import { Button } from 'antd'
import classNames from 'classnames'
import { getTime, intervalToDuration, formatDuration } from 'date-fns'
import { ru } from 'date-fns/locale'
import { useContext } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { DASHBOARD_LINK, LOGIN_LINK } from '../../constants'
import Section from '../Section'

import sticker from './images/actionsSticker.svg'
import lightningIcon from './images/lightningIcon.svg'

import s from './Promos.module.scss'
import AuthContext from '../../contexts/AuthContext'
import ModalContext from '../../contexts/ModalContext'
import TariffContext from '../../contexts/TariffContext'
import { useNavigate } from 'react-router-dom'
import { IPromotion } from '../../@types/assets'

function getDateDiff(dateEnd: string) {
  const interval = intervalToDuration({
    start: getTime(new Date()),
    end: new Date(dateEnd),
  })

  if (interval.days) {
    if (interval.days === 1) {
      return formatDuration(interval, {
        locale: ru,
        format: ['days', 'hours'],
      })
    }
    return formatDuration(interval, {
      locale: ru,
      format: ['days'],
    })
  }
  if (interval.hours) {
    return formatDuration(interval, {
      locale: ru,
      format: ['hours', 'minutes'],
    })
  }

  return formatDuration(interval, {
    locale: ru,
  })
}

const Promos = (props: { landingPromotions: IPromotion[] }) => {
  const { modalObject } = useContext(ModalContext)
  const { tariffObject } = useContext(TariffContext)
  const { authData } = useContext(AuthContext)
  const navigate = useNavigate()
  return (
    <Section
      id="promos"
      className={classNames(
        s['actions-section'],
        props.landingPromotions && props.landingPromotions.length !== 0
          ? ''
          : s['actions-section--disable'],
      )}
    >
      <div className="section_wrapper">
        <div className={classNames('section-title ', s['actions__title'])}>
          Действующие <span className="text-accent">акции</span>
        </div>
        <ul className={s['actions__list']}>
          {props.landingPromotions && props.landingPromotions.length !== 0
            ? props.landingPromotions.map((promo) => {
                return (
                  <li
                    className={s['action-card']}
                    key={`${promo.expiredAt}-${promo.newAvgCost}`}
                  >
                    <img
                      src={sticker}
                      alt=""
                      className={s['actions-sticker']}
                    />
                    <div className={s['action-card__time-to-end']}>
                      <LazyLoadImage
                        src={lightningIcon}
                        alt=""
                        className={s.lightningIcon}
                      />
                      <span className={s['action-card__title']}>
                        Акция закончится через {getDateDiff(promo.expiredAt)}
                      </span>
                    </div>
                    <div
                      className={classNames(
                        s['location-title'],
                        s[`location-title--${promo.locationCode}`],
                      )}
                    >
                      {promo.location}
                    </div>
                    <div className={s['action-new-condition']}>
                      <div className={s['action-new-condition__wrapper']}>
                        <span className={s['action-new-price']}>
                          {` ${promo.title} = ${promo.newCost} ₽ ${
                            promo.newCost === promo.newAvgCost
                              ? ''
                              : `(${promo.newAvgCost} ₽ в день)`
                          }`}
                        </span>
                        <span className={s['action-old-price']}>
                          {`Старая цена ${promo.title} = ${promo.oldCost} ₽ ${
                            promo.newCost === promo.newAvgCost
                              ? ''
                              : `(${promo.newAvgCost} ₽ в день)`
                          }`}
                        </span>
                      </div>
                      <Button
                        onClick={() => {
                          if (authData.isLoggedIn) {
                            tariffObject.setTariff(promo.title.split(' ')[0])
                            const coworkingName =
                              promo.locationCode === 'beta-kovorking'
                                ? 'beta'
                                : 'alfa'
                            modalObject.setModal(coworkingName)
                            navigate(DASHBOARD_LINK)
                          } else {
                            navigate(LOGIN_LINK)
                          }
                        }}
                        className={classNames('Button', s['action-button'])}
                      >
                        Купить
                      </Button>
                    </div>
                  </li>
                )
              })
            : null}
        </ul>
      </div>
    </Section>
  )
}

export default Promos
