import { UserOutlined } from '@ant-design/icons'
import { Col } from 'antd'
import classNames from 'classnames'
import Logo from '../svg/logo'
import { DASHBOARD_LINK, LOGIN_LINK, REGISTER_LINK } from '../../constants'
import { Link } from 'react-router-dom'
import CurrentUserContext from '../../contexts/AuthContext'

import Section from '../Section'
import TabletHeader from '../TabletHeader'
import s from './Hero.module.scss'
import { useContext } from 'react'
import { SetActiveTopMenuType } from '../../@types/assets'

function Title() {
  return (
    <p className={s['discoverer__subtitle']}>
      современное рабочее место «под ключ»: от канцелярских принадлежностей и
      оргтехники до собственной кухни и переговорной и никакой бытовухи!
    </p>
  )
}

export default function Hero(props: {
  setActiveTopMenu: SetActiveTopMenuType
}) {
  const { authData } = useContext(CurrentUserContext)
  return (
    <Section className={s.hero}>
      <div className="section_wrapper">
        <TabletHeader setActiveTopMenu={props.setActiveTopMenu} location={''} />
        <div className={s.heroContent}>
          <div className={s.discoverer}>
            <h1 className={s['discoverer__title']}>КОВОРКИНГ</h1>
            <Logo className={s.logo} />
            <div className={s['discoverer__subtitle-wrapper']}>
              <p className={s['discoverer__subtitle']}>это</p>
              <div className={s['discoverer__subtitle-divider']}></div>
              <Title />
            </div>
          </div>
          <div className={s.discovererTablet}>
            <h1 className={s.title}>КОВОРКИНГ</h1>
            <Title />
          </div>
          <div className={s.buttons}>
            <Link to={authData.isLoggedIn ? DASHBOARD_LINK : LOGIN_LINK}>
              <button className={classNames('Button ', s.whiteBtn)}>
                <UserOutlined className={s.loginIcon} />
                Вход
              </button>
            </Link>
            <Link to={authData.isLoggedIn ? DASHBOARD_LINK : REGISTER_LINK}>
              <button className={classNames('Button ', s.borderedBtn)}>
                Регистрация
              </button>
            </Link>
          </div>
          <Col className={s['hero-cabinet']}>
            <div className={s.cabinet}>
              <div className={s['cabinet__title']}>
                Создайте{' '}
                <span className="text-accent-bold">личный кабинет</span>, чтобы
                начать пользоваться коворкингом!
              </div>
              <Link to={authData.isLoggedIn ? DASHBOARD_LINK : REGISTER_LINK}>
                <button
                  className={classNames(
                    'Button ',
                    'Button--light',
                    s['cabinet__button'],
                  )}
                >
                  Регистрация
                </button>
              </Link>
              <a href="#how-use" className={s['text-link']}>
                Как это работает?→
              </a>
            </div>
          </Col>
        </div>
      </div>
    </Section>
  )
}
