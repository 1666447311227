import { useEffect, useState } from 'react'
import s from './Register.module.scss'
import classNames from 'classnames'
import { Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  CONFIDENTIAL_LINK,
  DASHBOARD_LINK,
  PHONE_MASK,
  PHONE_REG_EXP,
  TERMS_LINK,
} from '../../constants'
import MaskedInput from 'react-text-mask'
import { AxiosError } from 'axios'
import { IRegister, RegisterType } from '../../@types/assets'
import { sendEmailVerify } from '../../api/api'
import { useNavigate } from 'react-router-dom'
import { Cookies } from 'react-cookie'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: '#be763c',
    }}
    spin
  />
)

const Register = (props: { onRegister?: RegisterType }) => {
  const [loading, setLoading] = useState<false | true>(false)
  const [popupOpen, setPopupOpen] = useState<boolean>(false)

  const [errors, setErrors] = useState<{
    email: string[] | null
    phone: string[] | null
    login: string[] | null
  }>({ email: null, login: null, phone: null })

  const cookie = new Cookies()
  const navigate = useNavigate()
  const initialValues = {
    phone: '',
    password: '',
    password_confirmation: '',
    email: '',
    login: '',
    agreedOferta: false,
  }

  const validationSchema = () => {
    return Yup.object().shape({
      phone: Yup.string()
        .matches(PHONE_REG_EXP, 'Введите корректный номер телефона')
        .required('Это поле обязательно'),
      login: Yup.string()
        .min(3, 'Длина логина должна быть больше 3 символов')
        .max(15, 'Длина логина должна быть меньше 15 символов')
        .required('Это поле обязательно'),
      email: Yup.string()
        .email('Введите корректный email')
        .required('Это поле обязательно'),
      password: Yup.string()
        .min(6, 'Пароль должен содержать более 6 символов')
        .required('Это поле обязательно'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), undefined], 'Пароли не совпадают')
        .required('Это поле обязательно'),
      agreedOferta: Yup.bool().oneOf(
        [true],
        'Необходимо согласиться с условиями',
      ),
    })
  }

  const resetServerErrors = () => {
    setErrors({ email: null, login: null, phone: null })
  }

  useEffect(() => {
    if (cookie.get('token')) {
      navigate(DASHBOARD_LINK, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (
    formValue: IRegister,
    onSubmitProps: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    setLoading(true)
    resetServerErrors()
    if (props.onRegister) {
      props
        .onRegister(formValue)
        .catch((err: AxiosError) => {
          const serverErrors: {
            email: string[] | null
            phone: string[] | null
            login: string[] | null
          } = JSON.parse(err.request.response).errors
          setErrors(serverErrors)
        })
        .finally(() => {
          setLoading(false)
          onSubmitProps.setSubmitting(false)
        })
    }
  }

  const handleResend = (email: string) => {
    sendEmailVerify(email)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form className={s.form} autoComplete="off">
            <div
              className={classNames(
                s.form_group,
                s['form_group-field'],
                s.form_group__column,
              )}
            >
              <label htmlFor="phone">Номер телефона</label>
              <Field name="phone">
                {({ field }: { field: { name: string } }) => (
                  <MaskedInput
                    {...field}
                    mask={PHONE_MASK}
                    id="phone"
                    placeholder="+7(___) ___-____"
                    autoComplete="off"
                    type="tel"
                    onInput={() => {
                      setErrors((prev) => {
                        return { ...prev, phone: null }
                      })
                    }}
                    className={classNames(s.form_control, 'form-control')}
                  />
                )}
              </Field>
              {formik.errors.phone ? (
                <ErrorMessage
                  name="phone"
                  component="div"
                  className={s.alert}
                />
              ) : (
                <div className={s.alert}>
                  {errors.phone?.map((item) => {
                    return `${item}
                      `
                  })}
                </div>
              )}
            </div>
            <div
              className={classNames(
                s.form_group,
                s['form_group-field'],
                s.form_group__column,
              )}
            >
              <label htmlFor="new-login">Логин</label>
              <Field
                name="login"
                type="text"
                id="new-login"
                onInput={() => {
                  setErrors((prev) => {
                    return { ...prev, login: null }
                  })
                }}
                autoComplete="new-login"
                className={classNames(s.form_control, 'form-control')}
              />
              {formik.errors.login ? (
                <ErrorMessage
                  name="login"
                  component="div"
                  className={s.alert}
                />
              ) : (
                <div className={s.alert}>
                  {errors.login?.map((item) => {
                    return `${item}
                      `
                  })}
                </div>
              )}
            </div>
            <div
              className={classNames(
                s.form_group,
                s['form_group-field'],
                s.form_group__column,
              )}
            >
              <label htmlFor="new-email">E-mail</label>
              <Field
                name="email"
                type="email"
                onInput={() => {
                  setErrors((prev) => {
                    return { ...prev, email: null }
                  })
                }}
                id="new-email"
                autoComplete="off"
                className={classNames(s.form_control, 'form-control')}
              />
              {formik.errors.email ? (
                <ErrorMessage
                  name="email"
                  component="div"
                  className={s.alert}
                />
              ) : (
                <div className={s.alert}>
                  {errors.email?.map((item) => {
                    return `${item}
                      `
                  })}
                </div>
              )}
            </div>
            <div
              className={classNames(
                s.form_group,
                s['form_group-field'],
                s.form_group__column,
              )}
            >
              <label htmlFor="password">Пароль</label>
              <Field
                autoComplete="new-password"
                name="password"
                type="password"
                id="password"
                className={classNames(s.form_control, 'form-control')}
              />
              <ErrorMessage
                name="password"
                component="div"
                className={s.alert}
              />
            </div>
            <div
              className={classNames(
                s.form_group,
                s['form_group-field'],
                s.form_group__column,
              )}
            >
              <label htmlFor="password_confirmation">Повторите пароль</label>
              <Field
                name="password_confirmation"
                type="password"
                id="password_confirmation"
                className={classNames(s.form_control, 'form-control')}
              />
              <ErrorMessage
                name="password_confirmation"
                component="div"
                className={s.alert}
              />
            </div>
            <div className={classNames(s.form_group, s['form_group-field'])}>
              <Field
                name="agreedOferta"
                id="agreedOferta"
                type="checkbox"
                className={s.checkbox}
              />
              <label htmlFor="agreedOferta">
                Соглашаюсь с условиями
                <a href={TERMS_LINK} target="_blank" rel="noreferrer">
                  {' '}
                  Публичной оферты{' '}
                </a>{' '}
                и
                <a href={CONFIDENTIAL_LINK} target="_blank" rel="noreferrer">
                  {' '}
                  Политики обработки персональных данных{' '}
                </a>
              </label>
            </div>
            <ErrorMessage
              name="agreedOferta"
              component="div"
              className={s.alert}
            />
            <div
              className={classNames(
                'd-flex flex-column align-items-center',
                s.form_group,
                s.form_group__column,
              )}
            >
              <Button
                className={classNames('Button', s.submit_button)}
                type="primary"
                htmlType="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {loading ? (
                  <Spin className="preloader" indicator={antIcon} />
                ) : (
                  'Регистрация'
                )}
              </Button>
            </div>
            <div
              className={classNames(
                s.popup,
                popupOpen ? '' : s.popup_invisible,
              )}
            >
              <h3 className={s['popup-title']}>Подтвердите свой Email-адрес</h3>
              <p className={s['confirm-text']}>
                Для продолжения Вы должны подтвердить свой Email. Письмо с
                инструкцией направлено на{' '}
                <span className={s['confirm-text-accent']}>
                  {formik.values.email}
                </span>
                .
              </p>
              <p className={s['resend-text']}>
                Если Вы не получили письмо, запросите ещё одно.
              </p>
              <Button
                className={classNames('Button', s['popup-button'])}
                onClick={() => handleResend(formik.values.email)}
              >
                Отправить ещё письмо!
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Register
