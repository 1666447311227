import { useNavigate, useParams } from 'react-router-dom'
import s from './AdminEditSubscription.module.scss'
import {
  GetSubscriptionType,
  IAdminSubscription,
  IAdminSubscriptionForEdit,
  UpdateSubscriptionType,
} from '../../@types/assets'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import InputSkeleton from '../InputSkeleton/InputSkeleton'
import classNames from 'classnames'
import { ADMIN_SUBSCRIPTIONS_LINK } from '../../constants'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'

const AdminEditSubscription = (props: {
  isLoading?: boolean
  onGetSubscription?: GetSubscriptionType
  onUpdateSubscription?: UpdateSubscriptionType
}) => {
  const { subscriptionId } = useParams()
  document.title = `Редактирование подписки | Bekker-place Admin`
  const navigate = useNavigate()

  const [editedSubscription, setEditedSubscription] =
    useState<IAdminSubscription | null>(null)

  useEffect(() => {
    if (subscriptionId && props.onGetSubscription) {
      props.onGetSubscription(Number(subscriptionId)).then((res) => {
        setEditedSubscription(res.data)
      })
    }
  }, [])

  const validationSchema = () => {
    return Yup.object().shape({
      costDay: Yup.string().required('Это поле обязательно'),
      cost7days: Yup.string().required('Это поле обязательно'),
      cost14days: Yup.string().required('Это поле обязательно'),
      cost30days: Yup.string().required('Это поле обязательно'),
      cost360days: Yup.string().required('Это поле обязательно'),
    })
  }

  const initialValues: IAdminSubscriptionForEdit = {
    costDay: editedSubscription?.costDay ? editedSubscription?.costDay : '0',
    cost7days: editedSubscription?.cost7days
      ? editedSubscription?.cost7days
      : '0',
    cost14days: editedSubscription?.cost14days
      ? editedSubscription?.cost14days
      : '0',
    cost30days: editedSubscription?.cost30days
      ? editedSubscription?.cost30days
      : '0',
    cost360days: editedSubscription?.cost360days
      ? editedSubscription?.cost360days
      : '0',
  }

  const handleSubmit = (formValues: IAdminSubscriptionForEdit) => {
    if (props.onUpdateSubscription) {
      props
        .onUpdateSubscription({
          id: editedSubscription!.id,
          costDay: formValues.costDay,
          cost7Days: formValues.cost7days,
          cost14Days: formValues.cost14days,
          cost30Days: formValues.cost30days,
          cost360Days: formValues.cost360days,
        })
        .then(() => {
          navigate(ADMIN_SUBSCRIPTIONS_LINK)
        })
    }
  }
  return (
    <div className={s.settings}>
      <h1 className={s.title}>
        Редактирование подписки на {editedSubscription?.locationName}
      </h1>
      <div className={s.form_container}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(formik) => (
            <Form className={s.form}>
              <div className={s.inputs_container}>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="costDay">Стоимость дня</label>
                    {props.isLoading ? (
                      <InputSkeleton
                        className={classNames(
                          s.input_skeleton,
                          s.input_skeleton__settings,
                        )}
                      />
                    ) : (
                      <Field
                        name="costDay"
                        id="costDay"
                        autoComplete="off"
                        type="number"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.costDay ? s.form_control__error : '',
                        )}
                      ></Field>
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="costDay"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="cost7days">Стоимость 7 дней</label>
                    {props.isLoading ? (
                      <InputSkeleton
                        className={classNames(
                          s.input_skeleton,
                          s.input_skeleton__settings,
                        )}
                      />
                    ) : (
                      <Field
                        autoComplete="off"
                        id="cost7days"
                        name="cost7days"
                        type="number"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.cost7days ? s.form_control__error : '',
                        )}
                      />
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="cost7days"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="cost14days">Стоимость 14 дней</label>
                    {props.isLoading ? (
                      <InputSkeleton
                        className={classNames(
                          s.input_skeleton,
                          s.input_skeleton__settings,
                        )}
                      />
                    ) : (
                      <Field
                        autoComplete="off"
                        id="cost14days"
                        name="cost14days"
                        type="number"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.cost14days ? s.form_control__error : '',
                        )}
                      />
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="cost14days"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="cost30days">Стоимость 30 дней</label>
                    {props.isLoading ? (
                      <InputSkeleton
                        className={classNames(
                          s.input_skeleton,
                          s.input_skeleton__settings,
                        )}
                      />
                    ) : (
                      <Field
                        autoComplete="off"
                        id="cost30days"
                        name="cost30days"
                        type="number"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.cost30days ? s.form_control__error : '',
                        )}
                      />
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="cost30days"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="cost360days">Стоимость 360 дней</label>
                    {props.isLoading ? (
                      <InputSkeleton
                        className={classNames(
                          s.input_skeleton,
                          s.input_skeleton__settings,
                        )}
                      />
                    ) : (
                      <Field
                        autoComplete="off"
                        id="cost360days"
                        name="cost360days"
                        type="number"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.cost360days
                            ? s.form_control__error
                            : '',
                        )}
                      />
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="cost360days"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
              </div>
              <div className={s.buttons_container}>
                <button type="submit" className={s.button_save}>
                  Сохранить
                </button>
                <button
                  className={s.button_cancel}
                  onClick={() => {
                    navigate(ADMIN_SUBSCRIPTIONS_LINK)
                  }}
                >
                  Отмена
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AdminEditSubscription
