import { useState } from 'react'
import { Link } from 'react-router-dom'
import s from './PasswordEmail.module.scss'
import classNames from 'classnames'
import { Button } from 'antd'
import { Formik, Field, Form, ErrorMessage, FormikState } from 'formik'
import * as Yup from 'yup'
import { LOGIN_LINK } from '../../constants'
import { SendPasswordEmailType } from '../../@types/assets'

const PasswordEmail = (props: {
  onSendPasswordEmail?: SendPasswordEmailType
}) => {
  const [loading, setLoading] = useState<false | true>(false)
  const [serverError, setServerError] = useState<string | undefined>('')
  const initialValues = {
    email: '',
  }

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email('Введите корректный email')
        .required('Это поле обязательно'),
    })
  }

  const onSubmit = (
    formValue: { email: string },
    resetForm: (
      nextState?:
        | Partial<
            FormikState<{
              email: string
            }>
          >
        | undefined,
    ) => void,
  ) => {
    setLoading(true)
    setServerError('')
    if (props.onSendPasswordEmail) {
      props
        .onSendPasswordEmail(formValue.email)
        .then(() => {
          resetForm()
        })
        .catch((err) => {
          setServerError(JSON.parse(err.request.response).message)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values, resetForm)
      }}
    >
      {(formik) => (
        <Form className={s.form} noValidate>
          <div
            className={classNames(
              s.form_group,
              s['form_group-field'],
              s.form_group__column,
            )}
          >
            <label htmlFor="email">Восстановление пароля</label>
            <Field
              name="email"
              type="email"
              id="email"
              placeholder="Ваш email"
              onInput={() => {
                setServerError('')
              }}
              className={classNames(s.form_control, 'form-control')}
            />
            <div className={s['alert-container']}>
              {formik.errors.email ? (
                <ErrorMessage
                  name="email"
                  component="div"
                  className={s.alert}
                />
              ) : (
                <div className={s.alert}>{serverError}</div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              'd-flex flex-column align-items-center',
              s.form_group,
              s.form_group__column,
            )}
          >
            <Button
              className={classNames('Button', s.submit_button)}
              type="primary"
              htmlType="submit"
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Отправить ссылку для восстановления
            </Button>
            <Link to={LOGIN_LINK}>
              <p className={s['form_group-link']}>Назад к авторизации</p>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PasswordEmail
