import s from './AdminPage.module.scss'
import Section from '../Section'
import AdminMenu from '../AdminMenu/AdminMenu'
import AdminHeader from '../AdminHeader/AdminHeader'
import { useState } from 'react'
import AdminDashboard from '../AdminDashboard/AdminDashboard'
import AdminUsers from '../AdminUsers/AdminUsers'
import AdminSubscriptions from '../AdminSubscriptions/AdminSubscriptions'
import AdminPromocodes from '../AdminPromocodes/AdminPromocodes'
import AdminPromotions from '../AdminPromotions/AdminPromotions'
import AdminLogs from '../AdminLogs/AdminLogs'
import AdminEditUser from '../AdminEditUser/AdminEditUser'
import AdminLocationSettings from '../AdminLocationSettings/AdminLocationSettings'
import AdminEditSubscription from '../AdminEditSubscription/AdminEditSubscription'
import AdminAddPromocode from '../AdminAddPromocode/AdminAddPromocode'
import AdminEditPromocode from '../AdminEditPromocode/AdminEditPromocode'
import AdminAddPromotion from '../AdminAddPromotion/AdminAddPromotion'
import AdminEditPromotion from '../AdminEditPromotion/AdminEditPromotion'
import AdminAnalytics from '../AdminAnalytics/AdminAnalytics'
import {
  LogoutType,
  GetDashboardInfoType,
  openNotificationType,
  UpdateUserType,
  GetUserType,
  GetUsersType,
  GetSubscriptionsType,
  GetSubscriptionType,
  UpdateSubscriptionType,
  GetPromocodesType,
  DeletePromocodeType,
  AddPromocodeType,
  GetPromotionsType,
  GetPromotionType,
  AddPromotionType,
  DeletePromotionType,
  UpdateLocationSettingsType,
  GetLocationSettingsType,
  UpdatePromotionType,
  GetAnalyticsDataType,
  GetTransfersType,
  SearchUserType,
} from '../../@types/assets'
import { useClickOutside } from '../../hooks/useClickOutside'

const AdminPage = (props: {
  onLogout: LogoutType
  page: string
  openNotification?: openNotificationType
  isLoading?: boolean
  onUpdateUser?: UpdateUserType
  onGetUser?: GetUserType
  onGetUsers?: GetUsersType
  onGetSubscriptions?: GetSubscriptionsType
  onGetSubscription?: GetSubscriptionType
  onUpdateSubscription?: UpdateSubscriptionType
  onGetPromocodes?: GetPromocodesType
  onDeletePromocode?: DeletePromocodeType
  onAddPromocode?: AddPromocodeType
  onGetPromotions?: GetPromotionsType
  onGetPromotion?: GetPromotionType
  onAddPromotion?: AddPromotionType
  onDeletePromotion?: DeletePromotionType
  onUpdateLocationSettings?: UpdateLocationSettingsType
  onGetLocationSettings?: GetLocationSettingsType
  onUpdateAdminPromotion?: UpdatePromotionType
  onGetAnalyticsData?: GetAnalyticsDataType
  onGetDashboardInfo?: GetDashboardInfoType
  onGetTransfers?: GetTransfersType
  onSearchUser?: SearchUserType
}) => {
  const [collapsed, setCollapsed] = useState(true)

  const handleOpenMenu = () => {
    setCollapsed(false)
  }

  const handleCloseMenu = () => {
    setCollapsed(true)
  }

  let menuRef = useClickOutside(() => {
    handleCloseMenu()
  })

  return (
    <>
      <AdminHeader
        onLogout={props.onLogout}
        handleOpenMenu={handleOpenMenu}
        collapsed={collapsed}
      />
      <AdminMenu
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        menuRef={menuRef}
        onCloseMenu={handleCloseMenu}
      />
      <Section className={s.admin}>
        {props.page === 'dashboard' && (
          <AdminDashboard
            onGetDashboardInfo={props.onGetDashboardInfo}
            onGetTransfers={props.onGetTransfers}
            isLoading={props.isLoading}
          />
        )}
        {props.page === 'users' && (
          <AdminUsers
            isLoading={props.isLoading}
            onGetUsers={props.onGetUsers}
            onSearchUser={props.onSearchUser}
          />
        )}
        {props.page === 'editUser' && (
          <AdminEditUser
            isLoading={props.isLoading}
            onUpdateUser={props.onUpdateUser}
            onGetUser={props.onGetUser}
            openNotification={props.openNotification}
          />
        )}
        {props.page === 'subscriptions' && (
          <AdminSubscriptions
            onGetSubscriptions={props.onGetSubscriptions}
            isLoading={props.isLoading}
          />
        )}
        {props.page === 'locationSettings' && (
          <AdminLocationSettings
            isLoading={props.isLoading}
            onUpdateLocationSettings={props.onUpdateLocationSettings}
            onGetLocationSettings={props.onGetLocationSettings}
          />
        )}
        {props.page === 'subscriptionEdit' && (
          <AdminEditSubscription
            isLoading={props.isLoading}
            onGetSubscription={props.onGetSubscription}
            onUpdateSubscription={props.onUpdateSubscription}
          />
        )}
        {props.page === 'promocodes' && (
          <AdminPromocodes
            onGetPromocodes={props.onGetPromocodes}
            onDeletePromocode={props.onDeletePromocode}
            isLoading={props.isLoading}
          />
        )}
        {props.page === 'promocodesAdd' && (
          <AdminAddPromocode
            isLoading={props.isLoading}
            onAddPromocode={props.onAddPromocode}
            onGetUsers={props.onGetUsers}
          />
        )}
        {props.page === 'promocodeEdit' && (
          <AdminEditPromocode isLoading={props.isLoading} />
        )}
        {props.page === 'promotions' && (
          <AdminPromotions
            onGetPromotions={props.onGetPromotions}
            onDeletePromotion={props.onDeletePromotion}
            isLoading={props.isLoading}
          />
        )}
        {props.page === 'promotionAdd' && (
          <AdminAddPromotion onAddPromotion={props.onAddPromotion} />
        )}
        {props.page === 'promotionEdit' && (
          <AdminEditPromotion
            isLoading={props.isLoading}
            onGetPromotion={props.onGetPromotion}
            onUpdateAdminPromotion={props.onUpdateAdminPromotion}
          />
        )}
        {props.page === 'logs' && <AdminLogs />}
        {props.page === 'analytics' && (
          <AdminAnalytics
            onGetAnalyticsData={props.onGetAnalyticsData}
            isLoading={props.isLoading}
          />
        )}
      </Section>
    </>
  )
}

export default AdminPage
