import s from './TabletHeader.module.scss'
import { Button } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import Logo from '../svg/logo'
import { Link } from 'react-router-dom'
import { DASHBOARD_LINK, LOGIN_LINK } from '../../constants'
import CurrentUserContext from '../../contexts/AuthContext'
import { useContext } from 'react'
import { SetActiveTopMenuType } from '../../@types/assets'

const TabletHeader = (props: {
  location: string
  setActiveTopMenu: SetActiveTopMenuType
}) => {
  const { authData } = useContext(CurrentUserContext)

  return (
    <div
      className={classNames(
        s.tabletHeader,
        props.location === 'login' ? s.loginHeader : '',
        props.location === 'cabinet' ? s.cabinetHeader : '',
      )}
    >
      <Link to="/">
        <Logo
          className={classNames(
            s.logo,
            props.location === 'cabinet' ? s.cabinetLogo : '',
          )}
        />
      </Link>
      <div className={s.buttons}>
        <Link to={authData.isLoggedIn ? DASHBOARD_LINK : LOGIN_LINK}>
          <Button
            className={classNames('Button', s.tabletHeaderButton)}
            type="text"
          >
            <UserOutlined />
            Вход
          </Button>
        </Link>
        <div
          className={s.hamburgerBtn}
          onClick={() => {
            props.setActiveTopMenu(true)
          }}
        />
      </div>
    </div>
  )
}

export default TabletHeader
