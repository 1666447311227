import { useContext, useState } from 'react'
import Section from '../Section'
import s from './LkHeader.module.scss'
import { UserOutlined } from '@ant-design/icons'
import { Popover } from 'antd'
import classNames from 'classnames'
import Logo from '../svg/logo'
import LkTopMenu from '../LkTopMenu/LkTopMenu'
import { Link, NavLink } from 'react-router-dom'
import {
  ADMIN_LINK,
  DASHBOARD_LINK,
  GUESTS_LINK,
  PROFILE_LINK,
} from '../../constants'
import authContext from '../../contexts/AuthContext'
import { Cookies } from 'react-cookie'
import { LogoutType } from '../../@types/assets'

const cookie = new Cookies()

const LkHeader = (props: { onLogout: LogoutType }) => {
  const { authData } = useContext(authContext)
  const [visible, setVisible] = useState(false)

  return (
    <Section className={s['section-header']}>
      <div className="section_wrapper">
        <header className={s.header}>
          <div className={s.header__section}>
            <Link to={DASHBOARD_LINK} className={s['logo-container']}>
              <Logo className={s.logo} />
            </Link>
            <nav>
              <ul className={s.header__menu}>
                <li className={s.header__item}>
                  <Link className={s['header__item-link']} to="/">
                    ← Вернуться на сайт
                  </Link>
                </li>
                <li className={s.header__item}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? classNames(
                            s['header__item-link'],
                            s['header__item-link__current'],
                          )
                        : s['header__item-link']
                    }
                    to={DASHBOARD_LINK}
                  >
                    Главная
                  </NavLink>
                </li>
                <li className={s.header__item}>
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? classNames(
                            s['header__item-link'],
                            s['header__item-link__current'],
                          )
                        : s['header__item-link']
                    }
                    to={GUESTS_LINK}
                  >
                    Гости
                  </NavLink>
                </li>
                {cookie.get('isAdmin') ? (
                  <li className={s.header__item}>
                    <NavLink className={s['header__item-link']} to={ADMIN_LINK}>
                      Админ-панель
                    </NavLink>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </nav>
            <Popover
              content={
                <div className={s.popover}>
                  <Link className={s['popover-link']} to={PROFILE_LINK}>
                    Профиль
                  </Link>
                  <span
                    className={s['popover-link']}
                    onClick={() => {
                      props.onLogout()
                    }}
                  >
                    Выход
                  </span>
                </div>
              }
              placement="bottomRight"
              trigger="click"
            >
              <div className={s.userContainer}>
                <span className={s.headerUser}>
                  {authData.user?.name && authData.user?.surname
                    ? `${authData.user?.name} ${authData.user?.surname}`
                    : authData.user?.login}
                </span>

                <UserOutlined
                  className={classNames('Button', s.headerButton)}
                />
              </div>
            </Popover>
          </div>

          <div
            className={s.hamburgerBtn}
            onClick={() => {
              setVisible(true)
            }}
          />
          <LkTopMenu
            visible={visible}
            setActiveTopMenu={setVisible}
            onLogout={props.onLogout}
          />
        </header>
      </div>
    </Section>
  )
}

export default LkHeader
