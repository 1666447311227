import classNames from 'classnames'
import Section from '../Section'
import s from './RentBenefits.module.scss'
import parkingIcon from './images/ParkingIcon.svg'
import foodIcon from './images/Food.svg'
import interactiveBoardIcon from './images/InteractiveBoard.svg'
import groupsIcon from './images/Groups.svg'
import calendarIcon from './images/Calendar.svg'
import clingingIcon from './images/Clinging.svg'
import fruitsIcon from './images/Fruits.svg'
import docsIcon from './images/Docs.svg'
import cellsIcon from './images/Cells.svg'
import wwwIcon from './images/www.svg'
import volumeIcon from './images/Volume.svg'
import microWaveIcon from './images/MicroWave.svg'
import receptionIcon from './images/Reception.svg'
import boardIcon from './images/Board.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useMemo } from 'react'

export default function RentBenefits() {
  const content = useMemo(
    () => [
      {
        icon: foodIcon,
        text_content: (
          <span>
            <span className="text-accent">NO LIMIT!</span> Кофе, чай и печенье
          </span>
        ),
      },
      {
        icon: fruitsIcon,
        text_content: (
          <span>
            <span className="text-accent">NO LIMIT!</span> Фрукты
          </span>
        ),
      },
      {
        icon: microWaveIcon,
        text_content: (
          <span>
            Кухня с холодильником, микроволновкой, чайником и кофемашиной
          </span>
        ),
      },
      // {
      //   icon: interactiveBoardIcon,
      //   text_content: <span>Интерактивная доска + проектор</span>,
      // },
      {
        icon: docsIcon,
        text_content: <span>Печать документов</span>,
      },
      {
        icon: receptionIcon,
        text_content: <span>Ресепшен </span>,
      },
      // {
      //   icon: groupsIcon,
      //   text_content: <span>Переговорная на 20 человек</span>,
      // },
      {
        icon: cellsIcon,
        text_content: <span>Ячейки</span>,
      },
      {
        icon: boardIcon,
        text_content: <span>Магнитная (маркерная) доска</span>,
      },
      {
        icon: calendarIcon,
        text_content: <span>Мероприятия</span>,
      },
      {
        icon: wwwIcon,
        text_content: <span>Высокоскоростной интернет</span>,
      },
      {
        icon: parkingIcon,
        text_content: <span>Бесплатная парковка</span>,
      },
      {
        icon: clingingIcon,
        text_content: <span>Клининг</span>,
      },
      {
        icon: volumeIcon,
        text_content: <span>ZOOM-комнаты со звукоизоляцией</span>,
      },
    ],
    [],
  )
  return (
    <Section id="benefits" className={s['rent-benefits-section']}>
      <div
        className={classNames(
          'section_wrapper',
          s['rent-benefits-section-wrapper'],
        )}
      >
        <h2 className={classNames(s['rent-benefits-section__title'])}>
          в аренду места <span className="text-accent">Включено:</span>
        </h2>
        <div className={s['benefits']}>
          {content.map(({ icon, text_content }) => {
            return (
              <div className={s['benefits-item']} key={`${icon}`}>
                <div className={s['benefits-item__icon']}>
                  <LazyLoadImage effect="blur" src={icon} alt="" />
                </div>
                <p className={s['benefits-item__title']}>{text_content}</p>
              </div>
            )
          })}

          <div
            className={classNames(s['benefits-item'], s['benefits-item--text'])}
          >
            <p className={s['benefits-item__title']}>
              Все это вы получаете вне зависимости от выбранного тарифа или
              абонемента
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
