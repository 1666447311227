import { useEffect } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import s from './EmailConfirmation.module.scss'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { GetUserInfoType, VerifyEmailType } from '../../@types/assets'
import { DASHBOARD_LINK } from '../../constants'

const EmailConfirmation = (props: {
  onVerifyEmail?: VerifyEmailType
  onGetUserInfo?: GetUserInfoType
}) => {
  const { userId, token } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 100,
        color: '#be763c',
      }}
      spin
    />
  )

  useEffect(() => {
    const signature = searchParams.get('signature')
    const expires = searchParams.get('expires')
    if (props.onVerifyEmail && userId && token && signature && expires) {
      props.onVerifyEmail({ userId, token, expires, signature }).then(() => {
        if (props.onGetUserInfo) {
          props.onGetUserInfo().then(() => {
            navigate(DASHBOARD_LINK)
          })
        }
      })
    }
  }, [])

  return (
    <div className={s.form}>
      <Spin className={s.spinner} indicator={antIcon} />
    </div>
  )
}

export default EmailConfirmation
