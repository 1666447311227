import './App.scss'
import { useEffect, useState } from 'react'

import { Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import Main from './components/Main/Main'
import LoginPage from './components/LoginPage/LoginPage'
import Cabinet from './components/Cabinet/Cabinet'
import NotFound from './components/NotFound/NotFound'
import AuthContext from './contexts/AuthContext'
import ModalContext from './contexts/ModalContext'
import {
  activatePromocode,
  addAdminPromocode,
  addAdminPromotion,
  buySubscription,
  deleteAdminPromocode,
  deleteAdminPromotion,
  getAdminAnalyticsCyclesLine,
  getAdminAnalyticsForDays,
  getAdminAnalyticsForTimes,
  getAdminDashboardLocation,
  getAdminLocationSettings,
  getAdminLocationsStatistics,
  getAdminOrdersStatistics,
  getAdminPromocodes,
  getAdminPromotion,
  getAdminPromotions,
  getAdminSubscription,
  getAdminSubscriptions,
  getAdminTransfers,
  getAdminUser,
  getAdminUserSubscriptions,
  getAdminUsers,
  getAdminUsersStatistics,
  getBalanceDetails,
  getGuests,
  getLandingPromotions,
  getLocations,
  getOrderStatus,
  getPromotions,
  getSubscriptions,
  getTariffs,
  getUserCard,
  getUserInfo,
  inviteGuest,
  login,
  logout,
  openGates,
  register,
  resetPassword,
  searchUser,
  sendEmailVerify,
  sendPasswordEmail,
  upBalance,
  updateAdminLocationSettings,
  updateAdminPromotion,
  updateAdminSubscription,
  updateAdminUser,
  updateUser,
  verifyEmail,
} from './api/api'
import {
  ADMIN_ADD_PROMOCODES_LINK,
  ADMIN_ADD_PROMOTION_LINK,
  ADMIN_ANALYTICS,
  ADMIN_LINK,
  ADMIN_LOGS_LINK,
  ADMIN_PROMOCODES_LINK,
  ADMIN_PROMOTIONS_LINK,
  ADMIN_SUBSCRIPTIONS_LINK,
  ADMIN_USERS_LINK,
  BALANCE_LINK,
  BANNED_LINK,
  DASHBOARD_LINK,
  EMAIL_VERIFY_LINK,
  GUESTS_LINK,
  HOME_LINK,
  LOGIN_LINK,
  PASSWORD_RESET_LINK,
  PASSWORD_SEND_EMAIL_LINK,
  PROFILE_LINK,
  REGISTER_LINK,
  STARTUP_USER_DATA,
} from './constants'
import {
  IBalance,
  ICard,
  IGates,
  IGuest,
  IInviteForm,
  ILocations,
  ILogin,
  IProfile,
  IPromotion,
  IRegister,
  ISubscriptions,
  Tariff,
  IUser,
  IUpdateAdminUser,
  IUpdateAdminSubscription,
  IAdminAddPromocode,
  IAdminAddPromotion,
  IAdminLocationSettingsForEdit,
  IPromocodeResponse,
  IResetPassword,
  IVerifyEmail,
} from './@types/assets'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Cookies } from 'react-cookie'
import TariffContext from './contexts/TariffContext'
import { notification } from 'antd'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { AxiosError } from 'axios'
import AdminPage from './components/AdminPage/AdminPage'
import AdminProtectedRoute from './components/AdminProtectedRoute/AdminProtectedRoute'
import BannedPage from './components/BannedPage/BannedPage'
import BannedProtectedRoute from './components/BannedProtectedRoute/BannedProtectedRoute'
import UnverifiedProtectedRoute from './components/UnverifiedProtectedRoute/UnverifiedProtectedRoute'

function App() {
  const [activeTopMenu, setActiveTopMenu] = useState(false)
  const [subscriptions, setSubscriptions] = useState<ISubscriptions | null>(
    null,
  )
  const [card, setCard] = useState<ICard | null>(null)
  const [locations, setLocations] = useState<ILocations | null>(null)
  const [isLoading, setisLoading] = useState(true)
  const [isLoginLoading, setIsloginLoading] = useState(false)
  const [tariffs, setTariffs] = useState<Tariff[]>([])
  const [guests, setGuests] = useState<IGuest[]>([])
  const [balanceData, setBalanceData] = useState<IBalance[]>([])
  const [promotions, setPromotions] = useState<IPromotion[]>([])
  const [landingPromotions, setLandingPromotions] = useState<IPromotion[]>([])

  const [isBanned, setIsBanned] = useState(false)
  const [isVerified, setIsVerified] = useState<boolean>(true)

  const navigate = useNavigate()
  const { state } = useLocation()
  const cookie = new Cookies()

  const openNotification = (description: string, success: boolean) => {
    notification.open({
      message: success ? 'Успешно!' : 'Ошибка!',
      description: description,
      placement: 'bottomRight',
      icon: success ? (
        <CheckCircleOutlined style={{ color: '#6ea729' }} />
      ) : (
        <CloseCircleOutlined style={{ color: '#f74646' }} />
      ),
    })
  }

  const [userData, setUserdata] = useState<{
    isLoggedIn: boolean
    user: IUser
  }>({
    isLoggedIn: false,
    user: STARTUP_USER_DATA,
  })

  const [authData, setAuthData] = useState<{
    isLoggedIn: boolean
    user: IUser
  }>({
    isLoggedIn: false,
    user: STARTUP_USER_DATA,
  })

  useEffect(() => {
    loginUserOnStartup()

    Promise.all([getTariffs(), getLandingPromotions()]).then(
      ([tariffsRes, landingPromotionsRes]) => {
        setTariffs(tariffsRes.data.data)
        setLandingPromotions(landingPromotionsRes.data.data)
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setAuthData(userData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.isLoggedIn])

  useEffect(() => {
    setAuthData((prev) => {
      return {
        isLoggedIn: prev.isLoggedIn,
        user: { ...prev.user, balance: userData.user.balance },
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData.user.balance])

  // useEffect(() => {}, [authData])

  function getAuthCookieExpiration(rememberMe?: boolean) {
    let date = new Date()
    if (rememberMe) {
      date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000) // 365 days
      return date
    }
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000) // 1 day
    return date
  }

  function setAsLogged(user: IUser) {
    setUserdata({
      isLoggedIn: true,
      user: { ...user },
    })
  }

  useEffect(() => {
    if (authData.isLoggedIn) {
      Promise.all([
        getUserCard(authData.user.id),
        getLocations(),
        getSubscriptions(authData.user.id),
        getBalanceDetails(authData.user.id),
        getPromotions(),
      ]).then(
        ([card, locations, subscriptions, balanceData, promotionsRes]) => {
          setCard({ id: card.id, number: card.number, userId: card.userId })
          setLocations(locations)
          setSubscriptions(subscriptions.subscriptions)
          setBalanceData(balanceData.balances.data)
          setPromotions(promotionsRes.data.data)
          getGuests(card.id)
            .then((res) => {
              setGuests(res.guests.data)
            })
            .finally(() => {
              setisLoading(false)
            })
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData.isLoggedIn])

  const handleSendPromo = (promo: string) => {
    setisLoading(true)
    activatePromocode(promo)
      .then((res) => {
        Promise.all([
          getSubscriptions(authData.user.id),
          getUserInfo(),
          getBalanceDetails(authData.user.id),
        ]).then(([subscriptions, userData, balanceData]) => {
          setSubscriptions(subscriptions.subscriptions)
          setUserdata((prev) => {
            return {
              isLoggedIn: prev.isLoggedIn,
              user: { ...prev.user, balance: userData.data.balance },
            }
          })
          setBalanceData(balanceData.balances.data)
        })
      })
      .then((res) => {
        openNotification('Промокод успешно применён!', true)
      })
      .catch((err: AxiosError<IPromocodeResponse>) => {
        openNotification(
          err.response?.data.msg ? err.response?.data.msg : 'Ошибка!',
          false,
        )
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleOpenGates = () => {
    if (subscriptions?.data) {
      openGates(subscriptions.data[0].subscriptionId)
        .then((res) => {
          openNotification(res.msg, res.success)
        })
        .catch((err: AxiosError<IGates>) => {
          openNotification(err.response!.data.msg, false)
        })
    }
  }

  const handleInviteGuest = (cardId: number, guestData: IInviteForm) => {
    setisLoading(true)
    inviteGuest(cardId, guestData)
      .then(() => {
        getGuests(cardId)
          .then((res) => {
            setGuests(res.guests.data)
            openNotification('Гость успешно приглашён!', true)
          })
          .finally(() => {
            setisLoading(false)
          })
      })
      .catch((err: AxiosError<IGates>) => {
        openNotification('Что-то пошло не так!', false)
        setisLoading(false)
      })
  }

  const handleBuySubscription = (
    subscriptionType: number,
    subscriptionId: number,
  ) => {
    buySubscription(subscriptionType, subscriptionId).then(() => {
      setisLoading(true)
      Promise.all([
        getSubscriptions(authData.user.id),
        getUserInfo(),
        getBalanceDetails(authData.user.id),
      ])
        .then(([subscriptionRes, userRes, balanceRes]) => {
          setUserdata((prev) => {
            return {
              isLoggedIn: prev.isLoggedIn,
              user: { ...prev.user, balance: userRes.data.balance },
            }
          })
          setBalanceData(balanceRes.balances.data)
          setSubscriptions(subscriptionRes.subscriptions)
          openNotification('Дни успешно приобретены!', true)
        })
        .catch((err: AxiosError<{ success: boolean; msg: string }>) => {
          openNotification(err.response!.data.msg, err.response!.data.success)
        })
        .finally(() => {
          setisLoading(false)
        })
    })
  }

  function setLogout(path: string) {
    navigate(path, { replace: true })
    setUserdata({
      isLoggedIn: false,
      user: STARTUP_USER_DATA,
    })
    cookie.remove('token', { path: '/' })
    cookie.remove('isAdmin', { path: '/' })
  }

  const handleGetUser = () => {
    return getUserInfo().then((res) => {
      setIsVerified(!!res.data.emailVerifiedAt)
      setIsBanned(res.data.banned)
      setUserdata({ isLoggedIn: true, user: res.data })
    })
  }

  function loginUserOnStartup() {
    if (cookie.get('token')) {
      getUserInfo()
        .then((res) => {
          // if (res.data.banned) {
          //   setIsBanned(res.data.banned)
          //   Promise.reject()
          // }

          setIsVerified(!!res.data.emailVerifiedAt)
          setIsBanned(res.data.banned)
          setUserdata({ isLoggedIn: true, user: res.data })
        })
        .catch((error) => {
          // setLogout(LOGIN_LINK)
        })
    } else {
      // setLogout(LOGIN_LINK)
      setUserdata({
        isLoggedIn: false,
        user: STARTUP_USER_DATA,
      })
    }
  }

  const handleUpdateUser = (data: IProfile) => {
    const {
      email,
      firstname,
      gender,
      lastname,
      login,
      password,
      password_confirmation,
      phone,
    } = data
    if (authData.user) {
      setAuthData((prev) => {
        return {
          isLoggedIn: prev.isLoggedIn,
          user: {
            ...prev.user,
            name: firstname,
            surname: lastname,
            sex: gender,
            login,
          },
        }
      })
      return updateUser({
        id: authData.user.id,
        phone,
        login,
        email,
        password,
        password_confirmation,
        firstname,
        lastname,
        gender,
      }).then(() => {
        openNotification('Данные обновлены', true)
      })
    }
  }

  const handleLogin = (data: ILogin) => {
    setIsloginLoading(true)
    return login(data.phone, data.password)
      .then((loginRes) => {
        cookie.set('token', loginRes.data.data.accessToken, {
          path: '/',
          secure: true,
          expires: getAuthCookieExpiration(data.rememberMe),
          sameSite: 'strict',
          httpOnly: false,
        })

        getUserInfo()
          .then((res) => {
            setIsBanned(res.data.banned)
            setIsVerified(!!res.data.emailVerifiedAt)
            if (!res.data.banned) {
              setAsLogged(res.data)
              cookie.set('isAdmin', res.data.isAdmin === 1 ? true : '', {
                path: '/',
                expires: getAuthCookieExpiration(data.rememberMe),
                // sameSite: 'lax',
                httpOnly: false,
              })
            }
          })
          .finally(() => {
            navigate(state?.path || DASHBOARD_LINK, { replace: true })
          })
      })
      .finally(() => {
        setIsloginLoading(false)
      })
  }

  const handleLogout = () => {
    logout()
      .then(() => {
        setLogout(HOME_LINK)
      })
      .finally(() => {
        setisLoading(true)
      })
  }

  const handleRegister = (data: IRegister) => {
    return register({
      phone: data.phone,
      password: data.password,
      password_confirmation: data.password_confirmation,
      email: data.email,
      login: data.login,
      agreedOferta: data.agreedOferta,
    }).then((res) => {
      cookie.set('token', res.data.accessToken, {
        path: '/',
        secure: true,
        expires: getAuthCookieExpiration(),
        sameSite: 'strict',
        httpOnly: false,
      })
      getUserInfo()
        .then((res) => {
          setIsBanned(res.data.banned)
          setIsVerified(!!res.data.emailVerifiedAt)
          if (!res.data.banned) {
            setAsLogged(res.data)
            cookie.set('isAdmin', res.data.isAdmin === 1 ? true : '', {
              path: '/',
              expires: getAuthCookieExpiration(false),
              // sameSite: 'lax',
              httpOnly: false,
            })
          }
        })
        .finally(() => {
          navigate(state?.path || DASHBOARD_LINK, { replace: true })
        })
    })
  }

  const handleVerifyEmail = (values: IVerifyEmail) => {
    return verifyEmail(values)
  }

  const handleSendPasswordEmail = (email: string) => {
    return sendPasswordEmail(email)
      .then(() => {
        openNotification(
          `Ссылка для сброса пароля отправлена на ${email}!`,
          true,
        )
      })
      .catch((err: AxiosError<{ message: string }>) => {
        if (err.response?.data.message) {
          openNotification(err.response?.data.message, false)
        } else {
          openNotification('Что-то пошло не так', false)
        }
      })
  }

  const handleResetPassword = (formValues: IResetPassword) => {
    return resetPassword(formValues)
      .then((res) => {
        openNotification(res.message, true)
      })
      .catch((err: AxiosError<{ message: string }>) => {
        if (err.response?.data.message) {
          openNotification(err.response?.data.message, false)
        } else {
          openNotification('Что-то пошло не так', false)
        }
      })
  }

  const handleCheckOrder = (order: number) => {
    return getOrderStatus(order)
      .then((res) => {
        openNotification(res.message, true)
      })
      .catch((err: AxiosError<{ message: string }>) => {
        if (err.response?.data.message) {
          openNotification(err.response?.data.message, true)
        } else {
          openNotification('Что-то пошло не так', false)
        }
      })
  }

  const handleUpBalance = (amount: number) => {
    upBalance(amount, authData.user.id).then((res) => {
      window.location.href = res.payLink
    })
  }

  const handleUpdateAdminUser = ({
    id,
    balance,
    banned,
    email,
    entranceAccess,
    login,
    name,
    phone,
    sex,
    subscriptions,
    surname,
  }: IUpdateAdminUser) => {
    setisLoading(true)
    return updateAdminUser({
      id,
      balance,
      banned,
      email,
      entranceAccess,
      login,
      name,
      phone,
      sex,
      subscriptions,
      surname,
    })
      .then(() => {
        openNotification('Пользователь успешно обновлён!', true)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleGetAdminUser = (id: number) => {
    setisLoading(true)
    return Promise.all([
      getAdminUser(Number(id)),
      getAdminUserSubscriptions(Number(id)),
    ]).finally(() => {
      setisLoading(false)
    })
  }

  const handleGetAdminUsers = (perPage: number, page: number) => {
    setisLoading(true)
    return getAdminUsers(perPage, page).finally(() => {
      setisLoading(false)
    })
  }

  const handleSearchAdminUser = (
    perPage: number,
    page: number,
    login: string,
  ) => {
    setisLoading(true)
    return searchUser(login, page, perPage).finally(() => {
      setisLoading(false)
    })
  }

  const handleGetAdminSubscriptions = (perPage: number, page: number) => {
    setisLoading(true)
    return getAdminSubscriptions(perPage, page).finally(() => {
      setisLoading(false)
    })
  }

  const handleGetAdminSubscription = (id: number) => {
    setisLoading(true)
    return getAdminSubscription(id).finally(() => {
      setisLoading(false)
    })
  }

  const handleUpdateAdminSubscription = ({
    id,
    costDay,
    cost7Days,
    cost14Days,
    cost30Days,
    cost360Days,
  }: IUpdateAdminSubscription) => {
    setisLoading(true)
    return updateAdminSubscription({
      id,
      costDay,
      cost7Days,
      cost14Days,
      cost30Days,
      cost360Days,
    })
      .then(() => {
        openNotification('Подписка успешно обновлена!', true)
      })
      .catch(() => {
        openNotification('Произошла ошибка!', false)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleGetAdminPromocodes = (perPage: number, page: number) => {
    setisLoading(true)
    return getAdminPromocodes(perPage, page).finally(() => {
      setisLoading(false)
    })
  }

  const handleDeleteAdminPromocode = (id: number) => {
    setisLoading(true)
    return deleteAdminPromocode(id)
      .then(() => {
        openNotification('Промокод успешно удалён', true)
      })
      .catch(() => {
        openNotification('Произошла ошибка', false)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleAddAdminPromocode = ({
    daysCounter,
    subscriptionId,
    discount,
    expiresIn,
    usagesLeft,
    userId,
  }: IAdminAddPromocode) => {
    setisLoading(true)
    return addAdminPromocode({
      daysCounter,
      subscriptionId,
      discount,
      expiresIn,
      usagesLeft,
      userId,
    })
      .then((res) => {
        openNotification(`Промокод ${res.data.code} успешно добавлен!`, true)
      })
      .catch(() => {
        openNotification('Произошла ошибка', false)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleGetAdminPromotions = (perPage: number, page: number) => {
    setisLoading(true)
    return getAdminPromotions(perPage, page).finally(() => {
      setisLoading(false)
    })
  }

  const handleGetAdminPromotion = (id: number) => {
    setisLoading(true)
    return getAdminPromotion(id).finally(() => {
      setisLoading(false)
    })
  }

  const handleAddAdminPromotion = (formValues: IAdminAddPromotion) => {
    setisLoading(true)
    return addAdminPromotion(formValues)
      .then((res) => {
        openNotification(`Акция успешно добавлена!`, true)
      })
      .catch(() => {
        openNotification('Произошла ошибка', false)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleUpdateAdminPromotion = (
    id: number,
    formValues: IAdminAddPromotion,
  ) => {
    setisLoading(true)
    return updateAdminPromotion(id, formValues)
      .then(() => {
        openNotification('Акция успешно обновлена!', true)
      })
      .catch(() => {
        openNotification('Произошла ошибка!', false)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleDeleteAdminPromotion = (id: number) => {
    setisLoading(true)
    return deleteAdminPromotion(id)
      .then(() => {
        openNotification('Акция успешно удалена', true)
      })
      .catch(() => {
        openNotification('Произошла ошибка', false)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleGetAdminLocationSettings = (id: number) => {
    setisLoading(true)
    return getAdminLocationSettings(id).finally(() => {
      setisLoading(false)
    })
  }

  const handleUpdateAdminLocationSettings = (
    id: number,
    formValues: IAdminLocationSettingsForEdit,
  ) => {
    setisLoading(true)
    return updateAdminLocationSettings(id, formValues)
      .then((res) => {
        openNotification(`Локация успешно обновлена!`, true)
      })
      .catch(() => {
        openNotification('Произошла ошибка', false)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleGetAnalyticsData = (rangeStart: string, rangeEnd: string) => {
    setisLoading(true)
    return Promise.all([
      getAdminAnalyticsForDays(rangeStart, rangeEnd),
      getAdminAnalyticsForTimes(rangeStart, rangeEnd),
      getAdminAnalyticsCyclesLine(rangeStart, rangeEnd),
    ]).finally(() => {
      setisLoading(false)
    })
  }

  const handleSendEmailVerify = (email: string) => {
    setisLoading(true)
    return sendEmailVerify(email)
      .then((res) => {
        openNotification(`Сообщениие успешно отправлено!`, true)
      })
      .catch(() => {
        openNotification('Произошла ошибка! Попробуйте позже', false)
      })
      .finally(() => {
        setisLoading(false)
      })
  }

  const handleGetDashboardInfo = () => {
    setisLoading(true)
    return Promise.all([
      getAdminDashboardLocation(3), // Альфа
      getAdminDashboardLocation(1), // Бета
      getAdminDashboardLocation(6), // Парковка
      getAdminUsersStatistics(),
      getAdminOrdersStatistics(),
      getAdminLocationsStatistics(3),
      getAdminLocationsStatistics(1),
    ]).finally(() => {
      setisLoading(false)
    })
  }

  const handleGetAdminTransfers = (perPage: number, page: number) => {
    return getAdminTransfers(perPage, page).finally(() => {})
  }

  const [modal, setModal] = useState<string | null>(null)
  const [tariff, setTariff] = useState<string>('1')

  return (
    <TariffContext.Provider value={{ tariffObject: { tariff, setTariff } }}>
      <ModalContext.Provider value={{ modalObject: { modal, setModal } }}>
        <AuthContext.Provider value={{ authData }}>
          <Routes>
            <Route
              path={HOME_LINK}
              element={
                <Main
                  activeTopMenu={activeTopMenu}
                  setActiveTopMenu={setActiveTopMenu}
                  title={'Коворкинг Bekker-place'}
                  tariffs={tariffs}
                  landingPromotions={landingPromotions}
                />
              }
            />
            <Route
              path={LOGIN_LINK}
              element={
                <LoginPage
                  loginLoading={isLoginLoading}
                  activeTopMenu={activeTopMenu}
                  setActiveTopMenu={setActiveTopMenu}
                  onLogin={handleLogin}
                  activeForm={'login'}
                  title={`Авторизация » Bekker-place`}
                  landingPromotions={landingPromotions}
                />
              }
            />
            <Route
              path={REGISTER_LINK}
              element={
                <LoginPage
                  landingPromotions={landingPromotions}
                  activeTopMenu={activeTopMenu}
                  setActiveTopMenu={setActiveTopMenu}
                  onRegister={handleRegister}
                  activeForm={'register'}
                  title={'Регистрация нового пользователя » Bekker-place'}
                />
              }
            />
            <Route
              path={PASSWORD_SEND_EMAIL_LINK}
              element={
                <LoginPage
                  landingPromotions={landingPromotions}
                  activeTopMenu={activeTopMenu}
                  setActiveTopMenu={setActiveTopMenu}
                  activeForm={'passwordSendEmail'}
                  title={'Восстановление пароля » Bekker-place'}
                  onSendPasswordEmail={handleSendPasswordEmail}
                  onResetPassword={handleResetPassword}
                />
              }
            />
            {/* <Route
              path={PASSWORD_RESET_LINK}
              element={
                <LoginPage
                  landingPromotions={landingPromotions}
                  activeTopMenu={activeTopMenu}
                  setActiveTopMenu={setActiveTopMenu}
                  activeForm={'passwordReset'}
                  title={'Сброс пароля » Bekker-place'}
                  onResetPassword={handleResetPassword}
                />
              }
            /> */}
            <Route
              path={EMAIL_VERIFY_LINK}
              element={
                <LoginPage
                  landingPromotions={landingPromotions}
                  activeTopMenu={activeTopMenu}
                  setActiveTopMenu={setActiveTopMenu}
                  activeForm={'verifyEmail'}
                  title={'Подтверждение Email » Bekker-place'}
                  onSendEmailVerify={handleSendEmailVerify}
                  isLoading={isLoading}
                  onLogout={handleLogout}
                />
              }
            />

            <Route
              element={<ProtectedRoute isLoggedIn={userData.isLoggedIn} />}
            >
              <Route element={<BannedProtectedRoute isBanned={isBanned} />}>
                <Route
                  element={<UnverifiedProtectedRoute isVerified={isVerified} />}
                >
                  <Route
                    path={DASHBOARD_LINK}
                    element={
                      <Cabinet
                        page={'dashboard'}
                        title={
                          'Личный кабинет пользователя | Bekker-place Cabinet'
                        }
                        onLogout={handleLogout}
                        subscriptions={subscriptions}
                        card={card}
                        locations={locations}
                        isLoading={isLoading}
                        tariffs={tariffs}
                        onSendPromo={handleSendPromo}
                        onOpenGates={handleOpenGates}
                        onBuySubscription={handleBuySubscription}
                        promotions={promotions}
                      />
                    }
                  />
                  <Route
                    path={PROFILE_LINK}
                    element={
                      <Cabinet
                        page={'profile'}
                        title={
                          'Редактирование данных профиля | Bekker-place Cabinet'
                        }
                        onLogout={handleLogout}
                        onUpdate={handleUpdateUser}
                        isLoading={isLoading}
                      />
                    }
                  />
                  <Route
                    path={GUESTS_LINK}
                    element={
                      <Cabinet
                        page={'guests'}
                        title={'Управление гостями | Bekker-place Cabinet'}
                        onLogout={handleLogout}
                        isLoading={isLoading}
                        guests={guests}
                        subscriptions={subscriptions}
                        onInviteGuest={handleInviteGuest}
                        card={card}
                      />
                    }
                  />
                  <Route
                    path={BALANCE_LINK}
                    element={
                      <Cabinet
                        page={'balance'}
                        title={'Управление балансом | Bekker-place Cabinet'}
                        onLogout={handleLogout}
                        onSendPromo={handleSendPromo}
                        balanceData={balanceData}
                        isLoading={isLoading}
                        onUpBalance={handleUpBalance}
                        onCheckOrder={handleCheckOrder}
                      />
                    }
                  />

                  <Route element={<AdminProtectedRoute />}>
                    <Route
                      path={ADMIN_LINK}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          isLoading={isLoading}
                          page="dashboard"
                          onGetDashboardInfo={handleGetDashboardInfo}
                          onGetTransfers={handleGetAdminTransfers}
                        />
                      }
                    />
                    <Route
                      path={ADMIN_USERS_LINK}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="users"
                          onGetUsers={handleGetAdminUsers}
                          isLoading={isLoading}
                          onSearchUser={handleSearchAdminUser}
                        />
                      }
                    />
                    <Route
                      path={'admin/users/:userId/edit'}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="editUser"
                          onUpdateUser={handleUpdateAdminUser}
                          onGetUser={handleGetAdminUser}
                          isLoading={isLoading}
                          openNotification={openNotification}
                        />
                      }
                    />
                    <Route
                      path={ADMIN_SUBSCRIPTIONS_LINK}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="subscriptions"
                          isLoading={isLoading}
                          onGetSubscriptions={handleGetAdminSubscriptions}
                        />
                      }
                    />
                    <Route
                      path={'/admin/subscriptions/:subscriptionId/edit'}
                      element={
                        <AdminPage
                          onGetSubscription={handleGetAdminSubscription}
                          onLogout={handleLogout}
                          page="subscriptionEdit"
                          isLoading={isLoading}
                          onUpdateSubscription={handleUpdateAdminSubscription}
                        />
                      }
                    />
                    <Route
                      path={'admin/location/:locationId/settings'}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="locationSettings"
                          isLoading={isLoading}
                          onUpdateLocationSettings={
                            handleUpdateAdminLocationSettings
                          }
                          onGetLocationSettings={handleGetAdminLocationSettings}
                        />
                      }
                    />
                    <Route
                      path={ADMIN_PROMOCODES_LINK}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="promocodes"
                          isLoading={isLoading}
                          onGetPromocodes={handleGetAdminPromocodes}
                          onDeletePromocode={handleDeleteAdminPromocode}
                        />
                      }
                    />
                    <Route
                      path={ADMIN_ADD_PROMOCODES_LINK}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="promocodesAdd"
                          isLoading={isLoading}
                          onGetUsers={handleGetAdminUsers}
                          onAddPromocode={handleAddAdminPromocode}
                        />
                      }
                    />
                    <Route
                      path={'admin/promocodes/:promocodeId/edit'}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="promocodeEdit"
                          isLoading={isLoading}
                        />
                      }
                    />

                    <Route
                      path={ADMIN_PROMOTIONS_LINK}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="promotions"
                          onGetPromotions={handleGetAdminPromotions}
                          onDeletePromotion={handleDeleteAdminPromotion}
                          isLoading={isLoading}
                        />
                      }
                    />

                    <Route
                      path={ADMIN_ADD_PROMOTION_LINK}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="promotionAdd"
                          onAddPromotion={handleAddAdminPromotion}
                        />
                      }
                    />

                    <Route
                      path={'/admin/promotions/:promotionId/edit'}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          isLoading={isLoading}
                          page="promotionEdit"
                          onGetPromotion={handleGetAdminPromotion}
                          onUpdateAdminPromotion={handleUpdateAdminPromotion}
                        />
                      }
                    />

                    <Route
                      path={ADMIN_LOGS_LINK}
                      element={
                        <AdminPage onLogout={handleLogout} page="logs" />
                      }
                    />

                    <Route
                      path={ADMIN_ANALYTICS}
                      element={
                        <AdminPage
                          onLogout={handleLogout}
                          page="analytics"
                          onGetAnalyticsData={handleGetAnalyticsData}
                          isLoading={isLoading}
                        />
                      }
                    />
                  </Route>
                </Route>
              </Route>
              <Route
                path={BANNED_LINK}
                element={
                  <BannedPage
                    title="Аккаунт заблокирован | Bekker-place"
                    onLogout={handleLogout}
                  />
                }
              />
            </Route>

            <Route
              path={`${EMAIL_VERIFY_LINK}/:userId/:token`}
              element={
                <LoginPage
                  landingPromotions={landingPromotions}
                  activeTopMenu={activeTopMenu}
                  setActiveTopMenu={setActiveTopMenu}
                  activeForm={'emailConfirmation'}
                  title={'Подтверждение Email » Bekker-place'}
                  onVerifyEmail={handleVerifyEmail}
                  onGetUserInfo={handleGetUser}
                />
              }
            />
            <Route
              path="*"
              element={
                <NotFound title={'Страница не найдена | Bekker-place'} />
              }
            />
          </Routes>
        </AuthContext.Provider>
      </ModalContext.Provider>
    </TariffContext.Provider>
  )
}

export default App
