import { useNavigate, useParams } from 'react-router-dom'
import s from './AdminEditPromotion.module.scss'
import {
  GetPromotionType,
  IAdminAddPromotion,
  IAdminPromotionForEdit,
  UpdatePromotionType,
} from '../../@types/assets'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import classNames from 'classnames'
import { ADMIN_PROMOTIONS_LINK } from '../../constants'
import { Select } from 'antd'
import DatePicker from 'react-widgets/esm/DatePicker'
import Localization from 'react-widgets/esm/Localization'
import { DateLocalizer } from 'react-widgets/IntlLocalizer'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import InputSkeleton from '../InputSkeleton/InputSkeleton'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const AdminEditPromotion = (props: {
  isLoading?: boolean
  onGetPromotion?: GetPromotionType
  onUpdateAdminPromotion?: UpdatePromotionType
}) => {
  document.title = `Редактирование акции | Bekker-place Admin`
  const navigate = useNavigate()
  const { promotionId } = useParams()

  const [editedPromotion, setEditedPromotion] =
    useState<IAdminPromotionForEdit | null>(null)

  useEffect(() => {
    if (promotionId && props.onGetPromotion) {
      props.onGetPromotion(Number(promotionId)).then((res) => {
        setEditedPromotion(res.data)
      })
    }
  }, [])

  const validationSchema = () => {
    return Yup.object().shape({
      newCost: Yup.number()
        .required('Это поле обязательно')
        .min(0, 'Введите положительное число'),
      expiredAt: Yup.string().required('Это поле обязательно'),
      subscriptionType: Yup.number().required('Это поле обязательно'),
      subscriptionId: Yup.number()
        .required('Это поле обязательно')
        .moreThan(0, 'Выберите подписку'),
    })
  }

  const initialValues: IAdminAddPromotion = {
    subscriptionId: editedPromotion?.subscriptionId
      ? editedPromotion?.subscriptionId
      : 0,
    subscriptionType: editedPromotion?.subscriptionType
      ? editedPromotion?.subscriptionType
      : 0,
    newCost: editedPromotion?.newCost ? editedPromotion?.newCost : 0,
    expiredAt: editedPromotion?.expiredAt
      ? new Date(editedPromotion.expiredAt)
      : new Date(),
  }

  const handleSubmit = (formValues: IAdminAddPromotion) => {
    if (props.onUpdateAdminPromotion) {
      props.onUpdateAdminPromotion(Number(promotionId), formValues).then(() => {
        navigate(ADMIN_PROMOTIONS_LINK)
      })
    }
  }
  return (
    <div className={s.settings}>
      <h1 className={s.title}>
        Редактирование акции на{' '}
        {props.isLoading ? '...' : editedPromotion?.title} (
        {props.isLoading ? '...' : editedPromotion?.location})
      </h1>
      <div className={s.form_container}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(formik) => (
            <Form className={s.form}>
              <div className={s.inputs_container}>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="subscriptionId">Акции на подписку</label>
                    {props.isLoading ? (
                      <InputSkeleton className={s.input_skeleton} />
                    ) : (
                      <Field name="subscriptionId">
                        {({
                          field,
                        }: {
                          field: {
                            name: string
                          }
                        }) => (
                          <Select
                            {...field}
                            id="subscriptionId"
                            className={classNames(
                              s.form_control,
                              'form-control',
                              s.form_control__select,
                              formik.errors.subscriptionId
                                ? s.form_control__error
                                : '',
                            )}
                            onChange={(value) => {
                              formik.setFieldValue('subscriptionId', value)
                            }}
                            options={[
                              { value: 1, label: 'Альфа Коворкинг' },
                              { value: 2, label: 'Бета Коворкинг' },
                              { value: 0, label: 'Не выбрано' },
                            ]}
                          />
                        )}
                      </Field>
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="subscriptionId"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="subscriptionType">Акция на цену</label>
                    {props.isLoading ? (
                      <InputSkeleton className={s.input_skeleton} />
                    ) : (
                      <Field name="subscriptionType">
                        {({
                          field,
                        }: {
                          field: {
                            name: string
                          }
                        }) => (
                          <Select
                            {...field}
                            id="subscriptionType"
                            className={classNames(
                              s.form_control,
                              'form-control',
                              s.form_control__select,
                              formik.errors.subscriptionType
                                ? s.form_control__error
                                : '',
                            )}
                            onChange={(value) => {
                              formik.setFieldValue('subscriptionType', value)
                            }}
                            options={[
                              // { value: 0, label: 'Гость' },
                              { value: 1, label: '1 день' },
                              { value: 7, label: '7 дней' },
                              { value: 14, label: '14 дней' },
                              { value: 30, label: '30 дней' },
                              { value: 360, label: '360 дней' },
                            ]}
                          />
                        )}
                      </Field>
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="subscriptionType"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="newCost">Новая цена</label>
                    {props.isLoading ? (
                      <InputSkeleton className={s.input_skeleton} />
                    ) : (
                      <Field
                        autoComplete="off"
                        id="newCost"
                        name="newCost"
                        type="text"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.newCost ? s.form_control__error : '',
                        )}
                      />
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="newCost"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="expiredAt">Действительна до</label>
                    {props.isLoading ? (
                      <InputSkeleton className={s.input_skeleton} />
                    ) : (
                      <Field name="expiredAt">
                        {({ field }: { field: { name: string } }) => (
                          <Localization
                            date={
                              new DateLocalizer({
                                firstOfWeek: 1,
                                culture: 'ru',
                              })
                            }
                          >
                            <DatePicker
                              {...field}
                              id="expiredAt"
                              min={new Date()}
                              valueEditFormat={{ dateStyle: 'short' }}
                              valueDisplayFormat={{ dateStyle: 'medium' }}
                              value={formik.values.expiredAt}
                              className={classNames(
                                s.datepicker,
                                formik.errors.expiredAt
                                  ? 'datepicker__error'
                                  : '',
                              )}
                              onChange={(value) => {
                                formik.setFieldValue('expiredAt', value)
                              }}
                            />
                          </Localization>
                        )}
                      </Field>
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="expiredAt"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
              </div>
              <div className={s.buttons_container}>
                <button type="submit" className={s.button_save}>
                  Сохранить
                </button>
                <button
                  className={s.button_cancel}
                  onClick={() => {
                    navigate(ADMIN_PROMOTIONS_LINK)
                  }}
                >
                  Отмена
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AdminEditPromotion
