import { Link } from 'react-router-dom'
import Section from '../Section'
import s from './BannedPage.module.scss'
import { LogoutType } from '../../@types/assets'

const BannedPage = (props: { title: string; onLogout: LogoutType }) => {
  document.title = props.title
  return (
    <Section>
      <div className={s.container}>
        <span className={s['main-text']}>Ваш аккаунт заблокирован!</span>
        <span className={s.text}>Обратитесь к администратору</span>
        <Link to="/" className={s.link}>
          Вернуться на сайт
        </Link>
        <span
          onClick={() => {
            if (props.onLogout) {
              props.onLogout()
            }
          }}
          className={s.link}
        >
          Выйти из аккаунта
        </span>
      </div>
    </Section>
  )
}

export default BannedPage
