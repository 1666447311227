import { createContext } from 'react'
import { IUser } from '../@types/assets'
import { STARTUP_USER_DATA } from '../constants'

const authData: {
  isLoggedIn: boolean
  user: IUser
} = {
  isLoggedIn: false,
  user: STARTUP_USER_DATA,
}

export default createContext({
  authData: { ...authData },
})
