import { useState, useContext, useEffect } from 'react'
import Section from '../Section'
import ModalQuestion from '../ModalQuestion/ModalQuestion'
import ModalOption from '../ModalOption/ModalOption'
import ModalBooking from '../ModalBooking/ModalBooking'
import s from './CabinetMain.module.scss'
import classNames from 'classnames'
import { Tooltip, Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { QRCodeSVG } from 'qrcode.react'
import validSign from './images/validSign.svg'
import notValidSign from './images/notValidSign.svg'
import exclamation from './images/exclamation.svg'
import { BALANCE_LINK, GUESTS_LINK } from '../../constants'
import { Link, useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/AuthContext'
import QRSkeleton from '../QRSkeleton/QRSkeleton'
import {
  ICard,
  ILocations,
  IPromotion,
  ISubscriptions,
  ISubscription,
  Tariff,
  IGuestTariff,
  BuySubscriptionType,
  SendPromoType,
  OpenGatesType,
} from '../../@types/assets'
import ModalContext from '../../contexts/ModalContext'
import { getLocationSettings } from '../../api/api'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: '#be763c',
    }}
    spin
  />
)

const CabinetMain = (props: {
  subscriptions?: ISubscriptions | null
  card?: ICard | null
  locations?: ILocations | null
  isLoading: boolean
  tariffs?: Tariff[]
  onSendPromo?: SendPromoType
  onOpenGates?: OpenGatesType
  onBuySubscription?: BuySubscriptionType
  promotions?: IPromotion[]
  alfaGuestInfo: IGuestTariff
  betaGuestInfo: IGuestTariff
}) => {
  const [inviteDisabled, setInviteDisabled] = useState<boolean>(true)
  // TODO - дизейблить кнопку въезда
  const navigate = useNavigate()

  const { authData } = useContext(AuthContext)
  const { modalObject } = useContext(ModalContext)
  const [promoValue, setPromoValue] = useState('')

  const [allSubDays, setAllSubDays] = useState(0)

  useEffect(() => {
    if (props.subscriptions) {
      setAllSubDays(
        props.subscriptions.data.reduce((currentSum, currentNumber) => {
          return currentSum + currentNumber.daysCounter
        }, 0),
      )
    }
  }, [props.subscriptions])

  useEffect(() => {
    if (allSubDays > 0) {
      setInviteDisabled(false)
    } else {
      setInviteDisabled(true)
    }
  }, [allSubDays])

  const setDaysWord = (days: number) => {
    if (days >= 11 && days <= 19) {
      return 'дней'
    }
    days = days % 10
    switch (days) {
      case 1:
        return 'день'
      case 2:
        return 'дня'
      case 3:
        return 'дня'
      case 4:
        return 'дня'
      default:
        return 'дней'
    }
  }

  const CURRENT_DATE = new Date().toLocaleDateString('ru', {
    year: undefined,
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
  return (
    <Section className={s['section-header']}>
      <div className="section_wrapper">
        <div className={s.cabinet_main}>
          <div className={s.one}>
            <div>
              <span className={s.section_header}>
                Добро пожаловать в личный кабинет коворкинга{' '}
              </span>
              <span
                className={classNames(
                  s.section_header,
                  s['section_header-name'],
                )}
              >
                BEKKER PLACE
              </span>
            </div>
            <span className={s.section_description}>
              Отслеживайте баланс на Вашем счету, остаток дней по абонементу,
              пополняйте счёт и применяйте промокоды
            </span>
          </div>

          <div className={classNames(s.two, s.cabinet_qr)}>
            <span className={classNames(s.section_header, s['header-qr'])}>
              QR-код для входа в коворкинг
            </span>
            <span className={s.section_description}>
              Отсканируйте данный QR-код при входе в коворкинг
            </span>
          </div>

          {props.isLoading ? (
            <QRSkeleton className={classNames(s.three, s.qr)} />
          ) : (
            <QRCodeSVG
              value={props.card?.number ? props.card.number : ''}
              className={classNames(s.three, s.qr)}
            />
          )}

          {/* Баланс */}
          <div className={classNames(s.four, s['section_block-item'])}>
            <div className={s.item__header}>
              <div className={s['header-title']}>
                <div className={classNames(s['header-img'], s.balance)}></div>
                Баланс
              </div>
              <Link to={BALANCE_LINK}>
                <div className={s['header-action']}>Пополнить баланс</div>
              </Link>
            </div>
            <div className={classNames(s.item__body, s.balance)}>
              {props.isLoading ? (
                <Spin className="preloader" indicator={antIcon} />
              ) : (
                <div className={s['item__body-bold']}>
                  {authData.user?.balance} &#8381;
                </div>
              )}
              <span
                className={classNames(
                  s['item__body-fade'],
                  s['item__body-fade_date'],
                )}
              >
                актуальный баланс на вашем счету на {CURRENT_DATE}
              </span>
            </div>
          </div>

          {/* Абонементы */}
          <div className={classNames(s.five, s['section_block-item'])}>
            <div className={s.item__header}>
              <div className={s['header-title']}>
                <div
                  className={classNames(s['header-img'], s.membership)}
                ></div>
                Абонементы
              </div>
              <div
                className={s['header-action']}
                onClick={() => modalObject.setModal('option')}
              >
                Купить абонемент
              </div>
            </div>
            <div className={classNames(s.item__body, s.membership)}>
              {props.subscriptions && props.subscriptions.data ? (
                props.subscriptions?.data.map((item: ISubscription) => {
                  return (
                    <div className={s['item__body-column']}>
                      <img
                        src={item.daysCounter > 0 ? validSign : notValidSign}
                        style={{ padding: '6px' }}
                        alt="sign"
                      />
                      <div>
                        <span className={s['item__body-coworking']}>
                          {item.locationName.split(' ')[0]}
                        </span>
                        <div className={s['item__body-data']}>
                          <span
                            className={classNames(
                              s['item__body-fade'],
                              s['item__body-fade_remains'],
                            )}
                          >
                            остаток:
                          </span>
                          <span className={s['item__body-days']}>
                            <span className={s['item__body-bold']}>
                              {item.daysCounter}
                            </span>
                            {setDaysWord(item.daysCounter)}
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })
              ) : (
                <p className={s['item__empty-subscriptions']}>
                  Нет активных абонементов
                </p>
              )}
            </div>
          </div>

          {/* Загруженность коворкинга */}
          <div className={classNames(s.six, s['section_block-item'])}>
            <div className={s.item__header}>
              <div className={s['header-title']}>
                <div className={classNames(s['header-img'], s.social)}></div>
                Загруженность коворкинга
              </div>
            </div>
            <div className={classNames(s.item__body, s.social)}>
              <span
                className={classNames(
                  s['item__body-fade'],
                  s['item__body-fade_date'],
                  s['item__body-fade_marginless'],
                  s['item__body-fade_koworkings'],
                )}
              >
                актуальное количество людей на <br /> {CURRENT_DATE}
              </span>
              {props.isLoading ? (
                <Spin className="preloader" indicator={antIcon} />
              ) : (
                props.locations?.locations.data.map((item) => {
                  if (item.name !== 'Промышленный Коворкинг') {
                    return (
                      <div className={s['item__body-place']}>
                        <span>{item.name}</span>
                        <span>
                          {item.guestSeatsBusy + item.seatsBusy}/{item.seats}
                        </span>
                      </div>
                    )
                  }
                  return null
                })
              )}
            </div>
          </div>

          {/* промокод */}
          <div className={classNames(s.seven, s['section_block-item__promo'])}>
            <div className={s.item__header}>
              <div className={s.promo_title}>У меня есть промокод</div>
            </div>
            <div className={classNames(s.item__body, s.promo)}>
              <form
                className={s.promo_form}
                onSubmit={(evt: React.FormEvent) => {
                  evt.preventDefault()
                  if (props.onSendPromo) {
                    props.onSendPromo(promoValue)
                  }
                }}
              >
                <input
                  className={s.promo_input}
                  type="text"
                  placeholder="Введите промокод"
                  onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                    setPromoValue(evt.target.value)
                  }}
                  value={promoValue}
                />
                <Button
                  className={classNames('Button', s.promo_button)}
                  htmlType="submit"
                >
                  Применить
                </Button>
              </form>
              <div className={s.item__footer}>
                <Tooltip
                  placement="topLeft"
                  trigger="hover"
                  title={
                    inviteDisabled === true ? (
                      <div
                        style={{
                          padding: '23px 1px 23px 21px',
                          position: 'relative',
                          fontSize: '12px',
                          width: '174px',
                          boxSizing: 'border-box',
                        }}
                      >
                        <img
                          src={exclamation}
                          alt="excl"
                          style={{
                            position: 'absolute',
                            top: '26px',
                            left: '5px',
                          }}
                        />
                        Необходимо купить дни, чтобы была возможность приглашать
                        гостей
                      </div>
                    ) : null
                  }
                >
                  <span>
                    <Button
                      className={classNames('Button', s['promo_button-invite'])}
                      disabled={inviteDisabled}
                      onClick={() => {
                        navigate(GUESTS_LINK)
                      }}
                    >
                      Пригласить гостя
                    </Button>
                  </span>
                </Tooltip>
                <div
                  className={s['promo_button-question']}
                  onClick={() => modalObject.setModal('question')}
                ></div>
              </div>
            </div>
          </div>

          {/* Gate open  */}
          <Tooltip
            placement="topLeft"
            trigger="hover"
            className={s.eight}
            title={
              inviteDisabled === true ? (
                <div
                  style={{
                    padding: '23px 1px 23px 21px',
                    position: 'relative',
                    fontSize: '12px',
                    width: '174px',
                    boxSizing: 'border-box',
                  }}
                >
                  <img
                    src={exclamation}
                    alt="excl"
                    style={{
                      position: 'absolute',
                      top: '26px',
                      left: '5px',
                    }}
                  />
                  Необходимо купить дни, чтобы была возможность открыть ворота
                </div>
              ) : null
            }
          >
            <span>
              <Button
                className={classNames('Button', s.promo_button, s.eight)}
                onClick={() => {
                  if (props.subscriptions?.data && props.onOpenGates) {
                    props.onOpenGates()
                  }
                }}
                disabled={inviteDisabled}
              >
                Въезд
              </Button>
            </span>
          </Tooltip>

          {modalObject.modal === 'question' && (
            <ModalQuestion
              visible={modalObject.modal === 'question' ? true : false}
              onCancel={() => modalObject.setModal(null)}
              alfaGuestInfo={props.alfaGuestInfo}
              betaGuestInfo={props.betaGuestInfo}
            />
          )}

          {modalObject.modal === 'option' && (
            <ModalOption
              visible={modalObject.modal === 'option' ? true : false}
              onCancel={() => modalObject.setModal(null)}
              setAlfa={() => modalObject.setModal('alfa')}
              setBeta={() => modalObject.setModal('beta')}
            />
          )}

          {(modalObject.modal === 'alfa' || modalObject.modal === 'beta') && (
            <ModalBooking
              visible={
                modalObject.modal === 'alfa' || modalObject.modal === 'beta'
                  ? true
                  : false
              }
              isLoading={props.isLoading}
              onCancel={() => modalObject.setModal(null)}
              title={modalObject.modal === 'alfa' ? 'Альфа' : 'Бета'}
              setOption={() => modalObject.setModal('option')}
              tariffs={props.tariffs}
              onBuySubscription={props.onBuySubscription}
              promotions={props.promotions}
            />
          )}
        </div>
      </div>
    </Section>
  )
}

export default CabinetMain
