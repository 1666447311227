export default function Logo(props: { className: string }) {
    return (
        <svg
            width="77"
            height="42"
            viewBox="0 0 77 42"
            fill="#1D1D1B"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path d="M31.3131 32.5605H32.7204C32.8905 32.5605 33.0687 32.5802 33.2549 32.6192C33.4413 32.6584 33.6114 32.7261 33.7655 32.8227C33.9195 32.9193 34.0459 33.0516 34.1449 33.2194C34.2438 33.3872 34.2932 33.5977 34.2932 33.8506C34.2932 34.0713 34.2529 34.2622 34.1725 34.4231C34.0919 34.5842 33.9839 34.7163 33.8483 34.8198C33.7125 34.9232 33.5561 35.0003 33.3791 35.0508C33.202 35.1015 33.0169 35.1268 32.8238 35.1268H31.7683V37.4445H31.313V32.5605H31.3131ZM31.7684 34.7128H32.8032C32.9319 34.7128 33.0584 34.6979 33.1826 34.6678C33.3068 34.638 33.4171 34.5898 33.5137 34.523C33.6103 34.4565 33.6885 34.3679 33.7482 34.2575C33.808 34.147 33.8379 34.0115 33.8379 33.8505C33.8379 33.6941 33.8069 33.5606 33.7448 33.4504C33.6828 33.3399 33.6023 33.2492 33.5034 33.1779C33.4044 33.1067 33.2907 33.055 33.162 33.0226C33.0331 32.9906 32.9043 32.9743 32.7756 32.9743H31.7684V34.7128Z" />
            <path d="M37.8936 32.5605H38.3489V37.0306H40.6253V37.4445H37.8936V32.5605Z" />
            <path d="M45.4616 32.5605H45.9308L47.9865 37.4445H47.476L46.9448 36.1476H44.3579L43.8061 37.4445H43.3301L45.4616 32.5605ZM45.6755 33.0571L44.5373 35.7337H46.7793L45.6755 33.0571Z" />
            <path d="M55.3331 36.6789C55.0986 36.9962 54.8271 37.2239 54.519 37.3617C54.2109 37.4997 53.8775 37.5686 53.5189 37.5686C53.1463 37.5686 52.8049 37.5053 52.4945 37.3789C52.184 37.2526 51.9185 37.0753 51.6978 36.8477C51.477 36.62 51.3045 36.3488 51.1805 36.0336C51.0563 35.7187 50.9941 35.3748 50.9941 35.0023C50.9941 34.6299 51.0563 34.2872 51.1805 33.9745C51.3046 33.6619 51.477 33.3916 51.6978 33.164C51.9185 32.9364 52.184 32.7582 52.4945 32.6294C52.8049 32.5007 53.1463 32.4363 53.5189 32.4363C53.85 32.4363 54.1591 32.4949 54.4467 32.6122C54.734 32.7294 54.9859 32.926 55.202 33.2019L54.8296 33.5123C54.6778 33.2825 54.4834 33.1146 54.2467 33.0088C54.0097 32.903 53.7672 32.8502 53.5189 32.8502C53.2016 32.8502 52.9152 32.9054 52.6601 33.0157C52.4048 33.1262 52.1876 33.2779 52.0082 33.471C51.8288 33.6643 51.6908 33.8919 51.5942 34.1539C51.4976 34.4161 51.4494 34.699 51.4494 35.0024C51.4494 35.306 51.4977 35.5888 51.5942 35.8508C51.6909 36.113 51.8288 36.3406 52.0082 36.5337C52.1876 36.727 52.4048 36.8787 52.6601 36.9891C52.9152 37.0995 53.2016 37.1547 53.5189 37.1547C53.643 37.1547 53.7716 37.1397 53.9051 37.1098C54.0385 37.0799 54.1683 37.0339 54.295 36.9718C54.4214 36.9096 54.5422 36.8316 54.657 36.7373C54.772 36.643 54.8732 36.5293 54.9606 36.3958L55.3331 36.6789Z" />
            <path d="M58.7891 32.5605H61.7761V32.9743H59.2445V34.7128H61.6175V35.1267H59.2445V37.0305H61.8866V37.4444H58.7892V32.5605H58.7891Z" />
            <path d="M5.88054 28.7799H0V14.769H5.6635C7.20266 14.769 8.36024 15.1177 9.13657 15.8149C9.91268 16.5123 10.3008 17.4331 10.3008 18.5776C10.3008 19.3146 10.1199 19.9525 9.75811 20.4918C9.39614 21.0314 8.89968 21.4325 8.26828 21.6957C8.96534 21.9324 9.51793 22.3305 9.92583 22.8894C10.3335 23.4488 10.5376 24.103 10.5376 24.8528C10.5376 26.0895 10.1361 27.0532 9.33389 27.7438C8.53136 28.4345 7.38025 28.7799 5.88054 28.7799ZM5.68312 16.9397H2.34816V20.5904H5.68312C7.1564 20.5904 7.89326 19.9854 7.89326 18.7748C7.89326 18.1961 7.70888 17.7456 7.34067 17.4231C6.97235 17.101 6.41987 16.9397 5.68312 16.9397ZM5.86081 22.7808H2.34816V26.6091H5.84097C7.36698 26.6091 8.13016 25.9713 8.13016 24.695C8.13016 24.09 7.93284 23.6196 7.5381 23.284C7.14347 22.9486 6.58418 22.7808 5.86081 22.7808Z" />
            <path d="M23.0287 26.5698V28.7799H13.1816V14.769H23.0287V16.9792H15.5694V20.5706H22.6932V22.7808H15.5694V26.5697H23.0287V26.5698Z" />
            <path d="M38.0264 28.7799H35.0466L30.5671 22.3861L28.12 24.9319V28.7799H25.7324V14.769H28.1202V21.9126L34.6125 14.769H37.7501L32.2246 20.5904L38.0264 28.7799Z" />
            <path d="M51.7607 28.7799H48.7812L44.3015 22.3861L41.8545 24.9319V28.7799H39.4668V14.769H41.8545V21.9126L48.3469 14.769H51.4846L45.9592 20.5904L51.7607 28.7799Z" />
            <path d="M63.0483 26.5698V28.7799H53.2012V14.769H63.0483V16.9792H55.589V20.5706H62.7129V22.7808H55.589V26.5697H63.0483V26.5698Z" />
            <path d="M77.0002 28.7799H74.198L71.3564 23.2544H68.1398V28.7799H65.752V14.769H71.9088C73.4087 14.769 74.5532 15.1572 75.3425 15.9333C76.1318 16.7096 76.5265 17.7359 76.5265 19.0117C76.5265 19.9722 76.2961 20.7977 75.8357 21.4883C75.3751 22.179 74.6912 22.6692 73.7834 22.9585L77.0002 28.7799ZM73.5666 20.4917C73.9347 20.1235 74.1191 19.6302 74.1191 19.0117C74.1191 18.3935 73.9347 17.9002 73.5666 17.5316C73.1982 17.1634 72.6522 16.979 71.9288 16.979H68.1399V21.0442H71.9288C72.6522 21.0443 73.1982 20.8601 73.5666 20.4917Z" />
            <path d="M27.4049 13.0958H25.9307V0.000396729H41.8273V1.47459H27.4049V13.0958Z" />
            <path d="M67.8884 41.9582H25.9307V30.3867H27.4049V40.484H66.4142V30.3867H67.8884V41.9582Z" />
            <path d="M67.8889 13.0959H66.4147V1.47465H54.7534V0.000457764H67.8889V13.0959Z" />
            <path d="M42.2566 0.519641L41.0454 1.36008L48.9281 12.7202L50.1393 11.8798L42.2566 0.519641Z" />
            <path d="M50.2088 11.9835C50.2226 12.0036 50.2364 12.0238 50.2501 12.0439C50.2519 12.0465 50.2712 12.0746 50.2729 12.0772C50.4436 12.3262 50.6146 12.5748 50.7859 12.8228C50.1898 12.8231 49.5936 12.8236 48.9975 12.8239L48.9233 12.7171L50.1355 11.878L50.2088 11.9835Z" />
            <path d="M42.2554 0.520391L41.9282 0H41.828L41.5898 0.563823L41.7527 0.869206L42.2554 0.520391Z" />
            <path d="M41.7158 0.774277L41.8548 0.932243L42.2554 0.64092L42.3121 0.602136L42.2225 0.467987L41.7158 0.774277Z" />
        </svg>
    );
}
