import { Carousel } from 'antd'
import classNames from 'classnames'
import Section from '../Section'
import s from './Tariffs.module.scss'
import alfaSlide_1 from '../../images/alfa_gallery_11.jpg'
import alfaSlide_1_comp from '../../images/compressed/alfa_gallery_11_comp.jpg'
import alfaSlide_2 from '../../images/alfa_2.jpg'
import alfaSlide_2_comp from '../../images/compressed/alfa_2_comp.jpg'
import alfaSlide_3 from '../../images/alfa_3.jpg'
import alfaSlide_3_comp from '../../images/compressed/alfa_3_comp.jpg'
import alfaSlide_4 from '../../images/alfa_4.jpg'
import alfaSlide_4_comp from '../../images/compressed/alfa_4_comp.jpg'
import alfaSlide_5 from '../../images/alfa_gallery_2.jpg'
import alfaSlide_5_comp from '../../images/compressed/alfa_gallery_2_comp.jpg'

import promoSign from './images/propmoSign.svg'
import { useContext, useMemo } from 'react'
import { DASHBOARD_LINK, LOGIN_LINK } from '../../constants'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import ModalContext from '../../contexts/ModalContext'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../contexts/AuthContext'
import { Tariff } from '../../@types/assets'
import TariffContext from '../../contexts/TariffContext'
interface SlideT {
  src: string
  picComp: string
}

function CoworkingSlider(props: { tariffs: Tariff[] }) {
  const slides_alfa = useMemo(
    (): SlideT[] => [
      {
        src: alfaSlide_1,
        picComp: alfaSlide_1_comp,
      },
      {
        src: alfaSlide_2,
        picComp: alfaSlide_2_comp,
      },
      {
        src: alfaSlide_3,
        picComp: alfaSlide_3_comp,
      },
      {
        src: alfaSlide_4,
        picComp: alfaSlide_4_comp,
      },
      {
        src: alfaSlide_5,
        picComp: alfaSlide_5_comp,
      },
    ],
    [],
  )

  const { tariffs } = props
  tariffs.sort(function (a, b) {
    return a.locationCode.localeCompare(b.locationCode)
  })

  const { modalObject } = useContext(ModalContext)
  const { tariffObject } = useContext(TariffContext)
  const { authData } = useContext(AuthContext)
  const navigate = useNavigate()

  return (
    <>
      {tariffs.map((coworking) => (
        <div className={s.coworking} key={`${coworking.id}`}>
          <h3
            className={classNames(
              s['coworking__name'],
              s[`coworking__name--${coworking.locationCode}`],
            )}
          >
            {coworking.locationName}
          </h3>

          <div className={s['coworking__block']}>
            <Carousel autoplay className={s['coworking__carousel']}>
              {slides_alfa.map((slide) => (
                <div className={s.slide} key={`${slide}`}>
                  <LazyLoadImage
                    effect="blur"
                    src={slide.src}
                    placeholderSrc={slide.picComp}
                    alt=""
                  />
                </div>
              ))}
            </Carousel>
            <ul className={s['coworking__tariffs']}>
              <div className={s['promo-sign']}>
                <LazyLoadImage effect="blur" src={promoSign} alt="" />
              </div>
              <>
                {Object.values(coworking.tariffs).map((tariff) => {
                  if (tariff.visits !== 360 && tariff.visits !== 0) {
                    return (
                      <li
                        className={s['tariff']}
                        key={`${coworking.id}-${tariff.visits}`}
                      >
                        <div className={s['tariff__content']}>
                          <div className={s['tariff__text-wrapper']}>
                            <div className={s['tariff__name']}>
                              {` Пакет ${tariff.title}`}
                            </div>
                            <div className={s['tariff__title']}>
                              {`${tariff.visits}`}
                              {tariff.visits === 1
                                ? ' посещение '
                                : ' посещений '}
                              коворкинга в течение любого периода времени
                            </div>
                          </div>
                          <div className={s['tariff__price-wrapper']}>
                            <div className={s['tariff__price']}>
                              {`${tariff.cost} ₽`}
                            </div>
                            <div className={s['tariff__price-per-day']}>
                              {tariff.avgCost ? `${tariff.avgCost} р/день` : ``}
                            </div>
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            if (authData.isLoggedIn) {
                              tariffObject.setTariff(tariff.visits.toString())
                              const coworkingName = 'alfa'
                              modalObject.setModal(coworkingName)
                              navigate(DASHBOARD_LINK)
                            } else {
                              navigate(LOGIN_LINK)
                            }
                          }}
                          className={classNames('Button', s.button)}
                        >
                          Выбрать
                        </button>
                      </li>
                    )
                  }
                  return null
                })}
              </>
            </ul>
          </div>
        </div>
      ))}
    </>
  )
}
export default function Tariffs(props: { tariffs: Tariff[] }) {
  return (
    <Section
      id="prices"
      className={classNames(s['compare-section'], s['tariffs-section'])}
    >
      <div
        className={classNames('section_wrapper', s['tariffs-section-wrapper'])}
      >
        <h2 className={s['tariffs-section-title']}>
          <span className="text-accent">Выбери тариф для себя</span>
          <br className={s['line-break']} /> или своей команды
        </h2>
        <CoworkingSlider tariffs={props.tariffs} />
      </div>
    </Section>
  )
}
