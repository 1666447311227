import classNames from 'classnames'
import s from './AdminDashboard.module.scss'
import { UserOutlined, SwapOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import {
  Column,
  TableOptions,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import {
  IAdminLocation,
  IAdminUsersStatistics,
  IAdminOrdersStatistics,
  IAdminLocationsStatistics,
  IAdminTransfers,
  IPagination,
  GetDashboardInfoType,
  GetTransfersType,
} from '../../@types/assets'
import GlobalFilter from '../GlobalFilter/GlobalFilter'
import { Spin } from 'antd'
import { STARTUP_PAGINATION_DATA } from '../../constants'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const AdminDashboard = (props: {
  isLoading?: boolean
  onGetDashboardInfo?: GetDashboardInfoType
  onGetTransfers?: GetTransfersType
}) => {
  dayjs.locale('ru')
  document.title = 'Dashboard | Bekker-place Admin'

  const onChangeGoTo = useAsyncDebounce((value: number) => {
    setPageNumber(Number(value))
  }, 2000)

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 100,
        color: '#be763c',
      }}
      spin
    />
  )

  const antTableIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: '#be763c',
      }}
      spin
    />
  )

  const [alfaLocation, setAlfaLocation] = useState<IAdminLocation | null>(null)
  const [betaLocation, setBetaLocation] = useState<IAdminLocation | null>(null)
  const [parkingLocation, setParkingLocation] = useState<IAdminLocation | null>(
    null,
  )
  const [userStatistics, setUserStatistics] =
    useState<IAdminUsersStatistics | null>(null)
  const [ordersStatistics, setOrderStatistics] =
    useState<IAdminOrdersStatistics | null>(null)
  const [alfaLocationStatistics, setAlfaLocationStatistics] =
    useState<IAdminLocationsStatistics | null>(null)
  const [betaLocationStatistics, setBetaLocationStatistics] =
    useState<IAdminLocationsStatistics | null>(null)
  const [transfers, setTransfers] = useState<IAdminTransfers[]>([])
  const [paginationParameters, setPaginationParameters] = useState<IPagination>(
    STARTUP_PAGINATION_DATA,
  )
  const data = useMemo(() => {
    if (transfers?.length !== 0) {
      return transfers
    } else {
      return []
    }
  }, [transfers])

  const columns: Column<IAdminTransfers>[] = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Логин',
        accessor: 'userLogin',
      },
      {
        Header: 'Локация',
        accessor: 'locationName',
      },
      {
        Header: 'В какую сторону',
        accessor: 'directionName',
        Cell: (props) => {
          return (
            <div
              className={classNames(
                s['is-in'],
                props.value === 'Вышел' ? s['in-false'] : s['in-true'],
              )}
            >
              {props.value}
            </div>
          )
        },
      },
      {
        Header: 'Когда',
        accessor: 'createdAt',
        Cell: (cellProps) => {
          return (
            <span>{dayjs(cellProps.value).format('DD MMM YYYY, HH:mm')}</span>
          )
        },
      },
    ],
    [],
  )

  const options: TableOptions<IAdminTransfers> = {
    data,
    columns,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setPageSize,
    setGlobalFilter,
  } = useTable(options, useGlobalFilter, useSortBy, usePagination)

  const { globalFilter } = state

  const [pageSizeLocal, setPageSizeLocal] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  useEffect(() => {
    if (!!props.onGetDashboardInfo) {
      props
        .onGetDashboardInfo()
        .then(
          ([
            alfaLocationRes,
            betaLocationRes,
            parkingLocationRes,
            usersStatisticsRes,
            ordersStatisticsRes,
            alfaLocationStatisticsRes,
            betaLocationStatisticsRes,
          ]) => {
            setAlfaLocation(alfaLocationRes.data)
            setBetaLocation(betaLocationRes.data)
            setParkingLocation(parkingLocationRes.data)
            setUserStatistics(usersStatisticsRes.data)
            setOrderStatistics(ordersStatisticsRes.data)
            setAlfaLocationStatistics(alfaLocationStatisticsRes.data)
            setBetaLocationStatistics(betaLocationStatisticsRes.data)
          },
        )
    }
  }, [])

  const [isTransferTableLoading, setIsTransferTableLoading] = useState(false)

  useEffect(() => {
    if (props.onGetTransfers) {
      setIsTransferTableLoading(true)
      props
        .onGetTransfers(pageSizeLocal, pageNumber)
        .then((res) => {
          setTransfers(res.data.data)
          setPaginationParameters(res.data.pagination)
        })
        .finally(() => {
          setIsTransferTableLoading(false)
        })
    }
  }, [pageSizeLocal, pageNumber])

  return (
    <div className={s.dashboard}>
      {props.isLoading ? (
        <Spin className={s.spinner} indicator={antIcon} />
      ) : (
        <>
          <h1 className={s.title}>Dashboard</h1>
          <div className={s['cards-row']}>
            <div className={s.card}>
              <div className={s.card_header}>
                <h4 className={s.card_title}>Клиенты</h4>
                <UserOutlined className={s.card_icon} />
              </div>
              <div className={s.card_body}>
                <ul className={s.card_list}>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>Сегодня</span>
                      <span className={s.card_info}>
                        {userStatistics?.countToday}
                      </span>
                    </div>
                  </li>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>За месяц</span>
                      <span className={s.card_info}>
                        {userStatistics?.countCurrentMonth}
                      </span>
                    </div>
                    <div className={s['card_item-container']}>
                      <span
                        className={classNames(s.card_text, s.card_text__thin)}
                      >
                        Предыдущий
                      </span>
                      <span className={s.card_info}>
                        {userStatistics?.countPrevMonth}
                      </span>
                    </div>
                  </li>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>Прирост</span>
                      <span
                        className={classNames(
                          s.card_info,
                          Number(userStatistics?.growth) < 0
                            ? s.card_info__red
                            : '',
                        )}
                      >
                        {userStatistics?.growth} &#37;
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={s.card}>
              <div className={s.card_header}>
                <h4 className={s.card_title}>Оплаты</h4>
                <span className={s.card_icon}> &#8381;</span>
              </div>
              <div className={s.card_body}>
                <ul className={s.card_list}>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>Сегодня</span>
                      <span className={s.card_info}>
                        {ordersStatistics?.sumToday}
                      </span>
                    </div>
                  </li>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>За месяц</span>
                      <span className={s.card_info}>
                        {ordersStatistics?.sumCurrentMonth}
                      </span>
                    </div>
                    <div className={s['card_item-container']}>
                      <span
                        className={classNames(s.card_text, s.card_text__thin)}
                      >
                        Предыдущий
                      </span>
                      <span className={s.card_info}>
                        {ordersStatistics?.sumPrevMonth}
                      </span>
                    </div>
                  </li>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>Прирост</span>
                      <span
                        className={classNames(
                          s.card_info,
                          Number(ordersStatistics?.growth) < 0
                            ? s.card_info__red
                            : '',
                        )}
                      >
                        {ordersStatistics?.growth} &#37;
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className={s.card}>
              <div className={s.card_header}>
                <h4 className={s.card_title}>Посещения</h4>
                <SwapOutlined className={s.card_icon} />
              </div>
              <div className={s.card_body}>
                <ul className={s.card_list}>
                  <span className={s.card_label}> A | B</span>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>Сегодня</span>
                      <span className={s.card_info}>
                        {alfaLocationStatistics?.countToday} |{' '}
                        {betaLocationStatistics?.countToday}
                      </span>
                    </div>
                  </li>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>За месяц</span>
                      <span className={s.card_info}>
                        {alfaLocationStatistics?.countCurrentMonth} |{' '}
                        {betaLocationStatistics?.countCurrentMonth}
                      </span>
                    </div>
                    <div className={s['card_item-container']}>
                      <span
                        className={classNames(s.card_text, s.card_text__thin)}
                      >
                        Предыдущий
                      </span>
                      <span className={s.card_info}>
                        {alfaLocationStatistics?.countPrevMonth} |{' '}
                        {betaLocationStatistics?.countPrevMonth}
                      </span>
                    </div>
                  </li>
                  <li className={s['card_list-item']}>
                    <div className={s['card_item-container']}>
                      <span className={s.card_text}>Прирост &#37;</span>
                      <span className={s.card_info}>
                        <span
                          className={
                            Number(alfaLocationStatistics?.growth) < 0
                              ? s.card_info__red
                              : ''
                          }
                        >
                          {alfaLocationStatistics?.growth}
                        </span>{' '}
                        |{' '}
                        <span
                          className={
                            Number(alfaLocationStatistics?.growth) < 0
                              ? s.card_info__red
                              : ''
                          }
                        >
                          {betaLocationStatistics?.growth}{' '}
                        </span>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={s['cards-row']}>
            <div className={s.card}>
              <div className={s.card_body}>
                <div className={s['card_list-item']}>
                  <div className={s['card_item-container']}>
                    <span className={s.card_text}>{alfaLocation?.name}</span>
                    <span className={s.card_info}>
                      {alfaLocation?.seatsBusy} / {alfaLocation?.seats}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.card}>
              <div className={s.card_body}>
                <div className={s['card_list-item']}>
                  <div className={s['card_item-container']}>
                    <span className={s.card_text}>{betaLocation?.name}</span>
                    <span className={s.card_info}>
                      {betaLocation?.seatsBusy} / {betaLocation?.seats}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.card}>
              <div className={s.card_body}>
                <div className={s['card_list-item']}>
                  <div className={s['card_item-container']}>
                    <span className={s.card_text}>{parkingLocation?.name}</span>
                    <span className={s.card_info}>
                      {parkingLocation?.seatsBusy} / {parkingLocation?.seats}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={s.table_container}>
        <div className={s.table_header}>
          <h4 className={s.table_title}>Последние посещения</h4>
          <SwapOutlined className={s.table_icon} />
        </div>
        <div className={s['table_options-container']}>
          <select
            className={s['table_page-size']}
            value={pageSizeLocal}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
              setPageSizeLocal(Number(e.target.value))
              setPageNumber(1)
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Показать {pageSize}
              </option>
            ))}
          </select>
          {/* <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            className={s['table_search-input']}
            containerClassName={s.table_search}
          /> */}
        </div>
        <div className={s.table_wrapper}>
          <table {...getTableProps()} className={s.table_main}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className={s.table_arrow}>
                            &#8593;
                            <span className={s.table_arrow__unactive}>
                              &#8595;
                            </span>
                          </span>
                        ) : (
                          <span className={s.table_arrow}>
                            <span className={s.table_arrow__unactive}>
                              &#8593;
                            </span>
                            &#8595;
                          </span>
                        )
                      ) : (
                        <span
                          className={classNames(
                            s.table_arrow,
                            s.table_arrow__unactive,
                          )}
                        >
                          &#8593;&#8595;
                        </span>
                      )} */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {!isTransferTableLoading ? null : (
          <div className={s['table-spinner-container']}>
            <Spin indicator={antTableIcon} />
          </div>
        )}
        <div className={s.pagination}>
          <span className={s.pagination_text}>
            Страница {paginationParameters.currentPage} из{' '}
            {paginationParameters.totalPages}
          </span>
          <div className={s.pagination_goto}>
            <span className={s.pagination_text}>Перейти на страницу</span>
            <input
              type="number"
              defaultValue={1}
              max={paginationParameters.totalPages}
              min={1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const pageValue = e.target.value ? Number(e.target.value) : 0
                if (pageValue > paginationParameters.totalPages) {
                  onChangeGoTo(paginationParameters.totalPages)
                  return
                } else if (pageValue < 1) {
                  onChangeGoTo(1)
                  return
                }
                onChangeGoTo(pageValue)
              }}
              className={s.pagination_input}
            ></input>
          </div>
          <div className={s['pagination_button-container']}>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__first,
              )}
              onClick={() => {
                setPageNumber(1)
              }}
              disabled={pageNumber === 1}
            >
              1
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__middle,
              )}
              onClick={() => {
                setPageNumber(pageNumber - 1)
              }}
              disabled={pageNumber === 1}
            >
              Предыдущая
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__middle,
              )}
              onClick={() => {
                setPageNumber(pageNumber + 1)
              }}
              disabled={paginationParameters.totalPages === pageNumber}
            >
              Следующая
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__last,
              )}
              onClick={() => {
                setPageNumber(paginationParameters.totalPages)
              }}
              disabled={paginationParameters.totalPages === pageNumber}
            >
              {paginationParameters.totalPages}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminDashboard
