import s from './AdminAnalytics.module.scss'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  Sector,
  AreaChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Area,
} from 'recharts'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'

import {
  GetAnalyticsDataType,
  IAdminAlanyticsDayData,
  IAdminAlanyticsTimesData,
  IAdminAnalyticsCycleData,
} from '../../@types/assets'
import DatePicker from 'react-widgets/esm/DatePicker'
import classNames from 'classnames'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 100,
      color: '#be763c',
    }}
    spin
  />
)

const currentDate = new Date()

const VISITS_OPTIONS: {
  value: string
  label: string
}[] = [
  {
    value: 'today',
    label: 'Сегодня',
  },
  {
    value: 'yesterday',
    label: 'Вчера',
  },
  {
    value: 'last7Days',
    label: 'Последние 7 дней',
  },
  {
    value: 'last15Days',
    label: 'Последние 15 дней',
  },
  {
    value: 'last30Days',
    label: 'Последние 30 дней',
  },
  {
    value: 'thisMonth',
    label: 'В этом месяце',
  },
  {
    value: 'lastMonth',
    label: 'В предыдущем месяце',
  },
  {
    value: 'thisYear',
    label: 'В этом году',
  },
  {
    value: 'lastYear',
    label: 'В предыдущем году',
  },
  {
    value: 'dateRange',
    label: 'Выбрать дату',
  },
]
const COLORS = [
  '#FFBB28',
  '#00C49F',
  '#3300FF',
  '#CC00FF',
  '#0099FF',
  '#FF8042',
  '#FF3300',
]

const DAY_COLORS = ['#FFBB28', '#FF3300', '#3300FF', '#00C49F', '#FF8042']

const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
  const { x, y, payload } = props

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
      >
        {payload.value}
      </text>
    </g>
  )
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${value} человек`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

export default function AdminAlanytics(props: {
  onGetAnalyticsData?: GetAnalyticsDataType
  isLoading?: boolean
}) {
  const renderLegendForDays = (props: any) => {
    return (
      <ul style={{ listStyle: 'square inside' }}>
        {daysData.map((entry: any, index: number) => (
          <li style={{ color: `${COLORS[index]}` }} key={`item-${index}`}>
            {' '}
            {entry.dayName}
          </li>
        ))}
      </ul>
    )
  }

  const renderLegendForTimes = (props: any) => {
    return (
      <ul style={{ listStyle: 'square inside' }}>
        {timesData.map((entry: any, index: number) => (
          <li style={{ color: `${DAY_COLORS[index]}` }} key={`item-${index}`}>
            {entry.periodName}
          </li>
        ))}
      </ul>
    )
  }
  const [daysData, setDaysData] = useState<IAdminAlanyticsDayData[]>([])
  const [timesData, setTimesData] = useState<IAdminAlanyticsTimesData[]>([])
  const [cycleData, setCycleData] = useState<IAdminAnalyticsCycleData[]>([])

  const [activeIndex, setActiveIndex] = useState(0)
  const [weekActiveIndex, setDayActiveIndex] = useState(0)
  const onPieEnter = useCallback(
    (_: any, index: any) => {
      setActiveIndex(index)
    },
    [setActiveIndex],
  )

  const onDayPieEnter = useCallback(
    (_: any, index: any) => {
      setDayActiveIndex(index)
    },
    [setDayActiveIndex],
  )

  const [dateRangeActive, setDateRangeActive] = useState(false)
  const [dateRangeStart, setDateRangeStart] = useState<Date | null | undefined>(
    new Date(),
  )
  const [dateRangeEnd, setDateRangeEnd] = useState<Date | null | undefined>(
    new Date(),
  )
  const [period, setPeriod] = useState('thisMonth')
  const [periodObject, setPeriodObject] = useState<{
    rangeStart: string
    rangeEnd: string
  }>({
    rangeStart: new Date(
      `${currentDate.getMonth() + 1}-01-${currentDate.getFullYear()}`,
    ).toLocaleDateString(),
    rangeEnd: dayjs().toISOString(),
  })

  useEffect(() => {
    switch (period) {
      case 'today': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs().toISOString(),
          rangeEnd: dayjs().toISOString(),
        })
        break
      }
      case 'yesterday': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs().subtract(1, 'day').toISOString(),
          rangeEnd: dayjs().subtract(1, 'day').toISOString(),
        })
        break
      }

      case 'last7Days': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs().subtract(6, 'day').toISOString(),
          rangeEnd: dayjs().toISOString(),
        })
        break
      }
      case 'last15Days': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs().subtract(14, 'day').toISOString(),
          rangeEnd: dayjs().toISOString(),
        })
        break
      }
      case 'last30Days': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs().subtract(29, 'day').toISOString(),
          rangeEnd: dayjs().toISOString(),
        })
        break
      }
      case 'thisMonth': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs().startOf('month').toISOString(),
          rangeEnd: dayjs().toISOString(),
        })
        break
      }
      case 'lastMonth': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs()
            .subtract(1, 'month')
            .startOf('month')
            .toISOString(),
          rangeEnd: dayjs().subtract(1, 'month').endOf('month').toISOString(),
        })
        break
      }
      case 'thisYear': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs().startOf('year').toISOString(),
          rangeEnd: dayjs().toISOString(),
        })
        break
      }
      case 'lastYear': {
        setDateRangeActive(false)
        setPeriodObject({
          rangeStart: dayjs().subtract(1, 'year').startOf('year').toISOString(),
          rangeEnd: dayjs().subtract(1, 'year').endOf('year').toISOString(),
        })
        break
      }
      case 'dateRange': {
        setDateRangeActive(true)
        break
      }
    }
  }, [period])
  const [isAnalyticsLoading, setIsAnalyticsLoading] = useState(true)

  useEffect(() => {
    if (props.onGetAnalyticsData) {
      setIsAnalyticsLoading(true)
      props
        .onGetAnalyticsData(periodObject.rangeStart, periodObject.rangeEnd)
        .then(([daysRes, timesRes, cycleRes]) => {
          setDaysData(daysRes.data)
          setTimesData(timesRes.data)
          setCycleData(cycleRes.data)
        })
        .finally(() => {
          setIsAnalyticsLoading(false)
        })
    }
  }, [periodObject])

  return (
    <div className={s.analytics}>
      <div className={s['title-container']}>
        <h1 className={s.title}>Аналитика</h1>
        <select
          className={s.select}
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          {VISITS_OPTIONS.map((type: { value: string; label: string }) => (
            <option key={type.value} value={type.value}>
              {type.label}
            </option>
          ))}
        </select>
      </div>
      {!isAnalyticsLoading ? (
        <>
          <div
            className={classNames(
              s['date-range-container'],
              dateRangeActive ? '' : s['date-range-container__invisible'],
            )}
          >
            <DatePicker
              value={dateRangeStart}
              className="rw-widget-picker__analytics"
              onChange={(value) => {
                setDateRangeStart(value)
              }}
            />
            <DatePicker
              value={dateRangeEnd}
              className="rw-widget-picker__analytics"
              onChange={(value) => {
                setDateRangeEnd(value)
              }}
            />
            <button
              className={s['search-button']}
              onClick={() => {
                if (dateRangeStart && dateRangeEnd) {
                  setPeriodObject({
                    rangeStart: dateRangeStart?.toLocaleDateString(),
                    rangeEnd: dateRangeEnd?.toLocaleDateString(),
                  })
                }
              }}
            >
              Поиск
            </button>
          </div>
          <div className={s.analytics_container}>
            <div className={s.analytics_header}>
              <h2 className={s.subtitle}>Средняя загрузка по дням</h2>
            </div>
            <div className={s.chart_container}>
              {daysData.length === 0 ||
              daysData.reduce(function (currentSum, currentNumber) {
                return currentSum + currentNumber.count
              }, 0) === 0 ? (
                <p className={s['empty-data']}>Нет данных за этот период</p>
              ) : (
                <PieChart width={700} height={300} className={s.pie}>
                  <Pie
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    data={daysData}
                    cx={'50%'}
                    cy={'50%'}
                    innerRadius={60}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="count"
                    onMouseEnter={onPieEnter}
                  >
                    {daysData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend
                    iconType="square"
                    verticalAlign="middle"
                    align="right"
                    layout="vertical"
                    margin={{ top: 0, left: 20, right: 0, bottom: 0 }}
                    content={renderLegendForDays}
                  />
                </PieChart>
              )}
            </div>
          </div>
          <div className={s.analytics_container}>
            <div className={s.analytics_header}>
              <h2 className={s.subtitle}>Средняя загрузка по часам</h2>
            </div>
            <div className={s.chart_container}>
              {timesData.length === 0 ||
              timesData.reduce(function (currentSum, currentNumber) {
                return currentSum + currentNumber.count
              }, 0) === 0 ? (
                <p className={s['empty-data']}>Нет данных за этот период</p>
              ) : (
                <PieChart width={700} height={300}>
                  <Pie
                    activeIndex={weekActiveIndex}
                    activeShape={renderActiveShape}
                    data={timesData}
                    cx={'50%'}
                    cy={'50%'}
                    innerRadius={60}
                    outerRadius={100}
                    fill="#8884d8"
                    dataKey="count"
                    onMouseEnter={onDayPieEnter}
                  >
                    {timesData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={DAY_COLORS[index % DAY_COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Legend
                    iconType="square"
                    verticalAlign="middle"
                    align="right"
                    layout="vertical"
                    margin={{ top: 0, left: 20, right: 0, bottom: 0 }}
                    content={renderLegendForTimes}
                  />
                </PieChart>
              )}
            </div>
          </div>
          <div className={s.analytics_container}>
            <div className={s.analytics_header}>
              <h2 className={s.subtitle}>Количество посещений</h2>
            </div>

            <div className={s.chart_container}>
              {cycleData.length === 0 ||
              cycleData.reduce(function (currentSum, currentNumber) {
                return currentSum + currentNumber.count
              }, 0) === 0 ? (
                <p className={s['empty-data']}>Нет данных за этот период</p>
              ) : (
                <AreaChart
                  width={700}
                  height={300}
                  data={cycleData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    height={90}
                    tick={<CustomizedAxisTick />}
                  />
                  <YAxis />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="count"
                    stroke="#00C49F"
                    fill="#00C49F"
                  />
                </AreaChart>
              )}
            </div>
          </div>
        </>
      ) : (
        <Spin className={s.spinner} indicator={antIcon} />
      )}
    </div>
  )
}
