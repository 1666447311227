import { useState, useEffect, useContext } from 'react'
import s from './ModalBooking.module.scss'
import classNames from 'classnames'
import { Modal, Button, Tooltip } from 'antd/lib'
import exclamation from './images/exclamation.svg'
import { Link, useNavigate } from 'react-router-dom'
import logo from './images/logo.svg'
import CurrentUserContext from '../../contexts/AuthContext'
import { BALANCE_LINK } from '../../constants'
import { Field, Form, Formik } from 'formik'
import ModalContext from '../../contexts/ModalContext'
import TariffContext from '../../contexts/TariffContext'
import { BuySubscriptionType, IPromotion, Tariff } from '../../@types/assets'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: '#be763c',
    }}
    spin
  />
)

const ModalBooking = (props: {
  visible: boolean
  onCancel: any
  title: string
  setOption: any
  tariffs?: Tariff[] | null
  onBuySubscription?: BuySubscriptionType
  promotions?: IPromotion[]
  isLoading: boolean
}) => {
  const { modalObject } = useContext(ModalContext)
  const { authData } = useContext(CurrentUserContext)
  const { tariffObject } = useContext(TariffContext)

  const [maxModalSize, setMaxModalSize] = useState<number>(0)
  const [bookingDisabled, setBookingDisabled] = useState<boolean>(false)
  const [selectedPrice, setSelectedPrice] = useState(9999999)
  const navigate = useNavigate()

  useEffect(() => {
    if (window.innerWidth < 766) setMaxModalSize(290)
    else if (window.innerWidth < 1109) setMaxModalSize(630)
    else setMaxModalSize(768)
  }, [])

  useEffect(() => {
    if (authData.user.balance < selectedPrice) {
      setBookingDisabled(true)
    } else {
      setBookingDisabled(false)
    }
  }, [authData.user.balance, selectedPrice])

  const initialValues = {
    plan: tariffObject.tariff,
  }

  const onSubmit = (formValue: { plan: string }) => {
    if (props.onBuySubscription) {
      const subId = modalObject.modal === 'alfa' ? 1 : 2
      props.onBuySubscription(Number(formValue.plan), subId)
    }
  }

  const setDaysWord = (days: number) => {
    if (days >= 11 && days <= 19) {
      return 'дней'
    }
    days = days % 10
    switch (days) {
      case 1:
        return 'день'
      case 2:
        return 'дня'
      case 3:
        return 'дня'
      case 4:
        return 'дня'
      default:
        return 'дней'
    }
  }

  function isAPromo(
    element:
      | IPromotion
      | {
          title: string
          visits: number
          cost: number
          avgCost: number
        },
  ): element is IPromotion {
    return !!element && 'newAvgCost' in element
  }

  useEffect(() => {
    const elements =
      props.promotions && props.promotions?.length > 0
        ? props.tariffs?.map((tariffType) => {
            let coworkingName = ''
            modalObject.modal === 'beta'
              ? (coworkingName = 'beta-kovorking')
              : (coworkingName = 'alfa-kovorking')
            if (tariffType.locationCode === coworkingName) {
              return Object.values(tariffType.tariffs).map((tariffItem) => {
                const rightPromo = props.promotions!.find((promoElement) => {
                  return (
                    promoElement.locationCode === coworkingName &&
                    promoElement.subscriptionType === tariffItem.visits
                  )
                })
                if (rightPromo) {
                  return rightPromo
                } else {
                  return tariffItem
                }
              })
            }
          })
        : props.tariffs?.map((tariff) => {
            let coworkingName = ''
            modalObject.modal === 'beta'
              ? (coworkingName = 'beta-kovorking')
              : (coworkingName = 'alfa-kovorking')
            if (tariff.locationCode === coworkingName) {
              return Object.values(tariff.tariffs).map((tariffItem) => {
                return tariffItem
              })
            }
          })
    const rightElement = elements![0] ? elements![0] : elements![1]
    const rightTariff = rightElement?.find((element) => {
      return Number(element.title.split(' ')[0]) === Number(tariffObject.tariff)
    })

    if (rightTariff) {
      if (isAPromo(rightTariff)) {
        setSelectedPrice(rightTariff.newCost)
      } else {
        setSelectedPrice(rightTariff.cost)
      }
    }
  }, [])

  return (
    <Modal
      title=""
      footer=""
      centered
      className={s.modal}
      open={props.visible}
      onCancel={props.onCancel}
      wrapClassName={s.modal_wrap}
      width={maxModalSize}
      style={{ padding: '0' }}
      bodyStyle={{
        padding: '0',
        display: 'flex',
      }}
    >
      <div className={s.modal_body}>
        <div className={s.side}>
          <Link to="/">
            <img alt="логотип" src={logo} className={s.logo}></img>
          </Link>
        </div>
        <div className={s.main}>
          <div className={s.main_header}>{props.title}-коворкинг</div>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form className={s.main_body}>
                <div className={s.body_item}>
                  <div className={s['item-title']}>
                    <div
                      className={classNames(s['body-icon'], s.balance)}
                    ></div>
                    На Вашем балансе
                  </div>
                  <span>{authData.user?.balance} &#8381;</span>
                </div>
                <div className={s.body_item}>
                  <div className={s['item-title']}>
                    <div
                      className={classNames(s['body-icon'], s.membership)}
                    ></div>
                    Тип абонемента
                  </div>
                </div>
                <div className={classNames(s.body_item, s.body_options)}>
                  <fieldset className={s['item-option_list']}>
                    <legend className={s.options_title}>
                      Выберите один подходящий абонемент
                    </legend>
                    {props.promotions && props.promotions?.length > 0
                      ? props.tariffs?.map((tariffType) => {
                          let coworkingName = ''
                          modalObject.modal === 'beta'
                            ? (coworkingName = 'beta-kovorking')
                            : (coworkingName = 'alfa-kovorking')
                          if (tariffType.locationCode === coworkingName) {
                            return Object.values(tariffType.tariffs).map(
                              (tariffItem) => {
                                const rightPromo = props.promotions!.find(
                                  (promoElement) => {
                                    return (
                                      promoElement.locationCode ===
                                        coworkingName &&
                                      promoElement.subscriptionType ===
                                        tariffItem.visits
                                    )
                                  },
                                )
                                if (
                                  rightPromo &&
                                  rightPromo.subscriptionType !== 0
                                ) {
                                  return (
                                    <label
                                      className={s['option-label']}
                                      key={rightPromo.subscriptionType}
                                    >
                                      <Field name="plan">
                                        {({
                                          field,
                                        }: {
                                          field: {
                                            name: string
                                            value: string | number
                                          }
                                        }) => {
                                          return (
                                            <input
                                              {...field}
                                              type="radio"
                                              className={s.option}
                                              value={rightPromo.subscriptionType.toString()}
                                              checked={
                                                rightPromo.subscriptionType.toString() ===
                                                formik.values.plan
                                              }
                                              onChange={(
                                                evt: React.ChangeEvent<HTMLInputElement>,
                                              ) => {
                                                setSelectedPrice(
                                                  rightPromo.newCost,
                                                )
                                                formik.setFieldValue(
                                                  'plan',
                                                  evt.target.value,
                                                )
                                              }}
                                            />
                                          )
                                        }}
                                      </Field>
                                      <span className={s['option-text']}>
                                        {rightPromo.subscriptionType.toString()}{' '}
                                        {setDaysWord(
                                          rightPromo.subscriptionType,
                                        )}{' '}
                                        = {rightPromo.newCost} &#8381;
                                      </span>
                                    </label>
                                  )
                                } else {
                                  if (tariffItem.visits !== 0) {
                                    return (
                                      <label
                                        className={s['option-label']}
                                        key={tariffItem.visits}
                                      >
                                        <Field name="plan">
                                          {({
                                            field,
                                          }: {
                                            field: {
                                              name: string
                                              value: string | number
                                            }
                                          }) => {
                                            return (
                                              <input
                                                {...field}
                                                type="radio"
                                                className={s.option}
                                                value={tariffItem.visits.toString()}
                                                checked={
                                                  tariffItem.visits.toString() ===
                                                  formik.values.plan
                                                }
                                                onChange={(
                                                  evt: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                  setSelectedPrice(
                                                    tariffItem.cost,
                                                  )
                                                  formik.setFieldValue(
                                                    'plan',
                                                    evt.target.value,
                                                  )
                                                }}
                                              />
                                            )
                                          }}
                                        </Field>
                                        <span className={s['option-text']}>
                                          {tariffItem.visits.toString()}{' '}
                                          {setDaysWord(tariffItem.visits)} ={' '}
                                          {tariffItem.cost.toString()} &#8381;
                                        </span>
                                      </label>
                                    )
                                  } else return null
                                }
                              },
                            )
                          } else return null
                        })
                      : props.tariffs?.map((tariff) => {
                          let coworkingName = ''
                          modalObject.modal === 'beta'
                            ? (coworkingName = 'beta-kovorking')
                            : (coworkingName = 'alfa-kovorking')
                          if (tariff.locationCode === coworkingName) {
                            return Object.values(tariff.tariffs).map(
                              (tariffItem) => {
                                return (
                                  <label
                                    className={s['option-label']}
                                    key={tariffItem.visits}
                                  >
                                    <Field name="plan">
                                      {({
                                        field,
                                      }: {
                                        field: {
                                          name: string
                                          value: string | number
                                        }
                                      }) => {
                                        return (
                                          <input
                                            {...field}
                                            type="radio"
                                            className={s.option}
                                            value={tariffItem.visits.toString()}
                                            checked={
                                              tariffItem.visits.toString() ===
                                              formik.values.plan
                                            }
                                            onChange={(
                                              evt: React.ChangeEvent<HTMLInputElement>,
                                            ) => {
                                              setSelectedPrice(tariffItem.cost)
                                              formik.setFieldValue(
                                                'plan',
                                                evt.target.value,
                                              )
                                            }}
                                          />
                                        )
                                      }}
                                    </Field>
                                    <span className={s['option-text']}>
                                      {tariffItem.visits.toString()}{' '}
                                      {setDaysWord(tariffItem.visits)} ={' '}
                                      {tariffItem.cost.toString()} &#8381;
                                    </span>
                                  </label>
                                )
                              },
                            )
                          }
                          return null
                        })}
                  </fieldset>
                </div>
                <div className={s.button_group}>
                  {props.isLoading ? (
                    <Spin className={s.preloader} indicator={antIcon} />
                  ) : (
                    <Tooltip
                      placement="topLeft"
                      trigger="hover"
                      title={
                        bookingDisabled === true ? (
                          <div
                            style={{
                              padding: '13px 1px 10px 21px',
                              position: 'relative',
                              fontSize: '12px',
                              width: '187px',
                            }}
                          >
                            <img
                              src={exclamation}
                              alt="excl"
                              style={{
                                position: 'absolute',
                                top: '26px',
                                left: '5px',
                              }}
                            />
                            Недостаточно средств для покупки выбранного
                            абонемента.
                            <br />
                            Пополните баланс
                          </div>
                        ) : null
                      }
                    >
                      <span>
                        <Button
                          className={classNames('Button', s.book_button)}
                          disabled={bookingDisabled}
                          htmlType="submit"
                        >
                          Купить
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                  <Button
                    onClick={() => {
                      navigate(BALANCE_LINK)
                    }}
                    className={classNames('Button', s.topup_button)}
                  >
                    Пополнить баланс
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <div className={s.main_footer} onClick={props.setOption}>
            ←Выбрать другой коворкинг
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ModalBooking
