import classNames from 'classnames'
import Section from '../Section'
import s from './CompareSection.module.scss'
import FirstPromo from './images/first_promo.jpeg'
import { DASHBOARD_LINK, REGISTER_LINK } from '../../constants'
import Diagram from './images/Diagram'
import TickGrey from './images/TickGrey'
import NegationIcon from './images/NegationIcon'
import Tick from './images/Tick'
import CafeSvg from './images/CafeSVG'
import HomeSvg from './images/HomeSvg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import CurrentUserContext from '../../contexts/AuthContext'

export default function CompareSection() {
  const { authData } = useContext(CurrentUserContext)
  return (
    <Section className={s['compare-section']}>
      <div
        className={classNames('section_wrapper', s['compare-section-wrapper'])}
      >
        <div className={s['diagram-compare']}>
          <h2 className={classNames(s['compare-diagram-title'])}>
            ОФис VS&nbsp;<span className="text-accent">КОВОРКИНГ.</span>
            <br />
            Что выгоднее?
          </h2>
          <Diagram className={s['compare-diagram-pic']} />
          <p
            className={classNames(
              s['compare-subtitle'],
              s['compare-subtitle-wrapper'],
              'text-accent-bold',
            )}
          >
            Попробуйте все преимущества коворкинга на
            <span className="text-accent"> первом бесплатном посещении. </span>
            <span
              className={classNames(
                s['compare-subtitle'],
                s['compare-subtitle--addition'],
                'text-accent-bold',
              )}
            >
              Просто зарегистрируйтесь и приходите в любой удобный день
            </span>
          </p>
          <p
            className={classNames(
              s['compare-subtitle'],
              s['compare-subtitle--height'],
              'text-accent-bold',
            )}
          >
            Если вы думаете, что выгоднее иметь собственный офис, то посмотрите{' '}
            <span className="text-accent">
              сколько дополнительных платежей скрыто от вашего взора
            </span>
          </p>

          <div className={s['compare-table']}>
            <div
              className={classNames(
                s['compare-table-row'],
                s['compare-table-row--header'],
              )}
            >
              <div className={s['compare-table-item']}></div>
              <div className={s['compare-table-item']}>Офис</div>
              <div className={s['compare-table-item']}>Коворкинг</div>
            </div>
            <div className={s['compare-table-row']}>
              <div
                className={classNames(
                  s['compare-table-item'],
                  s['compare-table-item-text'],
                )}
              >
                Аренда
              </div>
              <div className={s['compare-table-item']}>
                <TickGrey className={s.tick} />
              </div>
              <div className={s['compare-table-item']}>
                <Tick className={s.tick} />
              </div>
            </div>
            <div className={s['compare-table-row']}>
              <div
                className={classNames(
                  s['compare-table-item'],
                  s['compare-table-item-text'],
                )}
              >
                Интернет
              </div>
              <div className={s['compare-table-item']}>
                <TickGrey className={s.tick} />
              </div>
              <div className={s['compare-table-item']}>
                <Tick className={s.tick} />
              </div>
            </div>
            <div className={s['compare-table-row']}>
              <div
                className={classNames(
                  s['compare-table-item'],
                  s['compare-table-item-text'],
                )}
              >
                Кофе
              </div>
              <div className={s['compare-table-item']}>
                <TickGrey className={s.tick} />
              </div>
              <div className={s['compare-table-item']}>
                <Tick className={s.tick} />
              </div>
            </div>
            <div className={s['compare-table-row']}>
              <div
                className={classNames(
                  s['compare-table-item'],
                  s['compare-table-item-text'],
                )}
              >
                Уборка
              </div>
              <div className={s['compare-table-item']}>
                <TickGrey className={s.tick} />
              </div>
              <div className={s['compare-table-item']}>
                <Tick className={s.tick} />
              </div>
            </div>
            <div className={s['compare-table-row']}>
              <div
                className={classNames(
                  s['compare-table-item'],
                  s['compare-table-item-text'],
                )}
              >
                Коммунальные услуги
              </div>
              <div className={s['compare-table-item']}>
                <TickGrey className={s.tick} />
              </div>
              <div className={s['compare-table-item']}>
                <Tick className={s.tick} />
              </div>
            </div>
            <div className={s['compare-table-row']}>
              <div
                className={classNames(
                  s['compare-table-item'],
                  s['compare-table-item-text'],
                )}
              >
                Парковка
              </div>
              <div className={s['compare-table-item']}>
                <TickGrey className={s.tick} />
              </div>
              <div className={s['compare-table-item']}>
                <Tick className={s.tick} />
              </div>
            </div>
            <div className={s['compare-table-row']}>
              <div
                className={classNames(
                  s['compare-table-item'],
                  s['compare-table-item-text'],
                )}
              >
                Бумага и принтер
              </div>
              <div className={s['compare-table-item']}>
                <TickGrey className={s.tick} />
              </div>
              <div className={s['compare-table-item']}>
                <Tick className={s.tick} />
              </div>
            </div>
            <div className={s['compare-table-row']}>
              <div
                className={classNames(
                  s['compare-table-item'],
                  s['compare-table-item--last-item'],
                  s['compare-table-item-text'],
                )}
              >
                Ежемесячный платеж*
              </div>
              <div className={s['compare-table-item']}>
                <TickGrey className={s.tick} />
              </div>
              <div className={s['compare-table-item']}>
                <NegationIcon className={s.tick} />
              </div>
            </div>
            <div
              className={classNames(
                s['compare-table-row'],
                s['compare-table-row--footer'],
              )}
            >
              <div className={s['compare-table-item']}>Сумма за месяц</div>
              <div className={s['compare-table-item']}>32 900 ₽</div>
              <div className={s['compare-table-item']}>8 900 ₽</div>
            </div>
          </div>
          <div className={s['compare-table-subtitle']}>
            <p className={s['compare-section-text']}>
              *За офис вы <span className="text-accent-bold">обязаны</span>{' '}
              платить ежемесячно, вне зависимости от того, пользовались ли вы им
              или нет.
            </p>
            <p className={s['compare-section-text']}>
              За коворкинг вы&nbsp;
              <span className={classNames('text-accent', 'text-accent-bold')}>
                платите лишь тогда, когда непосредственно его посещаете
              </span>
            </p>
          </div>
          <div className={s['button-wrapper']}>
            <Link to={authData.isLoggedIn ? DASHBOARD_LINK : REGISTER_LINK}>
              <button className={classNames('Button', s.button)}>
                Регистрация
              </button>
            </Link>
          </div>
        </div>
        <div className="divider"></div>
        <div className={s['compare-cards-wrapper']}>
          <h2 className={s['compare-cards-title']}>
            Не ходите в коворкинг -
            <span className="text-accent"> не платите!</span>
          </h2>
          <div className={s['compare-cards']}>
            <div
              className={classNames(s['compare-card'], s['compare-card--left'])}
            >
              <div>
                <h3 className={s['compare-card__header-title']}>
                  <span className="text-accent">Думаете</span> работать из кафе
                  удобнее?
                </h3>
              </div>
              <div className={s['compare-card-img-wrapper']}>
                <CafeSvg className={s['compare-card-img']} />
              </div>
              <div>
                <p className={s['compare-card__title']}>
                  Если вы думаете, что можете бесплатно работать в кафе каждый
                  день и не оставлять там ни копейки - вы ошибаетесь.{' '}
                  <span className="text-accent-bold">
                    Обычно фрилансер оставляет там от 600 рублей в день.
                  </span>
                </p>
              </div>
              <div>
                <p className={s['compare-card__subtitle']}>
                  Также в кафе вы столкнетесь с такими проблемами как:
                </p>
              </div>
              <div>
                <ul className={s['negation-list']}>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Отсутствие качественного интернета
                    </p>
                  </li>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Не везде есть розетки
                    </p>
                  </li>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Музыка и разговоры на фоне
                    </p>
                  </li>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Косые взгляды официантов, так как им не выгодно, чтобы вы
                      занимали столик весь день, заказав только один кофе
                    </p>
                  </li>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Отсутствие рабочей атмосферы, и как результат, падает ваша
                      концентрация
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={classNames(
                s['compare-card'],
                s['compare-card--right'],
              )}
            >
              <div>
                <h3 className={s['compare-card__header-title']}>
                  Почему неудобно{' '}
                  <span className="text-accent"> работать из дома?</span>
                </h3>
              </div>
              <div className={s['compare-card-img-wrapper']}>
                <HomeSvg className={s['compare-card-img']} />
              </div>
              <div>
                <p className={s['compare-card__title']}>
                  Есть и еще одно заблуждение, что работать из дома удобнее.
                  Зачастую, это не так.
                  <span className="text-accent-bold">
                    Работая из дома, сложно добиться максимальной концентрации в
                    работе.
                  </span>
                </p>
              </div>
              <div>
                <p className={s['compare-card__subtitle']}>
                  Дома вы столкнетесь с такими проблемами как:
                </p>
              </div>
              <div>
                <ul className={s['negation-list']}>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Вас постоянно будет отвлекать визуальный бытовой шум
                    </p>
                  </li>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Дома велик соблаз прилечь, отдохнуть, посмотреть сериал
                    </p>
                  </li>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Вы постоянно будете переключаться на бытовые дела
                    </p>
                  </li>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Соседям не интересно, что у вас важный созвон с клиентом.
                      У него ремонт и ему надо вешать эту полку с помощью дрели
                      прямо сейчас!
                    </p>
                  </li>
                  <li className={s['negation-item']}>
                    <NegationIcon className={s['negation-icon']} />
                    <p className={s['negation-item__title']}>
                      Домочадцы будут периодически отвлекать вопросами и
                      просьбами
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Link to={authData.isLoggedIn ? DASHBOARD_LINK : REGISTER_LINK}>
            {' '}
            <button className={classNames('Button', s.button)}>
              Попробовать коворкинг
            </button>
          </Link>
        </div>
        <div className="divider"></div>
        <div className={s['first-promo-title-wrapper']}>
          <h2 className={s['first-promo-title']}>
            Не уверены,
            <span className="text-accent"> что вам подойдёт коворкинг?</span>
          </h2>
          <p
            className={classNames(
              s['first-promo-subtitle'],
              'text-accent-bold',
              s['first-promo-subtitle--wider'],
            )}
          >
            лучше один раз увидеть, чем 100 раз услышать!
          </p>
        </div>
        <div className={s['first-promo']}>
          <div className={s['first-promo__pic']}>
            <LazyLoadImage
              src={FirstPromo}
              alt=""
              className={s['first-promo__img']}
            ></LazyLoadImage>
          </div>
          <div className={s['first-promo__text-content']}>
            <p className={classNames(s['first-promo__text-content-title'])}>
              Первое посещение коворкинга абсолютно{' '}
              <span className="text-accent">бесплатно!</span>
            </p>
            <p className={classNames(s['first-promo-subtitle'])}>
              Просто приходите в любой удобный вам день и{' '}
              <span className="text-accent">*</span>бесплатное посещение будет{' '}
              автоматически начислено после прохождения регистрации!
            </p>
            <p
              className={classNames(
                s['first-promo-subtitle'],
                s['first-promo-subtitle--addition'],
              )}
            >
              <span className="text-accent">* </span>акция действует в обоих
              коворкингах, вы можете получить по одному дню в альфа и бета
              коворкингах
            </p>
          </div>
        </div>
      </div>
    </Section>
  )
}
