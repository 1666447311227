import { Link } from 'react-router-dom'
import s from './Banner.module.scss'
import logo from './images/logo.svg'

const Banner: React.FC = () => {
  return (
    <div className={s.banner_wrapper}>
      <Link to="/">
        <img src={logo} className={s.logo} alt="logo" />
      </Link>
    </div>
  )
}

export default Banner
