import { useState, useContext } from 'react'
import s from './VerifyEmail.module.scss'
import classNames from 'classnames'
import { Button } from 'antd'
import CurrentUserContext from '../../contexts/AuthContext'
import { sendEmailVerify } from '../../api/api'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { SendEmailVerifyType } from '../../@types/assets'

const VerifyEmail = (props: {
  onLogout?: () => void
  onSendEmailVerify?: SendEmailVerifyType
  isLoading?: boolean
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { authData } = useContext(CurrentUserContext)
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: '#be763c',
      }}
      spin
    />
  )
  return (
    <div className={s.form}>
      <div
        className={classNames(
          'd-flex flex-column align-items-center',
          s.form_group,
          s.form_group__column,
        )}
      >
        <p className={s.title}>Подтвердите свой Email-адрес</p>
        <p className={s.text}>
          Для продолжения Вы должны подтвердить свой Email. Письмо с инструкцией
          направлено на <span className={s.accent}>{authData.user.email}</span>.
        </p>
        <p className={s.text}>
          Если Вы не получили письмо, запросите ещё одно.
        </p>
        <Button
          className={classNames('Button', s.submit_button)}
          type="primary"
          htmlType="submit"
          disabled={isLoading}
          onClick={() => {
            if (props.onSendEmailVerify) {
              setIsLoading(true)
              props.onSendEmailVerify(authData.user.email).finally(() => {
                setIsLoading(false)
              })
            }
          }}
        >
          <span className="spinner-border spinner-border-sm"></span>
          Отправить ещё письмо!
        </Button>
        <p
          className={s['form_group-link']}
          onClick={() => {
            if (props.onLogout) {
              props.onLogout()
            }
          }}
        >
          Выйти из аккаунта
        </p>
      </div>
    </div>
  )
}

export default VerifyEmail
