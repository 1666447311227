import CookieConsent from 'react-cookie-consent'
import cookiesIcon from './images/cookies_pic.svg'
import s from './Cookies.module.scss'
import { CONFIDENTIAL_LINK, TERMS_LINK } from '../../constants'
import 'react-lazy-load-image-component/src/effects/blur.css'

const Cookies = () => {
  return (
    <CookieConsent
      disableStyles={true}
      location="bottom"
      buttonClasses={s.okBtn}
      containerClasses={s.cookiesContainer}
      expires={90}
      buttonText="Ок"
      cookieName="cookieModal"
    >
      <div className={s.content}>
        <img alt="cookie" src={cookiesIcon} className={s.cookiesImg} />
        <div className={s.text}>
          Мы используем файлы cookie для улучшения функциональности и
          результатов работы Системы. Продолжив работу с Системой, вы
          соглашаетесь с{' '}
          <a
            href={TERMS_LINK}
            className={s.cookiesLink}
            target="_blank"
            rel="noreferrer"
          >
            Политикой обработки персональных данных
          </a>{' '}
          и{' '}
          <a
            href={CONFIDENTIAL_LINK}
            className={s.cookiesLink}
            target="_blank"
            rel="noreferrer"
          >
            Политикой конфиденциальности
          </a>
          .
        </div>
      </div>
    </CookieConsent>
  )
}

export default Cookies
