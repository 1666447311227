import Section from '../Section'
import s from './Profile.module.scss'
import classNames from 'classnames'
import { Button, Select } from 'antd'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import relativeTime from 'dayjs/plugin/relativeTime'

import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import MaskedInput from 'react-text-mask'
import { PHONE_MASK, PHONE_REG_EXP } from '../../constants'
import { useContext, useState } from 'react'
import CurrentUserContext from '../../contexts/AuthContext'
import { IProfile, UpdateType } from '../../@types/assets'
import { AxiosError } from 'axios'
import InputSkeleton from '../InputSkeleton/InputSkeleton'

const Profile = (props: { onUpdate?: UpdateType; isLoading: boolean }) => {
  dayjs.extend(relativeTime)
  dayjs.locale('ru')

  const [loginError, setLoginError] = useState<string | null>(null)

  const { authData } = useContext(CurrentUserContext)
  const initialValues: IProfile = {
    phone: `${authData.user?.phone.slice(0, 2)}(${authData.user?.phone.slice(
      2,
      5,
    )}) ${authData.user?.phone.slice(5, 8)}-${authData.user?.phone.slice(
      8,
      12,
    )}`,
    email: authData.user?.email,
    login: authData.user?.login,
    firstname: authData.user?.name,
    lastname: authData.user?.surname,
    gender: authData.user?.sex,
    password: '',
    password_confirmation: '',
  }
  const validationSchema = () => {
    return Yup.object().shape({
      phone: Yup.string()
        .matches(PHONE_REG_EXP, 'Введите корректный номер телефона')
        .required('Это поле обязательно'),
      login: Yup.string()
        .min(3, 'Длина логина должна быть больше 3 символов')
        .max(15, 'Длина логина должна быть меньше 15 символов')
        .required('Это поле обязательно'),
      email: Yup.string()
        .email('Введите корректный email')
        .required('Это поле обязательно'),
      password: Yup.string().min(8, 'Пароль должен содержать более 8 символов'),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref('password')],
        'Пароли не совпадают',
      ),
      firstname: Yup.string().nullable(),
      lastname: Yup.string().nullable(),
    })
  }

  const handleSubmit = (formValues: IProfile) => {
    if (props.onUpdate) {
      props.onUpdate(formValues)?.catch((err: AxiosError) => {
        setLoginError(JSON.parse(err.request.response).data.login)
      })
    }
  }

  return (
    <Section>
      <div className="section_wrapper">
        <div className={s.profile}>
          <div className={s.one}>
            <span className={s.section_header}>
              Редактирование данных профиля
            </span>
            <br />
            <span className={s.section_description}>
              Вы можете внести изменения в свои данные, указанные при
              регистрации
            </span>
          </div>

          <div className={s.section_main}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {(formik) => (
                <Form className={classNames(s.form, s.form_main)}>
                  <div
                    className={classNames(
                      s.form_container_one,
                      s.form_container,
                    )}
                  >
                    <div className={s.section_top}>Основные данные</div>
                    <div className={s.inputs_container}>
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label htmlFor="phone">Номер телефона</label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <Field name="phone">
                              {({ field }: { field: { name: string } }) => (
                                <MaskedInput
                                  {...field}
                                  mask={PHONE_MASK}
                                  readOnly={true}
                                  id="phone"
                                  placeholder="+7(___) ___-____"
                                  type="tel"
                                  className={classNames(
                                    s.form_control,
                                    'form-control',
                                  )}
                                />
                              )}
                            </Field>
                          )}
                        </div>
                        <div className={s['alert-container']}>
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className={s.alert}
                          />
                        </div>
                      </div>
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label htmlFor="login">Логин</label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <Field
                              autoComplete="off"
                              id="login"
                              name="login"
                              type="text"
                              onInput={() => {
                                setLoginError(null)
                              }}
                              className={classNames(
                                s.form_control,
                                'form-control',
                              )}
                            />
                          )}
                        </div>
                        <div className={s['alert-container']}>
                          {formik.errors.login ? (
                            <ErrorMessage
                              name="login"
                              component="div"
                              className={s.alert}
                            />
                          ) : (
                            <div className={s.alert}>{loginError}</div>
                          )}
                        </div>
                      </div>
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label htmlFor="email">E-mail</label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <Field
                              readOnly={true}
                              id="email"
                              name="email"
                              type="email"
                              className={classNames(
                                s.form_control,
                                'form-control',
                              )}
                            />
                          )}
                        </div>
                        <div className={s['alert-container']}>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className={s.alert}
                          />
                        </div>
                      </div>
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label htmlFor="password">Новый пароль</label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <Field
                              id="password"
                              name="password"
                              type="password"
                              autoComplete="new-password"
                              placeholder="Введите пароль"
                              className={classNames(
                                s.form_control,
                                'form-control',
                              )}
                            />
                          )}
                        </div>
                        <div className={s['alert-container']}>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className={s.alert}
                          />
                        </div>
                      </div>
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label htmlFor="password_confirmation">
                            Повторите новый пароль
                          </label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <Field
                              id="password_confirmation"
                              name="password_confirmation"
                              type="password"
                              placeholder="Повторите пароль"
                              className={classNames(
                                s.form_control,
                                'form-control',
                              )}
                            />
                          )}
                        </div>
                        <div className={s['alert-container']}>
                          <ErrorMessage
                            name="password_confirmation"
                            component="div"
                            className={s.alert}
                          />
                        </div>
                      </div>
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label>Дата регистрации</label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <span className={s.date}>
                              {`${dayjs(
                                authData.user?.createdAt,
                                'YYYY-MM-DDThh:mm:ss',
                              ).fromNow()} (${authData.user?.createdAt.slice(
                                0,
                                10,
                              )})`}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      s.form_container_two,
                      s.form_container,
                    )}
                  >
                    <div className={s.section_top}>Дополнительные данные</div>
                    <div className={s.inputs_container}>
                      {' '}
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label htmlFor="firstname">Имя</label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <Field
                              id="firstname"
                              name="firstname"
                              type="text"
                              className={classNames(
                                s.form_control,
                                'form-control',
                              )}
                            />
                          )}
                        </div>
                        <div className={s['alert-container']}>
                          <ErrorMessage
                            name="firstname"
                            component="div"
                            className={s.alert}
                          />
                        </div>
                      </div>
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label htmlFor="lastname">Фамилия</label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <Field
                              id="lastname"
                              name="lastname"
                              type="text"
                              className={classNames(
                                s.form_control,
                                'form-control',
                              )}
                            />
                          )}
                        </div>
                        <div className={s['alert-container']}>
                          <ErrorMessage
                            name="lastname"
                            component="div"
                            className={s.alert}
                          />
                        </div>
                      </div>
                      <div className={s.form_group}>
                        <div className={s['field-container']}>
                          <label htmlFor="gender">Пол</label>
                          {props.isLoading ? (
                            <InputSkeleton className={s.input_skeleton} />
                          ) : (
                            <Field name="gender">
                              {({
                                field,
                              }: {
                                field: {
                                  name: string
                                }
                              }) => (
                                <Select
                                  {...field}
                                  id="gender"
                                  onChange={(value) => {
                                    formik.setFieldValue('gender', value)
                                  }}
                                  className={classNames(
                                    s.form_control,
                                    'form-control',
                                    s['form_control__select'],
                                  )}
                                  options={[
                                    { value: 1, label: 'Мужской' },
                                    { value: 2, label: 'Женский' },
                                    { value: 0, label: 'Не определился' },
                                  ]}
                                />
                              )}
                            </Field>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Button
                    htmlType="submit"
                    className={classNames('Button', s.button_save, s.four)}
                  >
                    Сохранить
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Profile
