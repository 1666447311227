import { Link } from 'react-router-dom'
import { DASHBOARD_LINK, PROFILE_LINK } from '../../constants'
import s from './AdminHeader.module.scss'
import { UserOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import AuthContext from '../../contexts/AuthContext'
import { useContext } from 'react'
import classNames from 'classnames'
import Logo from '../svg/logo'
import { LogoutType, OpenMenuType } from '../../@types/assets'

const AdminHeader = (props: {
  onLogout: LogoutType
  handleOpenMenu: OpenMenuType
  collapsed: boolean
}) => {
  const { authData } = useContext(AuthContext)

  return (
    <header className={s['section-header']}>
      <div className={s.header}>
        <Button
          type="primary"
          onClick={props.handleOpenMenu}
          className={s.hamburger}
        >
          <MenuUnfoldOutlined />
        </Button>
        <Link to={DASHBOARD_LINK} className={s['logo-container']}>
          <Logo className={s.logo} />
        </Link>
        <Popover
          content={
            <div className={s.popover}>
              <Link className={s['popover-link']} to={PROFILE_LINK}>
                Профиль
              </Link>
              <span
                className={s['popover-link']}
                onClick={() => {
                  props.onLogout()
                }}
              >
                Выход
              </span>
            </div>
          }
          placement="bottomRight"
          trigger="click"
        >
          <div className={s.userContainer}>
            <span className={s.headerUser}>
              {authData.user?.name && authData.user?.surname
                ? `${authData.user?.name} ${authData.user?.surname}`
                : authData.user?.login}
            </span>
            <UserOutlined className={classNames('Button', s.headerButton)} />
          </div>
        </Popover>
      </div>
    </header>
  )
}

export default AdminHeader
