export default function PhoneSVG(props: { className?: string }) {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path
                d="M10.1304 11.7038C10.1304 11.7038 11.3958 10.9795 11.727 10.8054C12.0573 10.6307 12.3995 10.5866 12.6073 10.7135C12.922 10.906 15.5645 12.6639 15.787 12.8195C16.0095 12.9754 16.1167 13.4207 15.8107 13.857C15.5061 14.2933 14.1004 16.0183 13.5048 15.9999C12.9082 15.9805 10.4279 15.9261 5.75077 11.2476C1.07453 6.57064 1.01922 4.08966 1.00016 3.49309C0.981094 2.89683 2.70609 1.49087 3.14234 1.18587C3.57921 0.881174 4.02515 0.995863 4.18015 1.21024C4.3564 1.45431 6.09389 4.08841 6.28514 4.39029C6.41608 4.59655 6.36795 4.94061 6.19327 5.27124C6.01952 5.6025 5.29514 6.86783 5.29514 6.86783C5.29514 6.86783 5.80577 7.73878 7.53264 9.46537C9.25982 11.1923 10.1304 11.7038 10.1304 11.7038Z"
                fill="#BE763C"
                stroke="#303030"
                strokeMiterlimit="10"
            />
        </svg>
    );
}
