import { useEffect, useState } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import s from './PasswordReset.module.scss'
import classNames from 'classnames'
import { Button } from 'antd'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { LOGIN_LINK } from '../../constants'
import {
  IResetPassword,
  ResetType,
  SendPasswordEmailType,
} from '../../@types/assets'

const PasswordReset = (props: {
  onResetPassword?: ResetType
  token: string | null
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState<false | true>(false)
  const [serverError, setServerError] = useState<string | undefined>('')
  const initialValues = {
    email: searchParams.get('email') || '',
    password: '',
    password_confirmation: '',
  }

  const validationSchema = () => {
    return Yup.object().shape({
      email: Yup.string()
        .email('Введите корректный email')
        .required('Это поле обязательно'),
      password: Yup.string()
        .min(6, 'Пароль должен содержать более 6 символов')
        .required('Это поле обязательно'),
      password_confirmation: Yup.string()
        .oneOf([Yup.ref('password'), undefined], 'Пароли не совпадают')
        .required('Это поле обязательно'),
    })
  }

  const onSubmit = (formValue: {
    email: string
    password: string
    password_confirmation: string
  }) => {
    setLoading(true)
    setServerError('')
    if (props.onResetPassword && props.token) {
      const token = props.token
      props
        .onResetPassword({ ...formValue, token })
        .then()
        .finally(() => setLoading(false))
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className={s.form} noValidate autoComplete="false">
          <span>Сброс пароля</span>
          <div
            className={classNames(
              s.form_group,
              s['form_group-field'],
              s.form_group__column,
            )}
          >
            <label htmlFor="email">Email</label>
            <Field
              name="email"
              type="email"
              id="email"
              placeholder="Ваш email"
              onInput={() => {
                setServerError('')
              }}
              className={classNames(s.form_control, 'form-control')}
            />
            <div className={s['alert-container']}>
              {formik.errors.email ? (
                <ErrorMessage
                  name="email"
                  component="div"
                  className={s.alert}
                />
              ) : (
                <div className={s.alert}>{serverError}</div>
              )}
            </div>
          </div>
          <div
            className={classNames(
              s.form_group,
              s['form_group-field'],
              s.form_group__column,
            )}
          >
            <label htmlFor="password">Новый пароль</label>
            <Field
              name="password"
              type="password"
              id="password"
              placeholder="Введите новый пароль"
              onInput={() => {
                setServerError('')
              }}
              className={classNames(s.form_control, 'form-control')}
            />
            <div className={s['alert-container']}>
              <ErrorMessage
                name="password"
                component="div"
                className={s.alert}
              />
            </div>
          </div>
          <div
            className={classNames(
              s.form_group,
              s['form_group-field'],
              s.form_group__column,
            )}
          >
            <label htmlFor="password_confirmation">Подтверждение пароля</label>
            <Field
              name="password_confirmation"
              autoComplete="off"
              type="password"
              id="password_confirmation"
              placeholder="Повторите новый пароль"
              onInput={() => {
                setServerError('')
              }}
              className={classNames(s.form_control, 'form-control')}
            />
            <div className={s['alert-container']}>
              <ErrorMessage
                name="password_confirmation"
                component="div"
                className={s.alert}
              />
            </div>
          </div>
          <div
            className={classNames(
              'd-flex flex-column align-items-center',
              s.form_group,
              s.form_group__column,
            )}
          >
            <Button
              className={classNames('Button', s.submit_button)}
              type="primary"
              htmlType="submit"
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              Сбросить пароль
            </Button>
            <Link to={LOGIN_LINK}>
              <p className={s['form_group-link']}>Назад к авторизации</p>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default PasswordReset
