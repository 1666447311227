import { useEffect, useMemo, useState } from 'react'
import s from './AdminSubscriptions.module.scss'
import { Column, TableOptions, useTable } from 'react-table'
import {
  SlidersOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import { GetSubscriptionsType, IAdminSubscription } from '../../@types/assets'

const antTableIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
      color: '#be763c',
    }}
    spin
  />
)

const AdminSubscriptions = (props: {
  onGetSubscriptions?: GetSubscriptionsType
  isLoading?: boolean
}) => {
  document.title = 'Список всех подписок | Bekker-place Admin'
  const navigate = useNavigate()

  const [adminSubscriptions, setAdminSubscriptions] = useState<
    IAdminSubscription[]
  >([])

  const data = useMemo(() => {
    if (adminSubscriptions.length !== 0) {
      return adminSubscriptions
    } else {
      return []
    }
  }, [adminSubscriptions])

  const columns: Column<IAdminSubscription>[] = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Локация',
        accessor: 'locationId',
        Cell: (props) => {
          return (
            <>
              <div className={s.coworking_cell}>
                <span>
                  {props.value === 3 ? 'Альфа Коворкинг' : 'Бета Коворкинг'}
                </span>

                <button
                  className={s.edit}
                  onClick={() => {
                    navigate(
                      `/admin/location/${props.row.original.id}/settings`, // тут айдишник не лоакции, а подписки!!
                    )
                  }}
                >
                  <SlidersOutlined style={{ color: '#fff' }} />
                </button>
              </div>
            </>
          )
        },
      },
      {
        Header: 'День',
        accessor: 'costDay',
      },
      {
        Header: '7 дней',
        accessor: 'cost7days',
      },
      {
        Header: '14 дней',
        accessor: 'cost14days',
      },
      {
        Header: '30 дней',
        accessor: 'cost30days',
      },
      {
        Header: '360 дней',
        accessor: 'cost360days',
      },
      {
        Header: 'Действия',
        accessor: 'id',
        id: 'actions',
        Cell: (value) => {
          return (
            <button
              className={s.edit}
              onClick={() => {
                navigate(`/admin/subscriptions/${value.value}/edit`)
              }}
            >
              <EditOutlined style={{ color: '#fff' }} />
            </button>
          )
        },
      },
    ],
    [],
  )

  const options: TableOptions<IAdminSubscription> = {
    data,
    columns,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
  } = useTable(options)

  useEffect(() => {
    if (props.onGetSubscriptions) {
      props.onGetSubscriptions(2, 1).then((res) => {
        setAdminSubscriptions(res.data.data)
      })
    }
  }, [])

  return (
    <div className={s.users}>
      <h1 className={s.title}>Список всех подписок</h1>
      <div className={s.table_container}>
        <div className={s.table_wrapper}>
          <table {...getTableProps()} className={s.table_main}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {!props.isLoading ? null : (
          <div className={s['table-spinner-container']}>
            <Spin indicator={antTableIcon} />
          </div>
        )}
      </div>
    </div>
  )
}

export default AdminSubscriptions
