import axios, { AxiosResponse } from 'axios'
import {
  IBalances,
  ICard,
  IGates,
  IGuests,
  IInviteForm,
  ILocations,
  IPromotions,
  ISubscriptions,
  IUpBalance,
  Tariff,
  IUserResponse,
  IRegister,
  ILoginRespose,
  IAdminUsersResponse,
  IAdminUserResponse,
  IUpdateUser,
  IUpdateAdminUser,
  IAdminSubscriptions,
  IAdminSubscription,
  IUpdateAdminSubscription,
  IAdminSubscriptionResponse,
  IAdminPromocodes,
  IAdminPromocode,
  IAdminAddPromocode,
  IAdminAddPromocodeResponse,
  IAdminPromotions,
  IAdminPromotionForEdit,
  IAdminUpdatePromotion,
  IAdminAddPromotion,
  IAdminLocationSettingsForEdit,
  IAdminLocationSettingsResponse,
  IAdminLocationResponse,
  IAdminUsersStatisticsResponse,
  IAdminOrdersStatisticsResponse,
  IAdminLocationsStatisticsResponse,
  IAdminTransfersResponse,
  ITariffResponse,
  IRegisterRespose,
  IPromocodeResponse,
  ISubscriptionsResponse,
  IGuestTariffResponse,
  IResetPassword,
  IVerifyEmail,
} from '../@types/assets'
import { BASE_URL } from '../constants'
import { Cookies } from 'react-cookie'
import {
  IAdminAnalyticsCycle,
  IAdminAnalyticsDays,
  IAdminAnalyticsTimes,
} from '../@types/assets/Analytics'

const cookie = new Cookies()

const apiClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
})

// export const getToken = () => {
//   return apiClient.get('/sanctum/csrf-cookie', {
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//   })
// }

export const register = ({
  phone,
  password,
  password_confirmation,
  email,
  login,
  agreedOferta,
}: IRegister) => {
  return apiClient
    .post<IRegisterRespose>(
      `/auth/register`,
      {
        phone,
        password,
        password_confirmation,
        email,
        login,
        agreedOferta,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const login = (phone: string, password: string) => {
  return apiClient.post<ILoginRespose>(
    `/auth/login`,
    {
      phone,
      password,
    },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
}

export const logout = () => {
  return apiClient.post(
    `/auth/logout`,
    {},
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    },
  )
}

export const getUserInfo = () => {
  return apiClient
    .get<IUserResponse>(`/front/v1/users/me`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      if (res.status === 200) return res?.data
      return Promise.reject()
    })
    .then((res) => {
      return res
    })
}

export const verifyEmail = ({
  userId,
  token,
  expires,
  signature,
}: IVerifyEmail) => {
  return apiClient
    .get(
      `/auth/email/verify/${userId}/${token}?expires=${expires}&signature=${signature}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getOrderStatus = (order: number) => {
  return apiClient
    .get<{ message: string }>(`/front/v1/orders/${order}/check`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getLocationSettings = (id: number) => {
  return apiClient
    .get<IGuestTariffResponse>(`/front/v1/locations/${id}/settings`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getUserCard = (id: number) => {
  return apiClient
    .get<{ card: ICard }>(`/front/v1/users/${id}/card`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      if (res.status === 200) return res.data
      return Promise.reject()
    })
    .then((res) => {
      return res.card
    })
}

export const getSubscriptions = (id: number) => {
  return apiClient
    .get<{ subscriptions: ISubscriptions }>(
      `/front/v1/users/${id}/subscriptions`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) return res?.data
      return Promise.reject()
    })
}

export const getLocations = () => {
  return apiClient
    .get<ILocations>(`/front/v1/locations`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      if (res.status === 200) return res.data
      return Promise.reject()
    })
}

export const getTariffs = () => {
  return apiClient
    .get<ITariffResponse>(`/front/v1/subscriptions/tariffs`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      if (res.status === 200) return res.data
      return Promise.reject()
    })
}

export const getGuests = (cardId: number) => {
  return apiClient
    .get<IGuests>(`/front/v1/cards/${cardId}/guests`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
  // .then((res) => {
  //   if (res.status === 200) return res.data
  //   return Promise.reject()
  // })
}

export const getBalanceDetails = (userId: number) => {
  return apiClient
    .get<IBalances>(`/front/v1/balances/${userId}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getPromotions = () => {
  return apiClient
    .get<IPromotions>(`/front/v1/promotions/active`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getLandingPromotions = () => {
  return apiClient
    .get<IPromotions>(`/v1/promotions/active`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getAdminUsers = (perPage: number, page: number) => {
  return apiClient
    .get<IAdminUsersResponse>(
      `/front/v1/admin/users/?perPage=${perPage}&page=${page}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const searchUser = (login: string, page: number, perPage: number) => {
  return apiClient
    .get<IAdminUsersResponse>(
      `front/v1/admin/users/?login=${login}&page=${page}&perPage=${perPage}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      if (res.status === 200) return res.data
      return Promise.reject()
    })
}

export const getAdminUser = (id: number) => {
  return apiClient
    .get<IAdminUserResponse>(`/front/v1/admin/users/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getAdminUserSubscriptions = (id: number) => {
  return apiClient
    .get<ISubscriptionsResponse>(`front/v1/admin/users/${id}/subscriptions`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getAdminSubscriptions = (perPage: number, page: number) => {
  return apiClient
    .get<IAdminSubscriptions>(
      `/front/v1/admin/subscriptions/?perPage=${perPage}&page=${page}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminSubscription = (id: number) => {
  return apiClient
    .get<IAdminSubscriptionResponse>(`/front/v1/admin/subscriptions/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getAdminPromocodes = (perPage: number, page: number) => {
  return apiClient
    .get<IAdminPromocodes>(
      `/front/v1/admin/promocodes/?perPage=${perPage}&page=${page}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminPromotions = (perPage: number, page: number) => {
  return apiClient
    .get<IAdminPromotions>(
      `/front/v1/admin/promotions/?perPage=${perPage}&page=${page}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminPromotion = (id: number) => {
  return apiClient
    .get<IAdminUpdatePromotion>(`/front/v1/admin/promotions/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getAdminLocationSettings = (id: number) => {
  return apiClient
    .get<IAdminLocationSettingsResponse>(
      `/front/v1/admin/locations/${id}/settings`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminAnalyticsForDays = (
  rangeStart: string,
  rangeEnd: string,
) => {
  return apiClient
    .get<IAdminAnalyticsDays>(
      `/front/v1/admin/analytics/days-load?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminAnalyticsForTimes = (
  rangeStart: string,
  rangeEnd: string,
) => {
  return apiClient
    .get<IAdminAnalyticsTimes>(
      `/front/v1/admin/analytics/times-load?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminAnalyticsCyclesLine = (
  rangeStart: string,
  rangeEnd: string,
) => {
  return apiClient
    .get<IAdminAnalyticsCycle>(
      `/front/v1/admin/analytics/cycles-line?rangeStart=${rangeStart}&rangeEnd=${rangeEnd}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminDashboardLocation = (id: number) => {
  return apiClient
    .get<IAdminLocationResponse>(`/front/v1/admin/locations/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const getAdminUsersStatistics = () => {
  return apiClient
    .get<IAdminUsersStatisticsResponse>(
      `/front/v1/admin/users/dashboard-stat`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminOrdersStatistics = () => {
  return apiClient
    .get<IAdminOrdersStatisticsResponse>(
      `/front/v1/admin/orders/dashboard-stat`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminLocationsStatistics = (id: number) => {
  return apiClient
    .get<IAdminLocationsStatisticsResponse>(
      `/front/v1/admin/locations/${id}/dashboard-stat`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const getAdminTransfers = (perPage: number, page: number) => {
  return apiClient
    .get<IAdminTransfersResponse>(
      `/front/v1/admin/device-transfers/?perPage=${perPage}&page=${page}`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const updateAdminLocationSettings = (
  id: number,
  {
    guestCostHour,
    guestSeatsFreeHour,
    guestSeatsMax,
  }: IAdminLocationSettingsForEdit,
) => {
  return apiClient
    .put(
      `/front/v1/admin/locations/${id}/settings`,
      { guestCostHour, guestSeatsFreeHour, guestSeatsMax },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const updateAdminPromotion = (
  id: number,
  { expiredAt, newCost, subscriptionId, subscriptionType }: IAdminAddPromotion,
) => {
  return apiClient
    .put(
      `/front/v1/admin/promotions/${id}`,
      { expiredAt, newCost, subscriptionId, subscriptionType },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const addAdminPromotion = ({
  subscriptionId,
  subscriptionType,
  newCost,
  expiredAt,
}: IAdminAddPromotion) => {
  return apiClient
    .post(
      `/front/v1/admin/promotions`,
      { subscriptionId, subscriptionType, newCost, expiredAt },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const deleteAdminPromotion = (id: number) => {
  return apiClient
    .delete(`/front/v1/admin/promotions/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const deleteAdminPromocode = (id: number) => {
  return apiClient
    .delete(`/front/v1/admin/promocodes/${id}`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${cookie.get('token')}`,
      },
    })
    .then((res) => {
      return res.data
    })
}

export const addAdminPromocode = ({
  daysCounter,
  subscriptionId,
  discount,
  expiresIn,
  usagesLeft,
  userId,
}: IAdminAddPromocode) => {
  return apiClient
    .post<IAdminAddPromocodeResponse>(
      `/front/v1/admin/promocodes`,
      {
        daysCounter,
        subscriptionId,
        discount,
        expiresIn,
        usagesLeft,
        userId,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const updateAdminUser = ({
  id,
  login,
  email,
  phone,
  name,
  surname,
  sex,
  balance,
  banned,
  entranceAccess,
  subscriptions,
}: IUpdateAdminUser) => {
  return apiClient
    .put(
      `/front/v1/admin/users/${id}`,
      {
        login,
        email,
        phone,
        name,
        surname,
        sex,
        balance,
        banned,
        entranceAccess,
        subscriptions,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const updateAdminSubscription = ({
  id,
  costDay,
  cost7Days,
  cost14Days,
  cost30Days,
  cost360Days,
}: IUpdateAdminSubscription) => {
  return apiClient
    .put(
      `/front/v1/admin/subscriptions/${id}`,
      { costDay, cost7Days, cost14Days, cost30Days, cost360Days },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const updateUser = ({
  id,
  phone,
  login,
  email,
  password,
  password_confirmation,
  firstname,
  lastname,
  gender,
}: IUpdateUser) => {
  return apiClient
    .put(
      `/front/v1/users/${id}`,
      {
        phone,
        login,
        email,
        password,
        password_confirmation,
        name: firstname,
        surname: lastname,
        sex: gender,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res
    })
}

export const sendPasswordEmail = (email: string) => {
  return apiClient.post(
    '/auth/password/email',
    { email },
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  )
}

export const resetPassword = ({
  email,
  password,
  password_confirmation,
  token,
}: IResetPassword) => {
  return apiClient
    .post<{ message: string }>(
      '/auth/password/reset',
      { email, password, password_confirmation, token },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const sendEmailVerify = (email: string) => {
  return apiClient
    .post(
      `/auth/email/resend`,
      {
        email,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const activatePromocode = (promocode: string) => {
  return apiClient
    .post<IPromocodeResponse>(
      `/front/v1/promocodes/activate`,
      {
        promocode,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const openGates = (subscriptionId: number) => {
  return apiClient
    .post<IGates>(
      `/front/v1/user-subscriptions/${subscriptionId}/open-gate`,
      {},
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const inviteGuest = (cardId: number, guestData: IInviteForm) => {
  return apiClient
    .post<IGates>(
      `/front/v1/cards/${cardId}/guests/invite`,
      {
        inviteDate: guestData.inviteDate,
        subscription: guestData.subscription,
        note: guestData.note,
        count: guestData.count,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const upBalance = (amount: number, userId: number) => {
  return apiClient
    .post<IUpBalance>(
      `/front/v1/balances/${userId}/pay`,
      {
        orderType: 'virtual-money',
        amount,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}

export const buySubscription = (
  subscriptionType: number,
  subscriptionId: number,
) => {
  return apiClient
    .post(
      `/front/v1/user-subscriptions/buy`,
      {
        subscriptionType,
        subscriptionId,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${cookie.get('token')}`,
        },
      },
    )
    .then((res) => {
      return res.data
    })
}
