import { Link } from 'react-router-dom'
import Section from '../Section'
import s from './NotFound.module.scss'

const NotFound = (props: { title: string }) => {
  document.title = props.title
  return (
    <Section>
      <div className={s.container}>
        <span className={s.number}>404</span>
        <span className={s.text}>Страница не найдена</span>
        <Link to="/" className={s.link}>
          Вернуться на сайт
        </Link>
      </div>
    </Section>
  )
}

export default NotFound
