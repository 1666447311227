import { createContext } from 'react'

const tariffObject: {
  tariff: string
  setTariff: React.Dispatch<React.SetStateAction<string>> | VoidFunction
} = {
  tariff: '1',
  setTariff: () => {},
}

export default createContext({
  tariffObject: { ...tariffObject },
})
