import { CloseOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import s from './LkTopMenu.module.scss'
import Profile from '../svg/profile'
import {
  ADMIN_LINK,
  CONFIDENTIAL_LINK,
  DASHBOARD_LINK,
  GUESTS_LINK,
  PROFILE_LINK,
} from '../../constants'
import { Link } from 'react-router-dom'
import { Cookies } from 'react-cookie'
import { LogoutType, SetActiveTopMenuType } from '../../@types/assets'

const cookie = new Cookies()

const LkTopMenu = (props: {
  visible: boolean
  setActiveTopMenu: SetActiveTopMenuType
  onLogout: LogoutType
}) => {
  return (
    <div
      className={classNames(
        s.topMenu,
        !props.visible ? s.topMenuInvisible : '',
      )}
    >
      <div className={s.menuContent}>
        <CloseOutlined
          className={s.closeIcon}
          onClick={() => {
            props.setActiveTopMenu(false)
          }}
        />
        <Profile className={s.profile} />
        <nav>
          <ul className={s.menu}>
            {cookie.get('isAdmin') ? (
              <li
                className={s['menu-item']}
                onClick={() => {
                  props.setActiveTopMenu(false)
                }}
              >
                <Link className={s['menu-link']} to={ADMIN_LINK}>
                  Админ-панель
                </Link>
              </li>
            ) : (
              <></>
            )}
            <li
              className={s['menu-item']}
              onClick={() => {
                props.setActiveTopMenu(false)
              }}
            >
              <Link className={s['menu-link']} to={DASHBOARD_LINK}>
                Главная
              </Link>
            </li>
            <li
              className={s['menu-item']}
              onClick={() => {
                props.setActiveTopMenu(false)
              }}
            >
              <Link className={s['menu-link']} to={PROFILE_LINK}>
                Профиль
              </Link>
            </li>
            <li
              className={s['menu-item']}
              onClick={() => {
                props.setActiveTopMenu(false)
              }}
            >
              <Link className={s['menu-link']} to={GUESTS_LINK}>
                Гости
              </Link>
            </li>
            <li
              className={s['menu-item']}
              onClick={() => {
                props.setActiveTopMenu(false)
              }}
            >
              <Link className={s['menu-link']} to="/">
                ← Вернуться на сайт
              </Link>
            </li>
            <li
              className={s['menu-item']}
              onClick={() => {
                props.setActiveTopMenu(false)
                props.onLogout()
              }}
            >
              <span className={s['menu-link']}>Выход</span>
            </li>
            <li
              className={s['menu-item']}
              onClick={() => {
                props.setActiveTopMenu(false)
              }}
            >
              <a className={s['menu-link']} href={CONFIDENTIAL_LINK}>
                Политика конфиденциальности
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default LkTopMenu
