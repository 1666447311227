import s from './ActiveGuests.module.scss'
import classNames from 'classnames'
import { useTable, TableOptions, Column, useGlobalFilter } from 'react-table'
import React, { useEffect, useMemo, useState } from 'react'
import ModalQR from '../ModalQR/ModalQR'
import { LoadingOutlined, UpOutlined, DownOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { IGuest, IGuestTable } from '../../@types/assets'
import GlobalFilter from '../GlobalFilter/GlobalFilter'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const ActiveGuests = (props: { guests?: IGuest[]; isLoading: boolean }) => {
  dayjs.locale('ru')
  const [modal, setModal] = useState<string | null>(null)
  const data = useMemo((): IGuestTable[] => {
    if (props.guests) {
      return props.guests
    } else {
      return []
    }
  }, [props.guests])

  const columns: Column<IGuestTable>[] = React.useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id', // accessor is the "key" in the data
      },
      {
        Header: 'Локация',
        accessor: 'locationId',
        Cell: (props) => {
          return <span>{props.value === 3 ? 'Альфа' : 'Бета'}</span>
        },
      },
      {
        Header: 'Примечание',
        accessor: 'note',
      },
      {
        Header: 'Где гость',
        accessor: 'isInside',
        Cell: (props) => {
          return (
            <div
              className={classNames(
                s['is-in'],
                props.value === 0 ? s['in-false'] : s['in-true'],
              )}
            >
              {props.value === 0 ? 'Не входил' : 'Вошёл'}
            </div>
          )
        },
      },
      {
        Header: 'Статус',
        accessor: 'status',
        Cell: (props) => {
          return (
            <div
              className={classNames(
                s.status,
                props.value === '0' ? s['status-false'] : s['status-true'],
              )}
            ></div>
          )
        },
      },
      {
        Header: 'Действует до',
        accessor: 'expiredAt',
        Cell: (cellProps) => {
          return (
            <span>
              {/* {new Date(cellProps.value).toLocaleDateString('ru', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              })} */}
              {dayjs(cellProps.value).format('DD MMM YYYY, HH:mm')}
            </span>
          )
        },
      },
      {
        Header: 'QR-код',
        accessor: 'number',
        Cell: (props) => {
          const qr = props.value
          return <div className={s.qr} onClick={() => setModal(qr)}></div>
        },
      },
    ],
    [],
  )

  const options: TableOptions<IGuestTable> = {
    data,
    columns,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(options)

  const { globalFilter } = state

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
        color: '#be763c',
      }}
      spin
    />
  )

  return (
    <>
      <div className={s.section_top}>
        <div className={s.active}></div>
        Активные гости
      </div>
      <div className={s.section_bottom}>
        <div className={s.table_wrapper}>
          {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
          <table {...getTableProps()} className={s.table}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      //  {...column.getHeaderProps(column.getSortByToggleProps())}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                      {/* {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className={s.arrow}>
                            &#8593;
                            <span className={s.arrow_unactive}>&#8595;</span>
                          </span>
                        ) : (
                          <span className={s.arrow}>
                            <span className={s.arrow_unactive}>&#8593;</span>
                            &#8595;
                          </span>
                        )
                      ) : (
                        <span className={classNames(s.arrow, s.arrow_unactive)}>
                          &#8593;&#8595;
                        </span>
                      )} */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {props.guests?.length !== 0
                ? rows.map((row) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                : null}
            </tbody>
          </table>
        </div>
        {!props.isLoading ? null : (
          <div className={s['table-spinner-container']}>
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>

      {modal && (
        <ModalQR
          qr={modal}
          visible={modal ? true : false}
          onCancel={() => setModal(null)}
        />
      )}
    </>
  )
}

export default ActiveGuests
