import s from './AdminPromocodes.module.scss'
import { useEffect, useMemo, useState } from 'react'
import {
  Column,
  TableOptions,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import {
  QrcodeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  ADMIN_ADD_PROMOCODES_LINK,
  STARTUP_PAGINATION_DATA,
} from '../../constants'
import ModalQR from '../ModalQR/ModalQR'
import {
  DeletePromocodeType,
  GetPromocodesType,
  IAdminPromocode,
  IPagination,
} from '../../@types/assets'
import GlobalFilter from '../GlobalFilter/GlobalFilter'
import classNames from 'classnames'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { useAsyncDebounce } from 'react-table'

const antTableIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
      color: '#be763c',
    }}
    spin
  />
)

const AdminPromocodes = (props: {
  onGetPromocodes?: GetPromocodesType
  onDeletePromocode?: DeletePromocodeType
  isLoading?: boolean
}) => {
  dayjs.locale('ru')
  document.title = `Список промокодов | Bekker-place Admin`
  const navigate = useNavigate()
  const [modal, setModal] = useState<string | null>(null)
  const [adminPromocodes, setAdminPromocodes] = useState<IAdminPromocode[]>([])
  const [paginationParameters, setPaginationParameters] = useState<IPagination>(
    STARTUP_PAGINATION_DATA,
  )

  const data = useMemo(() => {
    if (adminPromocodes.length !== 0) {
      return adminPromocodes
    } else {
      return []
    }
  }, [adminPromocodes])

  const columns: Column<IAdminPromocode>[] = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Промокод',
        accessor: 'code',
      },
      {
        Header: 'Пользователь',
        accessor: 'userLogin',
      },
      {
        Header: 'Локация',
        accessor: 'locationName',
      },
      {
        Header: 'Дней',
        accessor: 'daysCounter',
      },
      {
        Header: 'Стоимость',
        accessor: 'discount',
      },
      {
        Header: 'Действителен до',
        accessor: 'expiredAt',
        Cell: (cellProps) => {
          return (
            <span>{dayjs(cellProps.value).format('DD MMM YYYY, HH:mm')}</span>
          )
        },
      },
      {
        Header: 'Использован',
        accessor: 'usedAt',
        Cell: (cellProps) => {
          return (
            <span>
              {cellProps.value
                ? dayjs(cellProps.value).format('DD MMM YYYY, HH:mm')
                : ''}
            </span>
          )
        },
      },
      {
        Header: 'Осталось срабатываний',
        accessor: 'usagesLeft',
        Cell: (cellProps) => {
          return (
            <span>
              {cellProps.value < 0 ? 'Многоразовый' : cellProps.value}
            </span>
          )
        },
      },
      {
        Header: 'Действия',
        accessor: 'id',
        id: 'actions',
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <>
              <button
                className={s['qr-button']}
                onClick={() => {
                  setModal(
                    `https://dev.bekker-place.ru/cabinet/balance/?promocode=${cellProps.row.original.code}`,
                  )
                }}
              >
                <QrcodeOutlined style={{ color: '#fff' }} />
              </button>
              {/* <button
                className={s.edit}
                onClick={() => {
                  navigate(`/admin/promocodes/${props.value}/edit`)
                }}
              >
                <EditOutlined style={{ color: '#fff' }} />
              </button> */}
              <button
                className={s.delete}
                onClick={() => {
                  handleDeletePromocode(cellProps.value)
                }}
              >
                <DeleteOutlined style={{ color: '#fff' }} />
              </button>
            </>
          )
        },
      },
    ],
    [],
  )

  const options: TableOptions<IAdminPromocode> = {
    data,
    columns,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setPageSize,
    setGlobalFilter,
  } = useTable(options, useGlobalFilter, useSortBy, usePagination)

  const { globalFilter } = state
  const [pageSizeLocal, setPageSizeLocal] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  const onChangeGoTo = useAsyncDebounce((value: number) => {
    setPageNumber(Number(value))
  }, 2000)

  const handleDeletePromocode = (id: number) => {
    if (props.onDeletePromocode) {
      props.onDeletePromocode(id).then(() => {
        handleGetPromocodes(pageSizeLocal, pageNumber)
      })
    }
  }

  const handleGetPromocodes = (perPage: number, page: number) => {
    if (props.onGetPromocodes) {
      props.onGetPromocodes(perPage, page).then((res) => {
        setAdminPromocodes(res.data.data)
        setPaginationParameters(res.data.pagination)
      })
    }
  }

  useEffect(() => {
    handleGetPromocodes(pageSizeLocal, pageNumber)
  }, [pageSizeLocal, pageNumber])

  return (
    <>
      <div className={s.promo}>
        <h1 className={s.title}>Список промокодов</h1>
        <div className={s.table_container}>
          <div className={s.table_header}>
            <button
              className={s['add-button']}
              onClick={() => {
                navigate(ADMIN_ADD_PROMOCODES_LINK)
              }}
            >
              <PlusOutlined /> Добавить новый
            </button>
          </div>
          <div className={s['table_options-container']}>
            <select
              className={s['table_page-size']}
              value={pageSizeLocal}
              onChange={(e) => {
                setPageSize(Number(e.target.value))
                setPageSizeLocal(Number(e.target.value))
                setPageNumber(1)
              }}
            >
              {[10, 25, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Показать {pageSize}
                </option>
              ))}
            </select>
            {/* <GlobalFilter
              filter={globalFilter}
              setFilter={setGlobalFilter}
              className={s['table_search-input']}
              containerClassName={s.table_search}
            /> */}
          </div>
          <div className={s.table_wrapper}>
            <table {...getTableProps()} className={s.table_main}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps(),
                        )}
                      >
                        {column.render('Header')}
                        {/* {column.disableSortBy ? (
                          <></>
                        ) : column.isSorted ? (
                          column.isSortedDesc ? (
                            <span className={s.table_arrow}>
                              &#8593;
                              <span className={s.table_arrow__unactive}>
                                &#8595;
                              </span>
                            </span>
                          ) : (
                            <span className={s.table_arrow}>
                              <span className={s.table_arrow__unactive}>
                                &#8593;
                              </span>
                              &#8595;
                            </span>
                          )
                        ) : (
                          <span
                            className={classNames(
                              s.table_arrow,
                              s.table_arrow__unactive,
                            )}
                          >
                            &#8593;&#8595;
                          </span>
                        )} */}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((page) => {
                  prepareRow(page)
                  return (
                    <tr {...page.getRowProps()}>
                      {page.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {!props.isLoading ? null : (
            <div className={s['table-spinner-container']}>
              <Spin indicator={antTableIcon} />
            </div>
          )}
          <div className={s.pagination}>
            <span className={s.pagination_text}>
              Страница {paginationParameters.currentPage} из{' '}
              {paginationParameters.totalPages}
            </span>
            <div className={s.pagination_goto}>
              <span className={s.pagination_text}>Перейти на страницу</span>
              <input
                type="number"
                defaultValue={1}
                max={paginationParameters.totalPages}
                min={1}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const pageValue = e.target.value ? Number(e.target.value) : 0
                  if (pageValue > paginationParameters.totalPages) {
                    onChangeGoTo(paginationParameters.totalPages)
                    return
                  } else if (pageValue < 1) {
                    onChangeGoTo(1)
                    return
                  }
                  onChangeGoTo(pageValue)
                }}
                className={s.pagination_input}
              ></input>
            </div>
            <div className={s['pagination_button-container']}>
              <button
                className={classNames(
                  s.pagination_button,
                  s.pagination_button__first,
                )}
                onClick={() => {
                  setPageNumber(1)
                }}
                disabled={pageNumber === 1}
              >
                1
              </button>
              <button
                className={classNames(
                  s.pagination_button,
                  s.pagination_button__middle,
                )}
                onClick={() => {
                  setPageNumber(pageNumber - 1)
                }}
                disabled={pageNumber === 1}
              >
                Предыдущая
              </button>
              <button
                className={classNames(
                  s.pagination_button,
                  s.pagination_button__middle,
                )}
                onClick={() => {
                  setPageNumber(pageNumber + 1)
                }}
                disabled={paginationParameters.totalPages === pageNumber}
              >
                Следующая
              </button>
              <button
                className={classNames(
                  s.pagination_button,
                  s.pagination_button__last,
                )}
                onClick={() => {
                  setPageNumber(paginationParameters.totalPages)
                }}
                disabled={paginationParameters.totalPages === pageNumber}
              >
                {paginationParameters.totalPages}
              </button>
            </div>
          </div>
        </div>
      </div>
      {modal && (
        <ModalQR
          qr={modal}
          visible={modal ? true : false}
          onCancel={() => setModal(null)}
        />
      )}
    </>
  )
}

export default AdminPromocodes
