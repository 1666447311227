import { useEffect, useState } from 'react'
import { IGuestTariff, Tariff } from '../../@types/assets'
import s from './ModalQuestion.module.scss'
import { Modal } from 'antd'

const ModalQuestion = (props: {
  visible: boolean
  onCancel: any
  alfaGuestInfo: IGuestTariff
  betaGuestInfo: IGuestTariff
}) => {
  return (
    <Modal
      title=""
      footer=""
      centered
      open={props.visible}
      onCancel={props.onCancel}
      wrapClassName={s.modal_wrap}
      width={380}
      bodyStyle={{}}
    >
      <div className={s.modal_container}>
        <div className={s.modal_header}>Альфа</div>
        <p>
          — до {props.alfaGuestInfo.guestSeatsFreeHour} гостей включительно:
          <br />
          первый час бесплатно, каждый следующий{' '}
          {props.alfaGuestInfo.guestCostHour}₽
        </p>

        <div className={s.modal_header}>Бета</div>
        <p>
          — до {props.betaGuestInfo.guestSeatsFreeHour} гостей включительно:
          <br />
          первый час бесплатно, каждый следующий{' '}
          {props.betaGuestInfo.guestCostHour}₽<br />
          — 6 гостей и больше: <br />
          начиная с первого часа {props.betaGuestInfo.guestCostHour}₽/ч
          <br />
        </p>

        <div className={s['modal_header-star']}></div>
        <p>
          Организатор встречи, приглашающий гостей, заранее должен пополнить
          баланс. Средства будут списаны с баланса организатора после выхода
          гостей.
        </p>
      </div>
    </Modal>
  )
}

export default ModalQuestion
