import s from './YandexMap.module.scss'
import Vk from './images/VK_Logo.svg'
import Tg from './images/TG_Logo.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

export default function YandexMap(props: { children: JSX.Element }) {
  return (
    <div className={s.container}>
      <div className={s.title}>
        Где мы <span className="text-accent">находимся</span>
      </div>
      <div className={s.contacts}>
        <div className={s['contacts__title']}>контакты</div>
        <div className={s['contacts__title--tiny']}>
          <span className="text-accent">Связаться</span> с нами
        </div>
        <ul className={s['contacts__list']}>
          <li className={s['contacts__item']}>
            <span className={s['contacts__item-title']}>Мы находимся</span>
            <span className={s['contacts__item-subtitle']}>
              г. Энгельс, улица Матросова, 26
            </span>
          </li>
          <li className={s['contacts__item']}>
            <span className={s['contacts__item-title']}>Для связи</span>
            <span className={s['contacts__item-subtitle']}>
              <a href="tel:+79586753137" className={s['contacts__link']}>
                +7 (958) 675 3137
              </a>
            </span>
          </li>
          <li className={s['contacts__item']}>
            <span className={s['contacts__item-title']}>Для писем</span>
            <span className={s['contacts__item-subtitle']}>
              <a
                href="mailto:info@bekker-place.ru"
                className={s['contacts__link']}
              >
                info@bekker-place.ru
              </a>
            </span>
          </li>
        </ul>
        <div className={s.socials}>
          <a
            href="https://vk.com/bekkerplace"
            title="Bekker Place Вконтакте"
            target="_blank"
            className={s.socialLink}
            rel="noreferrer"
          >
            <LazyLoadImage src={Vk} alt="" className={s.socialPic} />
          </a>
          <a
            href="https://t.me/bekkerplace"
            title="Bekker Place Telegram"
            className={s.socialLink}
            target="_blank"
            rel="noreferrer"
          >
            <LazyLoadImage src={Tg} alt="" className={s.socialPic} />
          </a>
        </div>
      </div>
      {props.children}
    </div>
  )
}
