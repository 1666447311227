import s from './Balance.module.scss'
import Section from '../Section'
import LkHeader from '../LkHeader/LkHeader'
import { Button, Spin } from 'antd'
import * as Yup from 'yup'
import classNames from 'classnames'
import { useMemo, useContext, useEffect } from 'react'
import { Column, TableOptions, useTable } from 'react-table'
import AuthContext from '../../contexts/AuthContext'
import { LoadingOutlined } from '@ant-design/icons'
import {
  CheckOrderType,
  IBalance,
  IOperationsTable,
  SendPromoType,
  UpBalanceType,
} from '../../@types/assets'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { useSearchParams } from 'react-router-dom'

const Balance = (props: {
  onSendPromo?: SendPromoType
  balanceData?: IBalance[]
  isLoading?: boolean
  onUpBalance?: UpBalanceType
  onCheckOrder?: CheckOrderType
}) => {
  dayjs.locale('ru')

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.get('order') && props.onCheckOrder) {
      props.onCheckOrder(Number(searchParams.get('order')))
    }
  }, [])

  const { authData } = useContext(AuthContext)

  const data = useMemo((): IOperationsTable[] => {
    if (props.balanceData) {
      return props.balanceData
    } else {
      return []
    }
  }, [props.balanceData])

  const columns: Column<IOperationsTable>[] = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
      },
      { Header: 'Сумма', accessor: 'amount' },
      {
        Header: 'Операция',
        accessor: 'description',
        Cell: (props) => {
          return <span>{props.value}</span>
        },
      },
      {
        Header: 'Дата',
        accessor: 'createdAt',
        Cell: (cellProps) => {
          return (
            <span>{dayjs(cellProps.value).format('DD MMM YYYY, HH:mm')}</span>
          )
        },
      },
    ],
    [],
  )

  const options: TableOptions<IOperationsTable> = {
    data,
    columns,
  }

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
        color: '#be763c',
      }}
      spin
    />
  )

  const initialValues = {
    replenishment: '',
  }

  const initialPromoValues = {
    promo: searchParams.get('promocode') || '',

    // promo: '',
  }

  const validationSchema = () => {
    return Yup.object().shape({
      replenishment: Yup.number()
        .required('Это поле обязательно!')
        .min(50, 'Минимальная сумма 50 рублей!'),
    })
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(options)
  return (
    <>
      <Section className={s['section-header']}>
        <div className="section_wrapper">
          <div className={s.balance}>
            <div className={s.container}>
              <h1 className={classNames(s.title, s.title_header)}>
                Управление балансом
              </h1>
              <div className={s.replenishment}>
                <p className={s.text}>
                  Текущий баланс: <span>{authData.user?.balance} &#8381;</span>
                </p>
                <label htmlFor="replenishment" className={s.title}>
                  Пополнить счёт
                </label>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(formValues) => {
                    if (props.onUpBalance) {
                      props.onUpBalance(Number(formValues.replenishment))
                    }
                  }}
                  enableReinitialize
                >
                  {(formik) => (
                    <Form className={s.form}>
                      <div className={s.input_container}>
                        <Field
                          name="replenishment"
                          id="replenishment"
                          className={s.input}
                          type="number"
                          placeholder="Введите сумму"
                        />
                        <Button
                          htmlType="submit"
                          className={classNames('Button', s.button)}
                        >
                          Пополнить
                        </Button>
                      </div>
                      <div className={s['alert-container']}>
                        <ErrorMessage
                          name="replenishment"
                          component="div"
                          className={s.alert}
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
                <label htmlFor="promo" className={s.title}>
                  Активировать промокод
                </label>
                <Formik
                  enableReinitialize
                  initialValues={initialPromoValues}
                  onSubmit={(formValues) => {
                    if (props.onSendPromo) {
                      props.onSendPromo(formValues.promo)
                    }
                  }}
                >
                  {(formik) => (
                    <Form className={s.form}>
                      <div className={s.input_container}>
                        <Field
                          id="promo"
                          name="promo"
                          className={s.input}
                          type="text"
                          placeholder="Введите промокод"
                        />
                        <Button
                          className={classNames('Button', s.button)}
                          htmlType="submit"
                        >
                          Применить
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <h1 className={classNames(s.title, s.title_header)}>
                Детализация баланса
              </h1>
              <div className={s.details}>
                <div className={s.table_wrapper}>
                  <table {...getTableProps()} className={s.table}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th {...column.getHeaderProps()}>
                              {column.render('Header')}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {!props.isLoading
                        ? rows.map((row) => {
                            prepareRow(row)
                            return (
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })
                        : null}
                    </tbody>
                  </table>
                </div>
                {!props.isLoading ? null : (
                  <div className={s['table-spinner-container']}>
                    <Spin indicator={antIcon} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default Balance
