import Section from '../Section'
import s from './Cabinet.module.scss'
import LkHeader from '../LkHeader/LkHeader'
import CabinetMain from '../CabinetMain/CabinetMain'
import Profile from '../ProfilePage/Profile'
import ManageGuest from '../ManageGuestPage/ManageGuest'
import {
  BuySubscriptionType,
  CheckOrderType,
  IBalance,
  ICard,
  IGuest,
  IGuestTariff,
  IInviteForm,
  ILocations,
  IPromotion,
  ISubscriptions,
  InviteGuestType,
  LogoutType,
  OpenGatesType,
  SendPromoType,
  Tariff,
  UpBalanceType,
  UpdateType,
} from '../../@types/assets'
import Balance from '../Balance/Balance'
import { getLocationSettings } from '../../api/api'
import { useEffect, useState } from 'react'

const Cabinet = (props: {
  page: string
  title: string
  onLogout: LogoutType
  subscriptions?: ISubscriptions | null
  card?: ICard | null
  locations?: ILocations | null
  onUpdate?: UpdateType
  isLoading: boolean
  tariffs?: Tariff[]
  onSendPromo?: SendPromoType
  onOpenGates?: OpenGatesType
  guests?: IGuest[]
  onInviteGuest?: InviteGuestType
  onBuySubscription?: BuySubscriptionType
  promotions?: IPromotion[]
  balanceData?: IBalance[]
  onUpBalance?: UpBalanceType
  onCheckOrder?: CheckOrderType
}) => {
  document.title = props.title

  const [alfaGuestInfo, setAlfaGuestInfo] = useState<IGuestTariff>({
    id: 0,
    locationId: 0,
    guestCostHour: 0,
    guestSeatsFreeHour: 0,
    guestSeatsMax: 0,
  })
  const [betaGuestInfo, setBetaGuestInfo] = useState<IGuestTariff>({
    id: 0,
    locationId: 0,
    guestCostHour: 0,
    guestSeatsFreeHour: 0,
    guestSeatsMax: 0,
  })

  useEffect(() => {
    getLocationSettings(1).then((res) => {
      //Альфа
      setAlfaGuestInfo(res.data)
    })
    getLocationSettings(2).then((res) => {
      // Бета
      setBetaGuestInfo(res.data)
    })
  }, [])
  return (
    <Section className={s.cabinet}>
      <LkHeader onLogout={props.onLogout} />
      {props.page === 'dashboard' && (
        <CabinetMain
          tariffs={props.tariffs}
          subscriptions={props.subscriptions}
          card={props.card}
          locations={props.locations}
          isLoading={props.isLoading}
          onSendPromo={props.onSendPromo}
          onOpenGates={props.onOpenGates}
          onBuySubscription={props.onBuySubscription}
          promotions={props.promotions}
          alfaGuestInfo={alfaGuestInfo}
          betaGuestInfo={betaGuestInfo}
        />
      )}
      {props.page === 'profile' && (
        <Profile isLoading={props.isLoading} onUpdate={props.onUpdate} />
      )}
      {props.page === 'guests' && (
        <ManageGuest
          guests={props.guests}
          isLoading={props.isLoading}
          subscriptions={props.subscriptions}
          onInviteGuest={props.onInviteGuest}
          card={props.card}
        />
      )}
      {props.page === 'balance' && (
        <Balance
          onSendPromo={props.onSendPromo}
          balanceData={props.balanceData}
          isLoading={props.isLoading}
          onUpBalance={props.onUpBalance}
          onCheckOrder={props.onCheckOrder}
        />
      )}
    </Section>
  )
}

export default Cabinet
