import { useState, useEffect } from 'react'
import s from './ModalOption.module.scss'
import classNames from 'classnames'
import { Modal, Button } from 'antd'

const ModalOption = (props: {
  visible: boolean
  onCancel: any
  setAlfa: any
  setBeta: any
}) => {
  const [maxModalSize, setMaxModalSize] = useState(0)

  useEffect(() => {
    if (window.innerWidth < 1109) setMaxModalSize(287)
    else setMaxModalSize(350)
  }, [])

  return (
    <Modal
      title=""
      footer=""
      centered
      open={props.visible}
      onCancel={props.onCancel}
      wrapClassName={s.modal_wrap}
      width={maxModalSize}
    >
      <div className={s.modal_body}>
        <Button
          className={classNames('Button', s.cabinet_button)}
          onClick={props.setAlfa}
        >
          Альфа-коворкинг
        </Button>

        <Button
          className={classNames('Button', s.cabinet_button)}
          onClick={props.setBeta}
        >
          Бета-коворкинг
        </Button>
      </div>
    </Modal>
  )
}

export default ModalOption
