import { useNavigate, useSearchParams } from 'react-router-dom'
import s from './Forms.module.scss'
import { Button } from 'antd'
import classNames from 'classnames'
import Login from '../Login/Login'
import Register from '../Register/Register'
import PasswordEmail from '../PasswordEmail/PasswordEmail'
import {
  GetUserInfoType,
  IResetPassword,
  LoginType,
  RegisterType,
  ResetType,
  SendEmailVerifyType,
  SendPasswordEmailType,
  VerifyEmailType,
} from '../../@types/assets'
import PasswordReset from '../PasswordReset/PasswordReset'
import VerifyEmail from '../VerifyEmail/VerifyEmail'
import EmailConfirmation from '../EmailConfirmation/EmailConfirmation'
import { useLayoutEffect, useState } from 'react'

const Forms = (props: {
  activeForm: string
  onRegister?: RegisterType
  onLogin?: LoginType
  loginLoading?: boolean
  onSendPasswordEmail?: SendPasswordEmailType
  onResetPassword?: ResetType
  onVerifyEmail?: VerifyEmailType
  onLogout?: () => void
  onGetUserInfo?: GetUserInfoType
  onSendEmailVerify?: SendEmailVerifyType
  isLoading?: boolean
}) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [token, setToken] = useState<string | null>(null)

  useLayoutEffect(() => {
    setToken(searchParams.get('token'))
  }, [])

  return (
    <div className={s.forms_wrapper}>
      <div className={classNames('d-flex flex-column', s.forms_section)}>
        <h1 className={s.section_header}>
          Добро пожаловать в коворкинг
          <br />
          <span
            className={classNames(s.section_header, s['section_header-name'])}
          >
            BEKKER PLACE
          </span>
        </h1>
        <div className={s.section_form}>
          {(props.activeForm === 'login' ||
            props.activeForm === 'register') && (
            <div className={s['section_button-group']}>
              <Button
                className={classNames(
                  'Button',
                  s.section_button,
                  props.activeForm === 'login' ? s.active : '',
                )}
                onClick={() => navigate('/auth/login')}
              >
                Вход
              </Button>
              <Button
                className={classNames(
                  'Button',
                  s.section_button,
                  props.activeForm === 'register' ? s.active : '',
                )}
                onClick={() => navigate('/auth/register')}
              >
                Регистрация
              </Button>
            </div>
          )}
          {props.activeForm === 'login' && (
            <Login loginLoading={props.loginLoading} onLogin={props.onLogin} />
          )}
          {props.activeForm === 'register' && (
            <Register onRegister={props.onRegister} />
          )}
          {props.activeForm === 'passwordSendEmail' &&
            (token ? (
              <PasswordReset
                onResetPassword={props.onResetPassword}
                token={token}
              />
            ) : (
              <PasswordEmail onSendPasswordEmail={props.onSendPasswordEmail} />
            ))}
          {/* {props.activeForm === 'passwordReset' && (
            <PasswordReset onResetPassword={props.onResetPassword} />
          )} */}
          {props.activeForm === 'verifyEmail' && (
            <VerifyEmail
              onLogout={props.onLogout}
              onSendEmailVerify={props.onSendEmailVerify}
              isLoading={props.isLoading}
            />
          )}
          {props.activeForm === 'emailConfirmation' && (
            <EmailConfirmation
              onVerifyEmail={props.onVerifyEmail}
              onGetUserInfo={props.onGetUserInfo}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Forms
