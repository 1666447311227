import { CloseOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import s from './TopMenu.module.scss'
import Logo from '../svg/logo'
import Vk from '../../images/VK_Logo.svg'
import Tg from '../../images/TG_Logo.svg'
import { TERMS_LINK } from '../../constants'
import PhoneSVG from './images/PhoneSVG'
import PhoneWhiteSVG from './images/PhoneSVG copy'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useClickOutside } from '../../hooks/useClickOutside'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import { IPromotion, SetActiveTopMenuType } from '../../@types/assets'

export default function TopMenu(props: {
  visible: boolean
  setActiveTopMenu: SetActiveTopMenuType
  landingPromotions: IPromotion[]
}) {
  let domNode = useClickOutside(() => {
    props.setActiveTopMenu(false)
  })

  return (
    <div
      className={classNames(
        s.topMenu,
        !props.visible ? s.topMenuInvisible : '',
      )}
    >
      <div ref={domNode} className={s.menuContent}>
        <CloseOutlined
          className={s.closeIcon}
          onClick={() => {
            props.setActiveTopMenu(false)
          }}
        />
        <Logo className={s.logo} />
        <ul className={s.menu}>
          {props.landingPromotions ? (
            <li
              className={s['menu-item']}
              onClick={() => {
                props.setActiveTopMenu(false)
              }}
            >
              <a className={s['menu-link']} href="/#promos">
                Акции
              </a>
            </li>
          ) : null}
          <li
            className={s['menu-item']}
            onClick={() => {
              props.setActiveTopMenu(false)
            }}
          >
            <HashLink className={s['menu-link']} to="/#benefits">
              Что включено
            </HashLink>
          </li>
          <li
            className={s['menu-item']}
            onClick={() => {
              props.setActiveTopMenu(false)
            }}
          >
            <HashLink className={s['menu-link']} to="/#prices">
              Цены
            </HashLink>
          </li>
          <li
            className={s['menu-item']}
            onClick={() => {
              props.setActiveTopMenu(false)
            }}
          >
            <HashLink className={s['menu-link']} to="/#how-use">
              Как пользоваться?
            </HashLink>
          </li>
          <li
            className={s['menu-item']}
            onClick={() => {
              props.setActiveTopMenu(false)
            }}
          >
            <HashLink className={s['menu-link']} to="/#gallery-slider">
              Фото
            </HashLink>
          </li>
          <li
            className={s['menu-item']}
            onClick={() => {
              props.setActiveTopMenu(false)
            }}
          >
            <HashLink className={s['menu-link']} to="/#contacts">
              Контакты
            </HashLink>
          </li>
        </ul>
        <a
          href={TERMS_LINK}
          className={s.policy}
          target="_blank"
          rel="noreferrer"
        >
          Политика конфиденциальности
        </a>
        <a
          className={classNames(s['contact-link'], s.telephone)}
          href="tel:+78452496987"
        >
          <PhoneSVG className={s.phoneIcon} />
          <PhoneWhiteSVG className={s.phoneWhiteIcon} /> +7 (8452) 49 69 87
        </a>
        <div className={s.socials}>
          <a
            href="https://vk.com/bekkerplace"
            title="Bekker Place Вконтакте"
            className={s.socialLink}
            target="_blank"
            rel="noreferrer"
          >
            <LazyLoadImage src={Vk} alt="" className={s.socialPic} />
          </a>
          <a
            href="https://t.me/bekkerplace"
            title="Bekker Place Telegram"
            className={s.socialLink}
            target="_blank"
            rel="noreferrer"
          >
            <LazyLoadImage src={Tg} alt="" className={s.socialPic} />
          </a>
        </div>
      </div>
    </div>
  )
}
