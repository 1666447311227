import { MutableRefObject, useEffect, useRef } from 'react'
export let useClickOutside = (
  handler: () => void,
): MutableRefObject<HTMLDivElement | null> => {
  let domNode = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    let maybeHandler = (event: MouseEvent) => {
      if (!domNode.current?.contains(event.target as Node)) {
        handler()
      }
    }
    document.addEventListener('mousedown', maybeHandler)
    return () => {
      document.removeEventListener('mousedown', maybeHandler)
    }
  }, [])

  return domNode
}
