import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { DASHBOARD_LINK } from '../../constants'
import { Cookies } from 'react-cookie'

type ProtectedRouteProps = {
  isAdmin?: boolean
}

const AdminProtectedRoute = ({ isAdmin }: ProtectedRouteProps) => {
  const cookie = new Cookies()
  const location = useLocation()
  return cookie.get('isAdmin') ? (
    <Outlet />
  ) : (
    <Navigate to={DASHBOARD_LINK} replace state={{ path: location.pathname }} />
  )
}

export default AdminProtectedRoute
