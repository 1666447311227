import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { BANNED_LINK } from '../../constants'

type ProtectedRouteProps = {
  isBanned?: boolean
}

const BannedProtectedRoute = ({ isBanned }: ProtectedRouteProps) => {
  const location = useLocation()
  return !isBanned ? (
    <Outlet />
  ) : (
    <Navigate to={BANNED_LINK} replace state={{ path: location.pathname }} />
  )
}

export default BannedProtectedRoute
