import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import arrow from './images/arrow_slider.svg'

import { Navigation, Pagination, Autoplay } from 'swiper'

import s from './GallerySlider.module.scss'

import Section from '../Section'
import classNames from 'classnames'
import { useState } from 'react'
import { Button } from 'antd'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'

import alfaSlide_1 from '../../images/alfa_gallery_1.jpg'
import alfaSlide_comp_1 from '../../images/compressed/alfa_gallery_1_comp.jpg'
import alfaSlide_2 from '../../images/alfa_gallery_2.jpg'
import alfaSlide_comp_2 from '../../images/compressed/alfa_gallery_2_comp.jpg'
import alfaSlide_3 from '../../images/velo-parking.jpg'
import alfaSlide_comp_3 from '../../images/compressed/velo-parking_comp.jpg'
import alfaSlide_4 from '../../images/car.jpg'
import alfaSlide_comp_4 from '../../images/compressed/car_comp.jpg'
import alfaSlide_5 from '../../images/parking.jpg'
import alfaSlide_comp_5 from '../../images/compressed/parking_comp.jpg'
import alfaSlide_6 from '../../images/outside-work.jpg'
import alfaSlide_comp_6 from '../../images/compressed/outside-work_comp.jpg'
import alfaSlide_7 from '../../images/alfa_gallery_7.jpg'
import alfaSlide_comp_7 from '../../images/compressed/alfa_gallery_7_comp.jpg'
import alfaSlide_8 from '../../images/alfa_gallery_8.jpg'
import alfaSlide_comp_8 from '../../images/compressed/alfa_gallery_8_comp.jpg'
import alfaSlide_9 from '../../images/alfa_gallery_9.jpg'
import alfaSlide_comp_9 from '../../images/compressed/alfa_gallery_9_comp.jpg'
import alfaSlide_10 from '../../images/alfa_gallery_10.jpg'
import alfaSlide_comp_10 from '../../images/compressed/alfa_gallery_10_comp.jpg'
import alfaSlide_11 from '../../images/alfa_gallery_11.jpg'
import alfaSlide_comp_11 from '../../images/compressed/alfa_gallery_11_comp.jpg'
import alfaSlide_12 from '../../images/alfa_gallery_12.jpg'
import alfaSlide_comp_12 from '../../images/compressed/alfa_gallery_12_comp.jpg'
import alfaSlide_13 from '../../images/alfa_gallery_13.jpg'
import alfaSlide_comp_13 from '../../images/compressed/alfa_gallery_13_comp.jpg'
import alfaSlide_14 from '../../images/alfa_gallery_14.jpg'
import alfaSlide_comp_14 from '../../images/compressed/alfa_gallery_14_comp.jpg'

interface SlideT {
  pic: string
  picComp: string
  title: string
}
const alfa_slides: SlideT[] = [
  { pic: alfaSlide_1, picComp: alfaSlide_comp_1, title: 'Open space' },
  {
    pic: alfaSlide_2,
    picComp: alfaSlide_comp_2,
    title: 'Администратор на ресепшн',
  },
  { pic: alfaSlide_3, picComp: alfaSlide_comp_3, title: 'Велопарковка' },
  {
    pic: alfaSlide_4,
    picComp: alfaSlide_comp_4,
    title:
      'Въезд в коворкинг (ворота открываются автоматически из вашего личного кабинета)',
  },
  {
    pic: alfaSlide_5,
    picComp: alfaSlide_comp_5,
    title: 'Закрытая парковка на 18 машин',
  },
  {
    pic: alfaSlide_6,
    picComp: alfaSlide_comp_6,
    title: 'Зона для работы на улице между коворкингами',
  },
  { pic: alfaSlide_7, picComp: alfaSlide_comp_7, title: 'Зона отдыха' },
  {
    pic: alfaSlide_8,
    picComp: alfaSlide_comp_8,
    title: 'Зона отдыха с телевизором',
  },
  { pic: alfaSlide_9, picComp: alfaSlide_comp_9, title: 'Кухня' },
  {
    pic: alfaSlide_10,
    picComp: alfaSlide_comp_10,
    title: 'Локеры для хранения личных вещей',
  },
  {
    pic: alfaSlide_11,
    picComp: alfaSlide_comp_11,
    title: 'Одна из zoom-комнат',
  },
  {
    pic: alfaSlide_12,
    picComp: alfaSlide_comp_12,
    title: 'Основной зал с закрытыми переговорными и open space',
  },
  {
    pic: alfaSlide_13,
    picComp: alfaSlide_comp_13,
    title: 'Переговорная с магнитной доской',
  },
  {
    pic: alfaSlide_14,
    picComp: alfaSlide_comp_14,
    title: 'Ресепшн с принтером и сканером',
  },
]

export default function GallerySlider() {
  const [activeGallery, setActiveGallery] = useState<'alfa' | 'beta'>('alfa')
  return (
    <Section id="gallery-slider" className={s['gallery']}>
      <h2 className={s['gallery__title']}>
        Интерьер <span className="text-accent">коворкинга</span>
      </h2>
      {/* <div className={s['gallery-switcher']}>
        <Button
          className={classNames(
            'Button',
            s['gallery-switcher__button'],
            activeGallery === 'alfa'
              ? s['gallery-switcher__button--active']
              : '',
          )}
          onClick={() => {
            setActiveGallery('alfa')
          }}
        >
          Альфа-коворкинг
        </Button>
      </div> */}
      <div
        className={classNames(
          s.slider,
          activeGallery === 'alfa' ? s['slider--active'] : '',
        )}
      >
        <Swiper
          slidesPerView={'auto'}
          spaceBetween={30}
          slidesPerGroup={1}
          loop={true}
          centeredSlides={true}
          autoplay={{ delay: 4000 }}
          navigation={{
            prevEl: '.prev',
            nextEl: '.next',
          }}
          pagination={true}
          modules={[Navigation, Pagination, Autoplay]}
          className="mySwiper"
        >
          {alfa_slides.map((slide) => (
            <SwiperSlide key={`${slide.pic}`}>
              <LazyLoadImage
                threshold={1000}
                effect="blur"
                src={slide.pic}
                placeholderSrc={slide.picComp}
                alt=""
              />
              <div className={s.slideTitle}>{slide.title}</div>
            </SwiperSlide>
          ))}
          <div className="prev">
            <img src={arrow} alt="" />
          </div>
          <div className="next">
            <img src={arrow} alt="" />
          </div>
        </Swiper>
      </div>
    </Section>
  )
}
