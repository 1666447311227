import { useNavigate, useParams } from 'react-router-dom'
import s from './AdminLocationSettings.module.scss'
import {
  GetLocationSettingsType,
  IAdminLocationSettingsForEdit,
  UpdateLocationSettingsType,
} from '../../@types/assets'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import classNames from 'classnames'
import { ADMIN_SUBSCRIPTIONS_LINK } from '../../constants'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import InputSkeleton from '../InputSkeleton/InputSkeleton'

const AdminLocationSettings = (props: {
  isLoading?: boolean
  onUpdateLocationSettings?: UpdateLocationSettingsType
  onGetLocationSettings?: GetLocationSettingsType
}) => {
  const { locationId } = useParams()

  const [editedLocation, setEditedLocation] =
    useState<IAdminLocationSettingsForEdit | null>(null)

  useEffect(() => {
    if (locationId && props.onGetLocationSettings) {
      props.onGetLocationSettings(Number(locationId)).then((res) => {
        setEditedLocation(res.data)
      })
    }
  }, [])

  document.title = `Редактирование настроек локации ${locationId} | Bekker-place Admin`
  const navigate = useNavigate()

  const initialValues: IAdminLocationSettingsForEdit = {
    guestCostHour: editedLocation?.guestCostHour
      ? editedLocation?.guestCostHour
      : 0,
    guestSeatsFreeHour: editedLocation?.guestSeatsFreeHour
      ? editedLocation?.guestSeatsFreeHour
      : 0,
    guestSeatsMax: editedLocation?.guestSeatsMax
      ? editedLocation?.guestSeatsMax
      : 0,
  }
  const validationSchema = () => {
    return Yup.object().shape({
      guestCostHour: Yup.number()
        .required('Это поле обязательно')
        .min(0, 'Введите положительное число'),
      guestSeatsFreeHour: Yup.number()
        .required('Это поле обязательно')
        .min(0, 'Введите положительное число'),
      guestSeatsMax: Yup.number()
        .required('Это поле обязательно')
        .min(0, 'Введите положительное число'),
    })
  }
  const handleSubmit = (formValues: IAdminLocationSettingsForEdit) => {
    if (props.onUpdateLocationSettings) {
      props
        .onUpdateLocationSettings(Number(locationId), formValues)
        .then(() => {
          navigate(ADMIN_SUBSCRIPTIONS_LINK)
        })
    }
  }
  return (
    <div className={s.settings}>
      <h1 className={s.title}>
        Редактирование настроек локации {locationId === '3' ? 'Альфа' : 'Бета'}{' '}
        Коворкинг
      </h1>
      <div className={s.form_container}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {(formik) => (
            <Form className={s.form}>
              <div className={s.inputs_container}>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="guestCostHour">
                      Стоимость часа для гостя
                    </label>
                    {props.isLoading ? (
                      <InputSkeleton className={s.input_skeleton} />
                    ) : (
                      <Field
                        name="guestCostHour"
                        id="guestCostHour"
                        autoComplete="off"
                        type="number"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.guestCostHour
                            ? s.form_control__error
                            : '',
                        )}
                      ></Field>
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="guestCostHour"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="guestSeatsFreeHour">
                      Кол-во гостей для бесплатного часа
                    </label>
                    {props.isLoading ? (
                      <InputSkeleton className={s.input_skeleton} />
                    ) : (
                      <Field
                        autoComplete="off"
                        id="guestSeatsFreeHour"
                        name="guestSeatsFreeHour"
                        type="number"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.guestSeatsFreeHour
                            ? s.form_control__error
                            : '',
                        )}
                      />
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="guestSeatsFreeHour"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="guestSeatsMax">
                      Максимальное кол-во гостей
                    </label>
                    {props.isLoading ? (
                      <InputSkeleton className={s.input_skeleton} />
                    ) : (
                      <Field
                        autoComplete="off"
                        id="guestSeatsMax"
                        name="guestSeatsMax"
                        type="number"
                        className={classNames(
                          s.form_control,
                          'form-control',
                          formik.errors.guestSeatsMax
                            ? s.form_control__error
                            : '',
                        )}
                      />
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="guestSeatsMax"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
              </div>
              <div className={s.buttons_container}>
                <button type="submit" className={s.button_save}>
                  Сохранить
                </button>
                <button
                  className={s.button_cancel}
                  onClick={() => {
                    navigate(ADMIN_SUBSCRIPTIONS_LINK)
                  }}
                >
                  Отмена
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AdminLocationSettings
