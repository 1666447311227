export const BASE_URL = process.env.REACT_APP_BASE_URL
export const LOGIN_LINK = '/auth/login'
export const REGISTER_LINK = '/auth/register'
export const PASSWORD_SEND_EMAIL_LINK = '/auth/password/reset'
export const PASSWORD_RESET_LINK = '/auth/password/reset/:token'
export const EMAIL_VERIFY_LINK = '/auth/email/verify'
export const HOME_LINK = '/'
export const GUESTS_LINK = '/cabinet/guests'
export const PROFILE_LINK = '/cabinet/profile'
export const DASHBOARD_LINK = '/cabinet/dashboard'
export const BALANCE_LINK = '/cabinet/balance'
export const ADMIN_LINK = '/admin'
export const ADMIN_USERS_LINK = '/admin/users'
export const ADMIN_SUBSCRIPTIONS_LINK = '/admin/subscriptions'
export const ADMIN_PROMOCODES_LINK = '/admin/promocodes'
export const ADMIN_PROMOTIONS_LINK = '/admin/promotions'
export const ADMIN_LOGS_LINK = '/admin/logs'
export const ADMIN_ADD_PROMOCODES_LINK = '/admin/promocodes/create'
export const ADMIN_ADD_PROMOTION_LINK = '/admin/promotions/create'
export const ADMIN_ANALYTICS = '/admin/analytics'
export const BANNED_LINK = '/banned'

export const CONFIDENTIAL_LINK = process.env.REACT_APP_CONFIDENTIAL_LINK
export const TERMS_LINK = process.env.REACT_APP_TERMS_LINK
export const PHONE_REG_EXP = /\+7\(\d{3}\)\s\d{3}-\d{4}/
export const PHONE_MASK = [
  '+',
  '7',
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const STARTUP_USER_DATA = {
  balance: 0,
  email: '',
  id: 0,
  login: '',
  name: '',
  phone: '',
  sex: 0,
  surname: '',
  createdAt: '',
  isAdmin: 0,
  banned: false,
  entranceAccess: false,
  emailVerifiedAt: '',
}

export const STARTUP_PAGINATION_DATA = {
  count: 0,
  currentPage: 0,
  links: [],
  perPage: 0,
  total: 0,
  totalPages: 0,
}
