import { useNavigate } from 'react-router-dom'
import s from './AdminEditPromocode.module.scss'
import { IEditPromocode } from '../../@types/assets'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import classNames from 'classnames'
import { ADMIN_PROMOCODES_LINK } from '../../constants'
import { Select } from 'antd'
import * as Yup from 'yup'

const AdminEditPromocode = (props: { isLoading?: boolean }) => {
  document.title = `Редактирование промокода | Bekker-place Admin`
  const navigate = useNavigate()
  const filterOption = (
    input: string,
    option?: { label: string; value: number },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const initialValues: IEditPromocode = {
    promocode: 'YA8Y-73YM',
    cost: 0,
    duration: 10,
    numTriggers: 1,
    subscription: 0,
    user: 0,
  }

  const validationSchema = () => {
    return Yup.object().shape({
      promocode: Yup.string().required('Это поле обязательно'),
      cost: Yup.number()
        .required('Это поле обязательно')
        .min(0, 'Введите положительное число'),
      duration: Yup.number().required('Это поле обязательно'),
      numTriggers: Yup.number()
        .required('Это поле обязательно')
        .min(-1, 'Введите верное значение'),
      subscription: Yup.number()
        .required('Это поле обязательно')
        .moreThan(0, 'Выберите подписку'),
      user: Yup.number()
        .required('Это поле обязательно')
        .moreThan(0, 'Выберите пользователя'),
    })
  }

  const handleSubmit = (formValues: IEditPromocode) => {
    console.log(formValues)
  }
  return (
    <div className={s.settings}>
      <h1 className={s.title}>Редактирование промокода</h1>
      <div className={s.form_container}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form className={s.form}>
              <div className={s.inputs_container}>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="promocode">Промокод</label>
                    <Field
                      name="promocode"
                      id="promocode"
                      className={classNames(
                        s.form_control,
                        'form-control',
                        s['form_control__select'],
                        s.form_control__select,
                        formik.errors.promocode ? s.form_control__error : '',
                      )}
                    />
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="promocode"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="numTriggers">
                      Кол-во срабатываний (если нужен многоразовый по дате,
                      поставить -1)
                    </label>
                    <Field
                      autoComplete="off"
                      id="numTriggers"
                      name="numTriggers"
                      type="number"
                      className={classNames(
                        s.form_control,
                        'form-control',
                        formik.errors.numTriggers ? s.form_control__error : '',
                      )}
                    />
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="numTriggers"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="cost">Стоимость промокода</label>

                    <Field
                      autoComplete="off"
                      id="cost"
                      name="cost"
                      type="number"
                      className={classNames(
                        s.form_control,
                        'form-control',
                        formik.errors.cost ? s.form_control__error : '',
                      )}
                    />
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="cost"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="duration">Кол-во дней подписки</label>
                    <Field
                      autoComplete="off"
                      id="duration"
                      name="duration"
                      type="number"
                      className={classNames(
                        s.form_control,
                        'form-control',
                        formik.errors.duration ? s.form_control__error : '',
                      )}
                    />
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="duration"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="subscription">
                      Дни добавятся на подписку
                    </label>
                    <Field name="subscription">
                      {({
                        field,
                      }: {
                        field: {
                          name: string
                        }
                      }) => (
                        <Select
                          {...field}
                          id="subscription"
                          className={classNames(
                            s.form_control,
                            'form-control',
                            s.form_control__select,
                            formik.errors.subscription
                              ? s.form_control__error
                              : '',
                          )}
                          onChange={(value) => {
                            formik.setFieldValue('subscription', value)
                          }}
                          options={[
                            { value: 1, label: 'Альфа Коворкинг' },
                            { value: 2, label: 'Бета Коворкинг' },
                            { value: 0, label: 'Не выбрано' },
                          ]}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="subscription"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="user">Для пользователя</label>
                    <Field name="user">
                      {({
                        field,
                      }: {
                        field: {
                          name: string
                        }
                      }) => (
                        <Select
                          {...field}
                          showSearch
                          id="user"
                          filterOption={filterOption}
                          className={classNames(
                            s.form_control,
                            'form-control',
                            s.form_control__select,
                            formik.errors.user ? s.form_control__error : '',
                          )}
                          onChange={(value) => {
                            formik.setFieldValue('user', value)
                          }}
                          options={[
                            {
                              value: 1,
                              label:
                                'shchegolef (+79217297083, efim-5654929@mail.ru)',
                            },
                            {
                              value: 2,
                              label: 'Egoк (+7999999998, mail@mail.ru)',
                            },
                            { value: 0, label: 'Не выбрано' },
                          ]}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="user"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
              </div>
              <div className={s.buttons_container}>
                <button type="submit" className={s.button_save}>
                  Сохранить
                </button>
                <button
                  className={s.button_cancel}
                  onClick={() => {
                    navigate(ADMIN_PROMOCODES_LINK)
                  }}
                >
                  Отмена
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AdminEditPromocode
