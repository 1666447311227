import Section from '../Section'
import s from './Header.module.scss'
import { UserOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import classNames from 'classnames'
import { DASHBOARD_LINK, LOGIN_LINK } from '../../constants'
import CurrentUserContext from '../../contexts/AuthContext'
import Logo from '../svg/logo'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { IPromotion } from '../../@types/assets'

export default function Header(props: { landingPromotions: IPromotion[] }) {
  const { authData } = useContext(CurrentUserContext)
  return (
    <Section className={s['section-header']}>
      <div className="section_wrapper">
        <header className={s.header}>
          <Link to="/">
            <Logo className={s.logo} />
          </Link>
          <nav>
            <ul className={s.header__menu}>
              {props.landingPromotions ? (
                <li className={s.header__item}>
                  <a className={s['header__item-link']} href="#promos">
                    Акции
                  </a>
                </li>
              ) : null}
              <li className={s.header__item}>
                <a className={s['header__item-link']} href="#benefits">
                  Что включено
                </a>
              </li>
              <li className={s.header__item}>
                <a className={s['header__item-link']} href="#prices">
                  Цены
                </a>
              </li>
              <li className={s.header__item}>
                <a className={s['header__item-link']} href="#how-use">
                  Как пользоваться?
                </a>
              </li>
              <li className={s.header__item}>
                <a className={s['header__item-link']} href="#gallery-slider">
                  Фото
                </a>
              </li>
              <li className={s.header__item}>
                <a className={s['header__item-link']} href="#contacts">
                  Контакты
                </a>
              </li>
            </ul>
          </nav>
          <Link to={authData.isLoggedIn ? DASHBOARD_LINK : LOGIN_LINK}>
            <Button className={classNames('Button', s.headerButton)}>
              <UserOutlined />
              Вход
            </Button>
          </Link>
        </header>
      </div>
    </Section>
  )
}
