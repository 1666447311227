import React from 'react'
import s from './LoginPage.module.scss'
import Section from '../Section'
import Banner from '../Banner/Banner'
import Forms from '../Forms/Forms'
import TabletHeader from '../TabletHeader'
import TopMenu from '../TopMenu'
import {
  GetUserInfoType,
  IPromotion,
  IResetPassword,
  LoginType,
  LogoutType,
  RegisterType,
  ResetType,
  SendEmailVerifyType,
  SendPasswordEmailType,
  SetActiveTopMenuType,
  VerifyEmailType,
} from '../../@types/assets'

const LoginPage = (props: {
  setActiveTopMenu: SetActiveTopMenuType
  onRegister?: RegisterType
  onLogin?: LoginType
  activeTopMenu: boolean
  activeForm: string
  title: string
  loginLoading?: boolean
  landingPromotions: IPromotion[]
  onSendPasswordEmail?: SendPasswordEmailType
  onResetPassword?: ResetType
  onVerifyEmail?: VerifyEmailType
  onLogout?: LogoutType
  onGetUserInfo?: GetUserInfoType
  onSendEmailVerify?: SendEmailVerifyType
  isLoading?: boolean
}) => {
  document.title = props.title
  return (
    <Section className={s.main}>
      <div className={s.section_wrapper}>
        <TabletHeader
          location={'login'}
          setActiveTopMenu={props.setActiveTopMenu}
        />
        <Banner />
        <Forms
          loginLoading={props.loginLoading}
          activeForm={props.activeForm}
          onRegister={props.onRegister}
          onLogin={props.onLogin}
          onSendPasswordEmail={props.onSendPasswordEmail}
          onResetPassword={props.onResetPassword}
          onVerifyEmail={props.onVerifyEmail}
          onLogout={props.onLogout}
          onGetUserInfo={props.onGetUserInfo}
          onSendEmailVerify={props.onSendEmailVerify}
          isLoading={props.isLoading}
        />
        <TopMenu
          landingPromotions={props.landingPromotions}
          visible={props.activeTopMenu}
          setActiveTopMenu={props.setActiveTopMenu}
        />
      </div>
    </Section>
  )
}

export default LoginPage
