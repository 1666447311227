export default function CallIcon(props: { className: string }) {
    return (
        <svg
            width="47"
            height="46"
            viewBox="0 0 47 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <ellipse cx="24.5" cy="24" rx="14.5" ry="16" fill="#BE763C" />
            <path
                d="M22.772 0C10.1936 0 0 10.1936 0 22.772C0 35.3504 10.1936 45.544 22.772 45.544C35.3504 45.544 45.544 35.3504 45.544 22.772C45.544 10.1936 35.3504 0 22.772 0ZM26.1854 33.8073C16.3191 28.9445 13.7473 20.2002 13.3732 17.0205C12.9991 13.8411 18.5169 11.0353 18.5169 11.0353L21.9773 16.9269C21.9773 16.9269 18.2364 18.9374 18.7507 20.0132C21.5095 25.8115 25.0635 27.9622 25.0635 27.9622C26.2325 28.383 28.8043 25.5307 28.8043 25.5307L33.7608 30.2066C33.8071 30.2066 29.6455 35.4906 26.1851 33.8072L26.1854 33.8073Z"
                fill="white"
            />
            <path
                d="M44.9759 42.4265L28.7487 37.8288C27.9903 37.6139 27.7671 36.647 28.3545 36.1214L38.6317 26.926C39.1407 26.4706 39.9462 26.6481 40.2167 27.2751L46.1667 41.0683C46.5004 41.8419 45.7865 42.6562 44.9759 42.4265Z"
                fill="white"
            />
        </svg>
    );
}
