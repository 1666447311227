import Section from '../Section'
import cashIcon from './images/CashIcon.svg'
import acceptIcon from './images/Accept.svg'
import arrowsIcon from './images/Arrows.svg'
import groupIcon from './images/Group.svg'
import portfolioIcon from './images/Portfolio.svg'
import rocketIcon from './images/Rocket.svg'
import s from './Coworking.module.scss'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { useMemo } from 'react'

export default function Coworking() {
  const content = useMemo(
    () => [
      {
        icon: cashIcon,
        title: 'Выгода',
        text_content:
          'Снимая рабочее место в коворкинге, вы экономите на оплате электричества, коммунальных услугах, канцелярии, оргтехнике, уборке, кофе и др.',
      },
      {
        icon: rocketIcon,
        title: 'Продуктивность',
        text_content:
          'Энергетическая обстановка заряженных на победу людей, не даст вам бездельничать. Здесь не возникнет желание поваляться на диване с сериалом вместо работы',
      },
      {
        icon: groupIcon,
        title: 'Знакомства и нетворкинг',
        text_content:
          'Коворкинг - это ваше новое окружение, в которое входят мотивированные и творческие люди. Здесь вы найдете и коллег по цеху, и друзей в новых отраслях, и клиентов',
      },
      {
        icon: portfolioIcon,
        title: 'Никакого визуального шума',
        text_content:
          'Стильные интерьеры наших коворкингов помогают максимально настроиться на рабочий лад. Ничего не будет отвлекать вас от работы',
      },
      {
        icon: arrowsIcon,
        title: 'Все включено',
        text_content:
          'Здесь вы платите только за вход. Для вас в неограниченном количестве кофе, чай, снеки, фрукты, интернет, тв, парковка, переговорные и zoom комнаты',
      },
      {
        icon: acceptIcon,
        title: 'Несгораемые дни',
        text_content:
          'Вы сами решаете, когда вам удобно посещать коворкинг. Дни не сгорают, если вы не посещаете коворкинг.',
      },
    ],
    [],
  )
  return (
    <Section className={s.benefit}>
      <div className={'section_wrapper'}>
        <h2 className={s['benefit-section__title']}>
          Коворкинг — <span className="text-accent">это выгодно</span>
        </h2>
        <div className={s.benefits}>
          {content.map(({ icon, title, text_content }) => {
            return (
              <div className={s.benefit__item} key={`${title}`}>
                <div className={s['benefit__title-wrapper']}>
                  <img src={icon} alt="" />
                  <h3 className={s['benefit__title']}>{title}</h3>
                </div>
                <p className={s['benefit__description']}>{text_content}</p>
              </div>
            )
          })}
        </div>
      </div>
    </Section>
  )
}
