import { Navigate, useLocation, Outlet } from 'react-router-dom'
import { BANNED_LINK, EMAIL_VERIFY_LINK } from '../../constants'

type ProtectedRouteProps = {
  isVerified?: boolean
}

const UnverifiedProtectedRoute = ({ isVerified }: ProtectedRouteProps) => {
  const location = useLocation()

  return isVerified ? (
    <Outlet />
  ) : (
    <Navigate
      to={EMAIL_VERIFY_LINK}
      replace
      state={{ path: location.pathname }}
    />
  )
}

export default UnverifiedProtectedRoute
