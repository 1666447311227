import s from './ModalQR.module.scss'
import { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { QRCodeSVG } from 'qrcode.react'
import { useLocation } from 'react-router-dom'

const ModalQR = (props: { qr: string; visible: boolean; onCancel: any }) => {
  const [maxModalSize, setMaxModalSize] = useState<number>(0)
  const [padding, setPadding] = useState<string>('')
  const location = useLocation()

  useEffect(() => {
    if (window.innerWidth < 766) {
      setMaxModalSize(320)
      setPadding('38px 15px 24px')
    } else {
      setMaxModalSize(448)
      setPadding('38px 50px 31px')
    }
  }, [])

  return (
    <Modal
      title=""
      footer=""
      centered
      open={props.visible}
      onCancel={props.onCancel}
      wrapClassName={s.modal_wrap}
      width={maxModalSize}
      bodyStyle={{ padding }}
    >
      <div className={s.modal_header}>
        {location.pathname === '/admin/promocodes'
          ? 'Промокод'
          : `Гостевой QR-код для входа`}
      </div>
      <div className={s['qr-container']}>
        {' '}
        <QRCodeSVG value={props.qr} className={s.modal_body} />
      </div>
    </Modal>
  )
}

export default ModalQR
