import { useEffect, useState } from 'react'
import s from './AdminEditUser.module.scss'
import {
  GetUserType,
  IAdminUserForEdit,
  IEditUser,
  UpdateUserType,
  openNotificationType,
} from '../../@types/assets'
import * as Yup from 'yup'
import { ADMIN_USERS_LINK, PHONE_MASK, PHONE_REG_EXP } from '../../constants'
import { ErrorMessage, Field, Formik, Form } from 'formik'
import { useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import InputSkeleton from '../InputSkeleton/InputSkeleton'
import MaskedInput from 'react-text-mask'
import { Select } from 'antd'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import relativeTime from 'dayjs/plugin/relativeTime'
import { AxiosError } from 'axios'

const AdminEditUser = (props: {
  isLoading?: boolean
  onUpdateUser?: UpdateUserType
  onGetUser?: GetUserType
  openNotification?: openNotificationType
}) => {
  const { userId } = useParams()

  useEffect(() => {
    if (userId && props.onGetUser) {
      props.onGetUser(Number(userId)).then(([userRes, subscriptionsRes]) => {
        setEditedUser({
          ...userRes.data,
          subscriptions: subscriptionsRes.data.data,
        })
      })
    }
  }, [])

  const navigate = useNavigate()
  document.title = `Редактирование пользователя | Bekker-place Admin`

  const [editedUser, setEditedUser] = useState<IAdminUserForEdit | null>(null)
  // const [loginError, setLoginError] = useState<string | null>(null)
  const [errors, setErrors] = useState<{
    email: string[] | null
    phone: string[] | null
    login: string[] | null
  }>({ email: null, login: null, phone: null })
  dayjs.extend(relativeTime)
  dayjs.locale('ru')

  const initialValues: IEditUser = {
    phone: `${editedUser?.phone.slice(0, 2)}(${editedUser?.phone.slice(
      2,
      5,
    )}) ${editedUser?.phone.slice(5, 8)}-${editedUser?.phone.slice(8, 12)}`,
    email: editedUser?.email ? editedUser.email : '',
    login: editedUser?.login ? editedUser.login : '',
    firstname: editedUser?.name ? editedUser.name : '',
    lastname: editedUser?.surname ? editedUser.surname : '',
    gender: editedUser?.sex ? editedUser.sex : 0,
    alpha:
      editedUser?.subscriptions &&
      editedUser?.subscriptions.find((elem) => {
        return elem.subscriptionId === 1
      })?.daysCounter === undefined
        ? 0
        : editedUser?.subscriptions.find((elem) => {
            return elem.subscriptionId === 1
          })?.daysCounter,
    beta:
      editedUser?.subscriptions &&
      editedUser?.subscriptions.find((elem) => {
        return elem.subscriptionId === 2
      })?.daysCounter === undefined
        ? 0
        : editedUser?.subscriptions.find((elem) => {
            return elem.subscriptionId === 2
          })?.daysCounter,
    balance: editedUser?.balance ? editedUser?.balance : 0,
    entranceAccess: editedUser?.entranceAccess
      ? editedUser?.entranceAccess
      : false,
    banned: editedUser?.banned ? editedUser?.banned : false,
  }

  const validationSchema = () => {
    return Yup.object().shape({
      phone: Yup.string()
        .matches(PHONE_REG_EXP, 'Введите корректный номер телефона')
        .required('Это поле обязательно'),
      login: Yup.string()
        .min(3, 'Длина логина должна быть больше 3 символов')
        .max(15, 'Длина логина должна быть меньше 15 символов')
        .required('Это поле обязательно'),
      email: Yup.string()
        .email('Введите корректный email')
        .required('Это поле обязательно'),
      firstname: Yup.string().nullable(),
      lastname: Yup.string().nullable(),
      alpha: Yup.number()
        .required('Это поле обязательно')
        .min(0, 'Введите положительное число'),
      beta: Yup.number()
        .required('Это поле обязательно')
        .min(0, 'Введите положительное число'),
      balance: Yup.number().required('Это поле обязательно'),
      entranceAccess: Yup.boolean().required('Это поле обязательно'),
      banned: Yup.boolean().required('Это поле обязательно'),
    })
  }

  const handleSubmit = (formValues: IEditUser) => {
    resetServerErrors()
    if (props.onUpdateUser) {
      props
        .onUpdateUser({
          id: editedUser!.id,
          balance: formValues.balance,
          banned: formValues.banned,
          email: formValues.email,
          entranceAccess: formValues.entranceAccess,
          login: formValues.login,
          name: formValues.firstname,
          phone: formValues.phone,
          sex: formValues.gender,
          subscriptions: [
            {
              id: 1,
              name: 'Альфа коворкинг',
              daysCounter: formValues.alpha,
            },
            {
              id: 2,
              name: 'Альфа коворкинг',
              daysCounter: formValues.beta,
            },
          ],
          surname: formValues.lastname,
        })
        .then(() => {
          navigate(ADMIN_USERS_LINK)
        })
        .catch(
          (
            err: AxiosError<{
              data: {
                email: string[] | null
                phone: string[] | null
                login: string[] | null
              }
            }>,
          ) => {
            if (err.response?.data) {
              const serverErrors: {
                email: string[] | null
                phone: string[] | null
                login: string[] | null
              } = err.response.data.data
              setErrors(serverErrors)
            } else if (props.openNotification) {
              props.openNotification('Произошла ошибка!', false)
            }
          },
        )
    }
  }

  const resetServerErrors = () => {
    setErrors({ email: null, login: null, phone: null })
  }

  return (
    <>
      <div className={s.edit}>
        <div className={s.one}>
          <span className={s.section_header}>
            Редактирование пользователя {editedUser?.login}
          </span>
        </div>

        <div className={s.section_main}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {(formik) => (
              <Form className={classNames(s.form, s.form_main)}>
                <div
                  className={classNames(s.form_container_one, s.form_container)}
                >
                  <div className={s.section_top}>Основные данные</div>
                  <div className={s.inputs_container}>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="phone">Номер телефона</label>
                        {props.isLoading ? (
                          <InputSkeleton className={s.input_skeleton} />
                        ) : (
                          <Field name="phone">
                            {({ field }: { field: { name: string } }) => (
                              <MaskedInput
                                {...field}
                                mask={PHONE_MASK}
                                id="phone"
                                placeholder="+7(___) ___-____"
                                type="tel"
                                onInput={() => {
                                  setErrors((prev) => {
                                    return { ...prev, phone: null }
                                  })
                                }}
                                className={classNames(
                                  s.form_control,
                                  'form-control',
                                  formik.errors.phone
                                    ? s.form_control__error
                                    : '',
                                )}
                              />
                            )}
                          </Field>
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        {formik.errors.phone ? (
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className={s.alert}
                          />
                        ) : (
                          <div className={s.alert}>
                            {errors.phone?.map((item) => {
                              return `${item}`
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="login">Логин</label>
                        {props.isLoading ? (
                          <InputSkeleton className={s.input_skeleton} />
                        ) : (
                          <Field
                            autoComplete="off"
                            id="login"
                            name="login"
                            type="text"
                            onInput={() => {
                              setErrors((prev) => {
                                return { ...prev, login: null }
                              })
                            }}
                            className={classNames(
                              s.form_control,
                              'form-control',
                              formik.errors.login ? s.form_control__error : '',
                            )}
                          />
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        {formik.errors.login ? (
                          <ErrorMessage
                            name="login"
                            component="div"
                            className={s.alert}
                          />
                        ) : (
                          <div className={s.alert}>
                            {errors.login?.map((item) => {
                              return `${item}`
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="email">E-mail</label>
                        {props.isLoading ? (
                          <InputSkeleton className={s.input_skeleton} />
                        ) : (
                          <Field
                            id="email"
                            name="email"
                            type="email"
                            onInput={() => {
                              setErrors((prev) => {
                                return { ...prev, email: null }
                              })
                            }}
                            className={classNames(
                              s.form_control,
                              'form-control',
                              formik.errors.email ? s.form_control__error : '',
                            )}
                          />
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        {formik.errors.email ? (
                          <ErrorMessage
                            name="email"
                            component="div"
                            className={s.alert}
                          />
                        ) : (
                          <div className={s.alert}>
                            {errors.email?.map((item) => {
                              return `${item}`
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label>Дата регистрации</label>
                        {props.isLoading ? (
                          <InputSkeleton className={s.input_skeleton} />
                        ) : (
                          <span className={s.date}>
                            {`${dayjs(
                              editedUser?.createdAt,
                              'YYYY-MM-DDThh:mm:ss',
                            ).fromNow()} (${editedUser?.createdAt.slice(
                              0,
                              10,
                            )})`}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(s.form_container_two, s.form_container)}
                >
                  <div className={s.section_top}>Дополнительные данные</div>
                  <div className={s.inputs_container}>
                    {' '}
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="firstname">Имя</label>
                        {props.isLoading ? (
                          <InputSkeleton className={s.input_skeleton} />
                        ) : (
                          <Field
                            id="firstname"
                            name="firstname"
                            type="text"
                            className={classNames(
                              s.form_control,
                              'form-control',
                              formik.errors.firstname
                                ? s.form_control__error
                                : '',
                            )}
                          />
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        <ErrorMessage
                          name="firstname"
                          component="div"
                          className={s.alert}
                        />
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="lastname">Фамилия</label>
                        {props.isLoading ? (
                          <InputSkeleton className={s.input_skeleton} />
                        ) : (
                          <Field
                            id="lastname"
                            name="lastname"
                            type="text"
                            className={classNames(
                              s.form_control,
                              'form-control',
                              formik.errors.lastname
                                ? s.form_control__error
                                : '',
                            )}
                          />
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className={s.alert}
                        />
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="gender">Пол</label>
                        {props.isLoading ? (
                          <InputSkeleton className={s.input_skeleton} />
                        ) : (
                          <Field name="gender">
                            {({
                              field,
                            }: {
                              field: {
                                name: string
                              }
                            }) => (
                              <Select
                                {...field}
                                id="gender"
                                onChange={(value) => {
                                  formik.setFieldValue('gender', value)
                                }}
                                className={classNames(
                                  s.form_control,
                                  'form-control',
                                  s['form_control__select'],
                                  s.form_control__select,
                                  formik.errors.gender
                                    ? s.form_control__error
                                    : '',
                                )}
                                options={[
                                  { value: 1, label: 'Мужской' },
                                  { value: 2, label: 'Женский' },
                                  { value: 0, label: 'Не определился' },
                                ]}
                              />
                            )}
                          </Field>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(
                    s.form_container__settings,
                    s.form_container,
                  )}
                >
                  <div className={s.section_top}>Настройки</div>
                  <div className={s.inputs_container}>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="alpha" className={s.settings}>
                          {'Альфа Коворкинг (дней)'}
                        </label>
                        {props.isLoading ? (
                          <InputSkeleton
                            className={classNames(
                              s.input_skeleton,
                              s.input_skeleton__settings,
                            )}
                          />
                        ) : (
                          <Field
                            name="alpha"
                            id="alpha"
                            type="number"
                            className={classNames(
                              s.form_control,
                              'form-control',
                              s.form_control__settings,
                              formik.errors.alpha ? s.form_control__error : '',
                            )}
                          />
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        <ErrorMessage
                          name="alpha"
                          component="div"
                          className={s.alert}
                        />
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="beta" className={s.settings}>
                          {'Бета Коворкинг (дней)'}
                        </label>
                        {props.isLoading ? (
                          <InputSkeleton
                            className={classNames(
                              s.input_skeleton,
                              s.input_skeleton__settings,
                            )}
                          />
                        ) : (
                          <Field
                            autoComplete="off"
                            id="beta"
                            name="beta"
                            type="number"
                            className={classNames(
                              s.form_control,
                              'form-control',
                              s.form_control__settings,
                              formik.errors.beta ? s.form_control__error : '',
                            )}
                          />
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        <ErrorMessage
                          name="beta"
                          component="div"
                          className={s.alert}
                        />
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="balance" className={s.settings}>
                          {'Баланс'}
                        </label>
                        {props.isLoading ? (
                          <InputSkeleton
                            className={classNames(
                              s.input_skeleton,
                              s.input_skeleton__settings,
                            )}
                          />
                        ) : (
                          <Field
                            id="balance"
                            name="balance"
                            type="number"
                            className={classNames(
                              s.form_control,
                              'form-control',
                              s.form_control__settings,
                              formik.errors.balance
                                ? s.form_control__error
                                : '',
                            )}
                          />
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        <ErrorMessage
                          name="balance"
                          component="div"
                          className={s.alert}
                        />
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="banned" className={s.settings}>
                          {'Доступ'}
                        </label>
                        {props.isLoading ? (
                          <InputSkeleton
                            className={classNames(
                              s.input_skeleton,
                              s.input_skeleton__settings,
                            )}
                          />
                        ) : (
                          <Field name="banned">
                            {({ field }: { field: { name: string } }) => (
                              <Select
                                {...field}
                                onChange={(value) => {
                                  formik.setFieldValue('banned', value)
                                }}
                                id="banned"
                                className={classNames(
                                  s.form_control,
                                  'form-control',
                                  s.form_control__settings,
                                  formik.errors.banned
                                    ? s.form_control__error
                                    : '',
                                )}
                                options={[
                                  { value: false, label: 'Разрешён' },
                                  { value: true, label: 'Заблокирован' },
                                ]}
                              />
                            )}
                          </Field>
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        <ErrorMessage
                          name="banned"
                          component="div"
                          className={s.alert}
                        />
                      </div>
                    </div>
                    <div className={s.form_group}>
                      <div className={s['field-container']}>
                        <label htmlFor="entranceAccess" className={s.settings}>
                          {'Свободный вход'}
                        </label>
                        {props.isLoading ? (
                          <InputSkeleton
                            className={classNames(
                              s.input_skeleton,
                              s.input_skeleton__settings,
                            )}
                          />
                        ) : (
                          <Field name="entranceAccess">
                            {({ field }: { field: { name: string } }) => (
                              <Select
                                {...field}
                                onChange={(value) => {
                                  formik.setFieldValue('entranceAccess', value)
                                }}
                                id="entranceAccess"
                                className={classNames(
                                  s.form_control,
                                  'form-control',
                                  s.form_control__settings,
                                  formik.errors.entranceAccess
                                    ? s.form_control__error
                                    : '',
                                )}
                                options={[
                                  { value: true, label: 'Разрешён' },
                                  { value: false, label: 'Не разрешён' },
                                ]}
                              />
                            )}
                          </Field>
                        )}
                      </div>
                      <div className={s['alert-container']}>
                        <ErrorMessage
                          name="entranceAccess"
                          component="div"
                          className={s.alert}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" className={s.button_save}>
                  Сохранить
                </button>
                <button
                  className={s.button_cancel}
                  onClick={() => {
                    navigate(ADMIN_USERS_LINK)
                  }}
                >
                  Отмена
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default AdminEditUser
