import { useNavigate } from 'react-router-dom'
import s from './AdminAddPromocode.module.scss'
import {
  AddPromocodeType,
  GetUsersType,
  IAdminAddPromocode,
  IAdminUser,
} from '../../@types/assets'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import classNames from 'classnames'
import { ADMIN_PROMOCODES_LINK } from '../../constants'
import { Select } from 'antd'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import InputSkeleton from '../InputSkeleton/InputSkeleton'

const AdminAddPromocode = (props: {
  isLoading?: boolean
  onAddPromocode?: AddPromocodeType
  onGetUsers?: GetUsersType
}) => {
  document.title = `Создание нового промокода | Bekker-place Admin`
  const navigate = useNavigate()
  const filterOption = (
    input: string,
    option?: { label: string; value: number },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const validationSchema = () => {
    return Yup.object().shape({
      daysCounter: Yup.number().required('Это поле обязательно'),
      discount: Yup.number()
        .required('Это поле обязательно')
        .min(0, 'Введите положительное число'),
      expiresIn: Yup.number().required('Это поле обязательно'),
      usagesLeft: Yup.number()
        .required('Это поле обязательно')
        .min(-1, 'Введите верное значение'),
      subscriptionId: Yup.number().required('Это поле обязательно'),
      userId: Yup.number().required('Это поле обязательно'),
    })
  }

  const initialValues: IAdminAddPromocode = {
    daysCounter: 10,
    discount: 0,
    expiresIn: 1,
    usagesLeft: 1,
    subscriptionId: 0,
    userId: 0,
  }

  const getDays = () => {
    const days: { value: number; label: string }[] = []
    for (let i = 1; i <= 50; i++) {
      days.push({
        value: i,
        label: `${i} (до ${new Date(
          Date.now() + i * 24 * 60 * 60 * 1000,
        ).toLocaleDateString('ru', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })})`,
      })
    }
    return days
  }

  const handleSubmit = (formValues: IAdminAddPromocode) => {
    if (props.onAddPromocode) {
      props.onAddPromocode(formValues).then(() => {
        navigate(ADMIN_PROMOCODES_LINK)
      })
    }
  }

  const [allUsers, setAllUsers] = useState<IAdminUser[]>([])
  const [isCodeAddLoading, setIsCodeAddLoading] = useState(false)

  useEffect(() => {
    if (props.onGetUsers) {
      setIsCodeAddLoading(true)
      props
        .onGetUsers(0, 1)
        .then((res) => {
          setAllUsers((prev) => {
            let newValue = prev.concat(res.data.data)
            return newValue
          })
        })
        .finally(() => {
          setIsCodeAddLoading(false)
        })
    }
  }, [])
  return (
    <div className={s.settings}>
      <h1 className={s.title}>Созданиие нового промокода</h1>
      <div className={s.form_container}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formik) => (
            <Form className={s.form}>
              <div className={s.inputs_container}>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="expiresIn">Сколько дней действует?</label>
                    <Field name="expiresIn">
                      {({
                        field,
                      }: {
                        field: {
                          name: string
                        }
                      }) => (
                        <Select
                          {...field}
                          id="expiresIn"
                          onChange={(value) => {
                            formik.setFieldValue('expiresIn', value)
                          }}
                          className={classNames(
                            s.form_control,
                            'form-control',
                            s.form_control__select,
                            formik.errors.expiresIn
                              ? s.form_control__error
                              : '',
                          )}
                          options={getDays()}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="expiresIn"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="usagesLeft">
                      Кол-во срабатываний (если нужен многоразовый по дате,
                      поставить -1)
                    </label>
                    <Field
                      autoComplete="off"
                      id="usagesLeft"
                      name="usagesLeft"
                      type="number"
                      className={classNames(
                        s.form_control,
                        'form-control',
                        formik.errors.usagesLeft ? s.form_control__error : '',
                      )}
                    />
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="usagesLeft"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="discount">Стоимость промокода</label>

                    <Field
                      autoComplete="off"
                      id="discount"
                      name="discount"
                      type="number"
                      className={classNames(
                        s.form_control,
                        'form-control',
                        formik.errors.discount ? s.form_control__error : '',
                      )}
                    />
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="discount"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="daysCounter">Кол-во дней подписки</label>
                    <Field
                      autoComplete="off"
                      id="daysCounter"
                      name="daysCounter"
                      type="number"
                      className={classNames(
                        s.form_control,
                        'form-control',
                        formik.errors.daysCounter ? s.form_control__error : '',
                      )}
                    />
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="daysCounter"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="subscriptionId">
                      Дни добавятся на подписку
                    </label>
                    <Field name="subscriptionId">
                      {({
                        field,
                      }: {
                        field: {
                          name: string
                        }
                      }) => (
                        <Select
                          {...field}
                          id="subscriptionId"
                          className={classNames(
                            s.form_control,
                            'form-control',
                            s.form_control__select,
                            formik.errors.subscriptionId
                              ? s.form_control__error
                              : '',
                          )}
                          onChange={(value) => {
                            formik.setFieldValue('subscriptionId', value)
                          }}
                          options={[
                            { value: 1, label: 'Альфа Коворкинг' },
                            { value: 2, label: 'Бета Коворкинг' },
                            { value: 0, label: 'Не выбрано' },
                          ]}
                        />
                      )}
                    </Field>
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="subscriptionId"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
                <div className={s.form_group}>
                  <div className={s['field-container']}>
                    <label htmlFor="userId">Для пользователя</label>
                    {isCodeAddLoading ? (
                      <InputSkeleton
                        className={classNames(s.input_skeleton)}
                      ></InputSkeleton>
                    ) : (
                      <Field name="userId">
                        {({
                          field,
                        }: {
                          field: {
                            name: string
                          }
                        }) => (
                          <Select
                            {...field}
                            showSearch
                            id="userId"
                            filterOption={filterOption}
                            className={classNames(
                              s.form_control,
                              'form-control',
                              s.form_control__select,
                              formik.errors.userId ? s.form_control__error : '',
                            )}
                            onChange={(value) => {
                              formik.setFieldValue('userId', value)
                            }}
                            options={[{ value: 0, label: 'Не выбрано' }].concat(
                              allUsers.map((user) => {
                                return {
                                  value: user.id,
                                  label: `${user.login} (${user.phone}, ${user.email} )`,
                                }
                              }),
                            )}
                          />
                        )}
                      </Field>
                    )}
                  </div>
                  <div className={s['alert-container']}>
                    <ErrorMessage
                      name="userId"
                      component="div"
                      className={s.alert}
                    />
                  </div>
                </div>
              </div>
              <div className={s.buttons_container}>
                <button type="submit" className={s.button_save}>
                  Сохранить
                </button>
                <button
                  className={s.button_cancel}
                  onClick={() => {
                    navigate(ADMIN_PROMOCODES_LINK)
                  }}
                >
                  Отмена
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default AdminAddPromocode
