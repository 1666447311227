import { createContext } from 'react'

const modalObject: {
  modal: string | null
  setModal: React.Dispatch<React.SetStateAction<string | null>> | VoidFunction
} = {
  modal: null,
  setModal: () => {},
}

export default createContext({
  modalObject: { ...modalObject },
})
