import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import s from './Login.module.scss'
import classNames from 'classnames'
import { Button, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {
  DASHBOARD_LINK,
  PASSWORD_SEND_EMAIL_LINK,
  PHONE_MASK,
  PHONE_REG_EXP,
} from '../../constants'
import MaskedInput from 'react-text-mask'
import { AxiosError } from 'axios'
import { ILogin, LoginType } from '../../@types/assets'
import { Cookies } from 'react-cookie'

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: '#be763c',
    }}
    spin
  />
)

const Login = (props: { onLogin?: LoginType; loginLoading?: boolean }) => {
  const [serverError, setServerError] = useState<string | undefined>('')
  const cookie = new Cookies()

  const navigate = useNavigate()
  const initialValues = {
    phone: '',
    password: '',
    rememberMe: false,
  }

  useEffect(() => {
    if (cookie.get('token')) {
      navigate(DASHBOARD_LINK, { replace: true })
    }
  }, [])

  const validationSchema = () => {
    return Yup.object().shape({
      phone: Yup.string()
        .matches(PHONE_REG_EXP, 'Введите корректный номер телефона')
        .required('Это поле обязательно'),
      password: Yup.string().required('Это поле обязательно'),
    })
  }

  const onSubmit = (
    formValue: ILogin,
    onSubmitProps: { setSubmitting: (isSubmitting: boolean) => void },
  ) => {
    setServerError('')
    if (props.onLogin) {
      props
        .onLogin(formValue)
        .catch((err: AxiosError) => {
          if (err.response?.status === 401) {
            setServerError('Неправильный номер телефона или пароль')
          } else {
            setServerError(JSON.parse(err.request.response).message)
          }
        })
        .finally(() => {
          onSubmitProps.setSubmitting(false)
        })
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => (
        <Form className={s.form}>
          <div
            className={classNames(
              s.form_group,
              s['form_group-field'],
              s.form_group__column,
            )}
          >
            <label htmlFor="phone">Номер телефона</label>
            <Field name="phone">
              {({ field }: { field: { name: string } }) => (
                <MaskedInput
                  {...field}
                  mask={PHONE_MASK}
                  id="phone"
                  placeholder="+7(___) ___-____"
                  autoComplete="tel-national"
                  type="tel"
                  className={classNames(s.form_control, 'form-control')}
                />
              )}
            </Field>
            <ErrorMessage name="phone" component="div" className={s.alert} />
          </div>
          <div
            className={classNames(
              s.form_group,
              s['form_group-field'],
              s.form_group__column,
            )}
          >
            <label htmlFor="password">Пароль</label>
            <Field
              name="password"
              id="password"
              type="password"
              autoComplete="current-password"
              className={classNames(s.form_control, 'form-control')}
            />
            <ErrorMessage name="password" component="div" className={s.alert} />
          </div>
          <div className={s.form_group}>
            <Field
              name="rememberMe"
              id="rememberMe"
              type="checkbox"
              className={s.checkbox}
            />
            <label htmlFor="rememberMe">Запомнить меня</label>
          </div>
          <div className={classNames(s.form_group, s.form_group__column)}>
            <Button
              className={classNames('Button', s.submit_button)}
              type="primary"
              htmlType="submit"
              disabled={!formik.isValid || formik.isSubmitting}
            >
              {props.loginLoading ? (
                <Spin className="preloader" indicator={antIcon} />
              ) : (
                'Войти'
              )}
            </Button>
            <Link to={PASSWORD_SEND_EMAIL_LINK}>
              <p className={s['form_group-link']}>Я забыл пароль</p>
            </Link>
          </div>
          <div className={s.alert}>{serverError}</div>
        </Form>
      )}
    </Formik>
  )
}

export default Login
