import { Outlet, Navigate, useLocation } from 'react-router-dom'
import { LOGIN_LINK } from '../../constants'
import { Cookies } from 'react-cookie'

type ProtectedRouteProps = {
  isLoggedIn: boolean
}

const ProtectedRoute = ({ isLoggedIn }: ProtectedRouteProps) => {
  const cookie = new Cookies()
  const location = useLocation()
  return cookie.get('token') ? (
    <Outlet />
  ) : (
    <Navigate to={LOGIN_LINK} replace state={{ path: location.pathname }} />
  )
}

export default ProtectedRoute
