import classNames from 'classnames'
import { CONFIDENTIAL_LINK } from '../../constants'
import Section from '../Section'
import s from './Footer.module.scss'
import logo from './images/logo_white.svg'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Link } from 'react-router-dom'
import { IPromotion } from '../../@types/assets'

export default function Footer(props: { landingPromotions: IPromotion[] }) {
  return (
    <Section className={classNames(s['footer-section'])}>
      <div className={classNames('section_wrapper', s.footer)}>
        <Link to="/">
          <LazyLoadImage effect="blur" src={logo} alt="" className={s.logo} />
        </Link>
        <nav>
          <ul className={s.footer__menu}>
            {props.landingPromotions ? (
              <li className={s['footer__menu-item']}>
                <a className={s['footer__menu-link']} href="#promos">
                  Акции
                </a>
              </li>
            ) : null}
            <li className={s['footer__menu-item']}>
              <a className={s['footer__menu-link']} href="#benefits">
                Что включено
              </a>
            </li>
            <li className={s['footer__menu-item']}>
              <a className={s['footer__menu-link']} href="#prices">
                Цены
              </a>
            </li>
            <li className={s['footer__menu-item']}>
              <a className={s['footer__menu-link']} href="#how-use">
                Как пользоваться?
              </a>
            </li>
            <li className={s['footer__menu-item']}>
              <a className={s['footer__menu-link']} href="#gallery-slider">
                Фото
              </a>
            </li>
          </ul>
        </nav>
        <a
          href={CONFIDENTIAL_LINK}
          className={s.policy}
          target="_blank"
          rel="noreferrer"
        >
          Политика конфиденциальности
        </a>
      </div>
    </Section>
  )
}
