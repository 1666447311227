import ContentLoader from 'react-content-loader'

const InputSkeleton = (props: { className?: string }) => (
  <ContentLoader
    speed={2}
    width={'100%'}
    height={45}
    viewBox="0 0 100% 45"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="100%" height="45" />
  </ContentLoader>
)

export default InputSkeleton
