export default function Profile(props: { className: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="69"
      height="64"
      fill="none"
      viewBox="0 0 69 64"
      className={props.className}
    >
      <circle cx="32" cy="32" r="32" fill="#BE763C"></circle>
      <circle
        cx="32.527"
        cy="23.409"
        r="7.89"
        stroke="#fff"
        strokeWidth="1.5"
        fill="none"
      ></circle>
      <path
        stroke="#fff"
        strokeWidth="1.5"
        d="M21.334 47.758v-4.763a8 8 0 018-8h6.974a8 8 0 018 8v4.763"
        fill="none"
      ></path>
      <path
        fill="#fff"
        d="M56.534 42c-.257-.005-.392.212-.463.344L55.4 44.66c-.507.159-.99.37-1.45.613l-2.122-1.181a.492.492 0 00-.568.09l-2.077 2.062a.492.492 0 00-.075.583l1.18 2.122a8.637 8.637 0 00-.612 1.45l-2.331.657a.492.492 0 00-.344.464v2.944a.492.492 0 00.344.463l2.346.672c.158.501.357.979.598 1.435l-1.18 2.137a.492.492 0 00.074.568l2.077 2.077c.147.143.39.175.568.075l2.122-1.18a8.7 8.7 0 001.45.597l.672 2.331a.493.493 0 00.464.359h2.929c.21 0 .41-.155.463-.359l.673-2.331a8.68 8.68 0 001.45-.598l2.121 1.18c.179.1.422.069.568-.074l2.078-2.077a.492.492 0 00.074-.568l-1.18-2.137c.24-.456.44-.934.597-1.435l2.347-.672a.492.492 0 00.343-.463V51.52a.493.493 0 00-.343-.464l-2.332-.657a8.654 8.654 0 00-.612-1.45l1.18-2.122a.493.493 0 00-.074-.583l-2.077-2.062a.492.492 0 00-.568-.09l-2.122 1.18a8.664 8.664 0 00-1.45-.612l-.673-2.316a.492.492 0 00-.463-.344h-2.93zm.344.957h2.242l.612 2.196c.047.16.183.29.344.33.624.171 1.21.432 1.764.746a.488.488 0 00.463 0l2.017-1.12 1.57 1.569-1.121 2.002a.488.488 0 000 .478c.314.554.56 1.14.732 1.764a.488.488 0 00.329.329l2.212.627v2.227l-2.212.628a.488.488 0 00-.329.329 7.772 7.772 0 01-.732 1.778.488.488 0 000 .463l1.105 2.018-1.554 1.554-2.017-1.12a.488.488 0 00-.463.014 7.66 7.66 0 01-1.764.732.487.487 0 00-.344.33l-.627 2.211h-2.212l-.628-2.212a.488.488 0 00-.343-.329 7.66 7.66 0 01-1.764-.732.487.487 0 00-.463-.015l-2.018 1.121-1.554-1.554 1.106-2.018a.488.488 0 000-.463 7.78 7.78 0 01-.732-1.778.487.487 0 00-.33-.33l-2.211-.627V51.88l2.212-.628a.487.487 0 00.329-.329c.172-.624.417-1.21.732-1.763a.488.488 0 000-.479l-1.12-2.002 1.569-1.57 2.017 1.121a.487.487 0 00.463 0 7.7 7.7 0 011.764-.747.487.487 0 00.343-.329l.613-2.196zm1.12 6.217A3.833 3.833 0 0054.174 53 3.833 3.833 0 0058 56.825 3.833 3.833 0 0061.825 53a3.833 3.833 0 00-3.826-3.826zm0 .956c1.591 0 2.87 1.28 2.87 2.87s-1.279 2.869-2.87 2.869a2.862 2.862 0 01-2.868-2.87c0-1.59 1.279-2.869 2.869-2.869z"
      ></path>
    </svg>
  );
}
