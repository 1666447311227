import ContentLoader from 'react-content-loader'

const QRSkeleton = (props: { className?: string }) => (
  <ContentLoader
    speed={2}
    width={348}
    height={348}
    viewBox="0 0 348 348"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="4" ry="4" width="348" height="348" />
  </ContentLoader>
)

export default QRSkeleton
