import classNames from "classnames";
import React from "react";
import s from "./Section.module.scss";

export default function Section(props: {
    children: React.ReactNode;
    className?: string;
    id?: string;
}) {
    return (
        <section
            className={classNames(s.section, `${props.className}`)}
            id={props.id ? props.id : undefined}
        >
            {props.children}
        </section>
    );
}
