import Section from '../Section'
import s from './ManageGuest.module.scss'
import classNames from 'classnames'
import InviteGuest from '../InviteGuestForm/InviteGuest'
import ActiveGuests from '../ActiveGuestsTable/ActiveGuests'
import {
  ICard,
  IGuest,
  IInviteForm,
  ISubscriptions,
  InviteGuestType,
} from '../../@types/assets'

const ManageGuest = (props: {
  guests?: IGuest[]
  isLoading: boolean
  subscriptions?: ISubscriptions | null
  onInviteGuest?: InviteGuestType
  card?: ICard | null
}) => {
  return (
    <Section className={s['section-header']}>
      <div className="section_wrapper">
        <div className={s.guest}>
          <div className={s.one}>
            <span className={s.section_header}>Управление гостями</span>
            <br />
            <span className={s.section_description}>
              В этом разделе вы можете управлять своими гостями
            </span>
          </div>

          <div className={classNames(s.two, s.section_main)}>
            <InviteGuest
              subscriptions={props.subscriptions}
              isLoading={props.isLoading}
              onInviteGuest={props.onInviteGuest}
              card={props.card}
            />
          </div>

          <div className={classNames(s.three, s.section_addition)}>
            <ActiveGuests guests={props.guests} isLoading={props.isLoading} />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ManageGuest
