import s from './InviteGuest.module.scss'
import classNames from 'classnames'
import { Button, Select, Tooltip } from 'antd'
import exclamation from './images/exclamation.svg'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-widgets/DatePicker'
import 'react-widgets/styles.css'
import {
  ICard,
  IInviteForm,
  ISubscriptions,
  InviteGuestType,
} from '../../@types/assets'
import InputSkeleton from '../InputSkeleton/InputSkeleton'
import { useEffect, useState } from 'react'
import Localization from 'react-widgets/esm/Localization'
import { DateLocalizer } from 'react-widgets/IntlLocalizer'

const InviteGuest = (props: {
  subscriptions?: ISubscriptions | null
  isLoading: boolean
  onInviteGuest?: InviteGuestType
  card?: ICard | null
}) => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() + 1)

  const initialValues = {
    subscription: props.subscriptions?.data[0].subscriptionId
      ? props.subscriptions?.data[0].subscriptionId
      : NaN,
    inviteDate: currentDate,
    count: 1,
    note: '',
  }

  const validationSchema = () => {
    return Yup.object().shape({
      note: Yup.string().required('Заполните примечаниие!'),
    })
  }

  const [allSubDays, setAllSubDays] = useState(0)
  const [inviteDisabled, setInviteDisabled] = useState(true)
  useEffect(() => {
    if (props.subscriptions) {
      setAllSubDays(
        props.subscriptions.data.reduce((currentSum, currentNumber) => {
          return currentSum + currentNumber.daysCounter
        }, 0),
      )
    }
  }, [props.subscriptions])

  useEffect(() => {
    if (allSubDays > 0) {
      setInviteDisabled(false)
    } else {
      setInviteDisabled(true)
    }
  }, [allSubDays])

  const { Option } = Select

  return (
    <>
      <div className={s.section_top}>
        <div className={classNames(s['top-img'], s.invite)}></div>
        Пригласить гостя
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(formValues) => {
          if (props.onInviteGuest && props.card?.number) {
            props.onInviteGuest(props.card.id, formValues)
          }
        }}
      >
        {(formik) => (
          <Form className={classNames(s.form, s.form_main)}>
            <label htmlFor="subscription" className={s.one}>
              Локация
            </label>
            {props.isLoading ? (
              <InputSkeleton className={s.two} />
            ) : (
              <Field name="subscription">
                {({ field }: { field: { name: string } }) => (
                  <Select
                    {...field}
                    id="subscription"
                    className={classNames(
                      s.form_control,
                      'form-control',
                      s.two,
                    )}
                    onChange={(value) => {
                      formik.setFieldValue('subscription', value)
                    }}
                  >
                    {props.subscriptions?.data.map((subscription) => {
                      return (
                        <Option value={subscription.subscriptionId}>
                          {subscription.locationName}
                        </Option>
                      )
                    })}
                  </Select>
                )}
              </Field>
            )}
            <label className={s.three} htmlFor="inviteDate">
              Дата
            </label>
            {props.isLoading ? (
              <InputSkeleton className={s.four} />
            ) : (
              <Field name="inviteDate">
                {({ field }: { field: { name: string } }) => (
                  <Localization
                    date={new DateLocalizer({ firstOfWeek: 1, culture: 'ru' })}
                  >
                    <DatePicker
                      {...field}
                      className={s.four}
                      min={currentDate}
                      valueEditFormat={{ dateStyle: 'short' }}
                      valueDisplayFormat={{ dateStyle: 'medium' }}
                      value={formik.values.inviteDate}
                      onChange={(value) => {
                        formik.setFieldValue('inviteDate', value)
                      }}
                    />
                  </Localization>
                )}
              </Field>
            )}
            <label className={s.five} htmlFor="count">
              Количество гостей
            </label>
            {props.isLoading ? (
              <InputSkeleton className={s.six} />
            ) : (
              <Field name="count">
                {({ field }: { field: { name: string } }) => (
                  <Select
                    {...field}
                    id="count"
                    className={classNames(
                      s.form_control,
                      'form-control',
                      s.six,
                    )}
                    onChange={(value) => {
                      formik.setFieldValue('count', value)
                    }}
                  >
                    <Option value={1}>1</Option>
                    <Option value={2}>2</Option>
                    <Option value={3}>3</Option>
                  </Select>
                )}
              </Field>
            )}
            <label className={s.seven} htmlFor="note">
              Примечание
            </label>
            <Field
              id="note"
              as="textarea"
              name="note"
              type="text"
              maxLength="50"
              placeholder="В этом поле можете написать имя гостя"
              className={classNames(s.comment, 'form-control', s.eight)}
            />
            <div className={s['alert-container']}>
              <ErrorMessage name="note" component="div" className={s.alert} />
            </div>
            <Tooltip
              placement="topLeft"
              trigger="hover"
              title={
                inviteDisabled ? (
                  <div
                    style={{
                      padding: '13px 1px 10px 21px',
                      position: 'relative',
                      fontSize: '12px',
                      width: '187px',
                    }}
                  >
                    <img
                      src={exclamation}
                      alt="excl"
                      style={{
                        position: 'absolute',
                        top: '26px',
                        left: '5px',
                      }}
                    />
                    Недостаточно дней для приглашения гостя.
                    <br />
                    Приобретите подписку
                  </div>
                ) : null
              }
            >
              <span className={s.nine}>
                <Button
                  disabled={inviteDisabled}
                  htmlType="submit"
                  className={classNames('Button', s.button, s.nine)}
                >
                  Пригласить
                </Button>
              </span>
            </Tooltip>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default InviteGuest
