import s from './AdminPromotions.module.scss'
import { useEffect, useMemo, useState } from 'react'
import {
  Column,
  TableOptions,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import {
  EditOutlined,
  PlusOutlined,
  DeleteOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd'
import {
  ADMIN_ADD_PROMOTION_LINK,
  STARTUP_PAGINATION_DATA,
} from '../../constants'
import {
  IPagination,
  DeletePromotionType,
  GetPromotionsType,
  IAdminPromotion,
} from '../../@types/assets'
import classNames from 'classnames'
import GlobalFilter from '../GlobalFilter/GlobalFilter'
import { useGlobalFilter } from 'react-table'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const AdminPromotions = (props: {
  onGetPromotions?: GetPromotionsType
  onDeletePromotion?: DeletePromotionType
  isLoading?: boolean
}) => {
  dayjs.locale('ru')
  document.title = `Список акций | Bekker-place Admin`
  const navigate = useNavigate()

  const antTableIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
        color: '#be763c',
      }}
      spin
    />
  )

  const [adminPromotions, setAdminPromotions] = useState<IAdminPromotion[]>([])

  const [paginationParameters, setPaginationParameters] = useState<IPagination>(
    STARTUP_PAGINATION_DATA,
  )
  const data = useMemo(() => {
    if (adminPromotions.length !== 0) {
      return adminPromotions
    } else {
      return []
    }
  }, [adminPromotions])

  const columns: Column<IAdminPromotion>[] = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Локация',
        accessor: 'locationId',
        Cell: (props) => {
          return (
            <>
              <span>
                {props.value === 3 ? 'Альфа Коворкинг' : 'Бета Коворкинг'}
              </span>
            </>
          )
        },
      },
      {
        Header: 'Тип цены',
        accessor: 'title',
      },
      {
        Header: 'Новая цена',
        accessor: 'newCost',
      },
      {
        Header: 'Действителен до',
        accessor: 'expiredAt',
        Cell: (cellProps) => {
          return (
            <span>{dayjs(cellProps.value).format('DD MMM YYYY, HH:mm')}</span>
          )
        },
      },
      {
        Header: 'Действия',
        accessor: 'id',
        disableSortBy: true,
        id: 'actions',
        Cell: (cellProps) => {
          return (
            <>
              <button
                className={s.edit}
                onClick={() => {
                  navigate(`/admin/promotions/${cellProps.value}/edit`)
                }}
              >
                <EditOutlined style={{ color: '#fff' }} />
              </button>
              <button
                className={s.delete}
                onClick={() => {
                  handleDeletePromotion(cellProps.value)
                }}
              >
                <DeleteOutlined style={{ color: '#fff' }} />
              </button>
            </>
          )
        },
      },
    ],
    [],
  )

  const options: TableOptions<IAdminPromotion> = {
    data,
    columns,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    page,
    pageOptions,
    gotoPage,
    pageCount,
    prepareRow,
    state,
    setPageSize,
    setGlobalFilter,
  } = useTable(options, useGlobalFilter, useSortBy, usePagination)
  // } = useTable(options, useGlobalFilter, useSortBy)

  const { globalFilter } = state
  const [pageSizeLocal, setPageSizeLocal] = useState(10)
  const [pageNumber, setPageNumber] = useState(1)

  const onChangeGoTo = useAsyncDebounce((value: number) => {
    setPageNumber(Number(value))
  }, 2000)

  const handleDeletePromotion = (id: number) => {
    if (props.onDeletePromotion) {
      props.onDeletePromotion(id).then(() => {
        handleGetPromotions(pageSizeLocal, pageNumber)
      })
    }
  }

  const handleGetPromotions = (perPage: number, page: number) => {
    if (props.onGetPromotions) {
      props.onGetPromotions(perPage, page).then((res) => {
        setAdminPromotions(res.data.data)
        setPaginationParameters(res.data.pagination)
      })
    }
  }

  useEffect(() => {
    handleGetPromotions(pageSizeLocal, pageNumber)
  }, [pageSizeLocal, pageNumber])

  return (
    <div className={s.users}>
      <h1 className={s.title}>Список акций</h1>
      <div className={s.table_container}>
        <div className={s.table_header}>
          <button
            className={s['add-button']}
            onClick={() => {
              navigate(ADMIN_ADD_PROMOTION_LINK)
            }}
          >
            <PlusOutlined /> Добавить акцию
          </button>
        </div>
        <div className={s['table_options-container']}>
          <select
            className={s['table_page-size']}
            value={pageSizeLocal}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
              setPageSizeLocal(Number(e.target.value))
              setPageNumber(1)
            }}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Показать {pageSize}
              </option>
            ))}
          </select>
          {/* <GlobalFilter
            filter={globalFilter}
            setFilter={setGlobalFilter}
            className={s['table_search-input']}
            containerClassName={s.table_search}
          /> */}
        </div>
        <div className={s.table_wrapper}>
          <table {...getTableProps()} className={s.table_main}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      // {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                      {/* {column.disableSortBy ? (
                        <></>
                      ) : column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className={s.table_arrow}>
                            &#8593;
                            <span className={s.table_arrow__unactive}>
                              &#8595;
                            </span>
                          </span>
                        ) : (
                          <span className={s.table_arrow}>
                            <span className={s.table_arrow__unactive}>
                              &#8593;
                            </span>
                            &#8595;
                          </span>
                        )
                      ) : (
                        <span
                          className={classNames(
                            s.table_arrow,
                            s.table_arrow__unactive,
                          )}
                        >
                          &#8593;&#8595;
                        </span>
                      )} */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((page) => {
                prepareRow(page)
                return (
                  <tr {...page.getRowProps()}>
                    {page.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {!props.isLoading ? null : (
          <div className={s['table-spinner-container']}>
            <Spin indicator={antTableIcon} />
          </div>
        )}
        <div className={s.pagination}>
          <span className={s.pagination_text}>
            Страница {paginationParameters.currentPage} из{' '}
            {paginationParameters.totalPages}
          </span>
          <div className={s.pagination_goto}>
            <span className={s.pagination_text}>Перейти на страницу</span>
            <input
              type="number"
              defaultValue={1}
              max={paginationParameters.totalPages}
              min={1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const pageValue = e.target.value ? Number(e.target.value) : 0
                if (pageValue > paginationParameters.totalPages) {
                  onChangeGoTo(paginationParameters.totalPages)
                  return
                } else if (pageValue < 1) {
                  onChangeGoTo(1)
                  return
                }
                onChangeGoTo(pageValue)
              }}
              className={s.pagination_input}
            ></input>
          </div>
          <div className={s['pagination_button-container']}>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__first,
              )}
              onClick={() => {
                setPageNumber(1)
              }}
              disabled={pageNumber === 1}
            >
              1
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__middle,
              )}
              onClick={() => {
                setPageNumber(pageNumber - 1)
              }}
              disabled={pageNumber === 1}
            >
              Предыдущая
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__middle,
              )}
              onClick={() => {
                setPageNumber(pageNumber + 1)
              }}
              disabled={paginationParameters.totalPages === pageNumber}
            >
              Следующая
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__last,
              )}
              onClick={() => {
                setPageNumber(paginationParameters.totalPages)
              }}
              disabled={paginationParameters.totalPages === pageNumber}
            >
              {paginationParameters.totalPages}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminPromotions
