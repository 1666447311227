import React from 'react'
import { FloatButton } from 'antd'
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  GeolocationControl,
} from '@pbe/react-yandex-maps'
import CompareSection from '../../components/CompareSection'
import RentBenefits from '../../components/RentBenefits'
import Tariffs from '../../components/Tariffs'
import HowUse from '../../components/HowUse'
import GallerySlider from '../../components/GallerySlider'
import Footer from '../../components/Footer'
import Coworking from '../../components/Coworking'
import Header from '../../components/Header'
import Promos from '../../components/Promos'
import YandexMap from '../../components/YandexMap'
import Hero from '../../components/Hero'
import Cookies from '../../components/Cookies'
import TopMenu from '../../components/TopMenu'
import classNames from 'classnames'
import CallIcon from '../../images/CallIcon'
import { IPromotion, SetActiveTopMenuType, Tariff } from '../../@types/assets'

type MainProps = {
  activeTopMenu: boolean
  setActiveTopMenu: SetActiveTopMenuType
  title: string
  tariffs: Tariff[]
  landingPromotions: IPromotion[]
}
const Main = (props: MainProps) => {
  document.title = props.title
  return (
    <YMaps>
      <div
        className={classNames('App', props.activeTopMenu ? 'menu-visible' : '')}
      >
        <Header landingPromotions={props.landingPromotions} />
        <Hero setActiveTopMenu={props.setActiveTopMenu} />
        <Promos landingPromotions={props.landingPromotions} />
        <Coworking />
        <CompareSection />
        <RentBenefits />
        <Tariffs tariffs={props.tariffs} />
        <HowUse />
        <GallerySlider />
        <YandexMap>
          <div className="map-wrapper">
            <Map
              defaultState={{
                center: [51.499032, 46.140709],
                zoom: 13,
              }}
              width="100%"
              height="100%"
              id="contacts"
            >
              <Placemark
                geometry={[51.499032, 46.140709]}
                properties={{
                  balloonContentBody: 'Test 6',
                }}
              />
              <ZoomControl />
              <GeolocationControl />
            </Map>
          </div>
        </YandexMap>
        <Footer landingPromotions={props.landingPromotions} />
        <FloatButton.BackTop />
        <a href="tel:+78452496987" className="phoneLink">
          <CallIcon className="callIcon callIcon-index" />
        </a>
        <TopMenu
          landingPromotions={props.landingPromotions}
          visible={props.activeTopMenu}
          setActiveTopMenu={props.setActiveTopMenu}
        />
        <Cookies />
      </div>
    </YMaps>
  )
}

export default Main
