import classNames from 'classnames'
import { DASHBOARD_LINK, REGISTER_LINK } from '../../constants'
import Section from '../Section'
import s from './HowUse.module.scss'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import CurrentUserContext from '../../contexts/AuthContext'

export default function HowUse() {
  const { authData } = useContext(CurrentUserContext)
  return (
    <Section id="how-use" className={classNames(s['how-use-section'])}>
      <div
        className={classNames('section_wrapper', s['how-use-section-wrapper'])}
      >
        <div className={s['how-use-table']}>
          <h2 className={s['how-use-section__title']}>
            <span className="text-accent">КАК ПОЛЬЗОВАТЬСЯ</span> КОВОРКИНГОМ
          </h2>
          <div className={s['how-use-section__tip']}>
            <div className={s['how-use-section__tip-icon']}>
              <span className="text-accent">*</span>
            </div>
            <p className={s['how-use-section__tip-text']}>
              Если вы впервые в коворкинге, администратор выдаст промокод на
              первый бесплатный день, по которому вы сможете провести{' '}
              <span className="text-accent-bold text-accent-uppercase">
                его абсолютно бесплатно
              </span>
            </p>
          </div>
          <div
            className={classNames(
              s['how-use-section__tip'],
              s['how-use-section__tip--bottom'],
            )}
          >
            <div
              className={classNames(
                s['how-use-section__tip-icon'],
                s['how-use-section__tip-icon--question'],
              )}
            >
              <span className="text-accent">?</span>
            </div>
            <p className={s['how-use-section__tip-text']}>
              <span
                className={classNames(
                  'text-accent-bold',
                  s['how-use-section__tip-subtitle'],
                )}
              >
                У меня абонемент на 7 дней. Значит ли это, что мне надо ходить
                каждый день в течение недели?
              </span>
              <span className="text-accent-bold">
                <span className="text-accent">Нет!</span> Не нужно ходить каждый
                день{' '}
              </span>
              - расходуйте дни, когда Вам удобно! Дни не сгорают, если Вы не
              ходите в коворкинг!
            </p>
          </div>

          <ul className={s.rules}>
            <li className={s['rules-item']}>
              <span className={s['rules-item__count']}>01</span>
              <div className={s['rules-item__text']}>
                <h3 className={s['rules-item__title']}>Зарегистрироваться</h3>
                <p className={s['rules-item__subtitle']}>
                  Вам необходимо зарегистрироваться на сайте{' '}
                  <Link
                    to={authData.isLoggedIn ? DASHBOARD_LINK : REGISTER_LINK}
                    className={s.rulesLink}
                  >
                    bekker-place.ru
                  </Link>
                </p>
              </div>
            </li>
            <li className={s['rules-item']}>
              <span className={s['rules-item__count']}>02</span>
              <div className={s['rules-item__text']}>
                <h3 className={s['rules-item__title']}>
                  <span className="text-accent">*</span> Пополнить баланс
                </h3>
                <p className={s['rules-item__subtitle']}>
                  Пополнить баланс на желаемую сумму
                </p>
              </div>
            </li>
            <li className={s['rules-item']}>
              <span className={s['rules-item__count']}>03</span>
              <div className={s['rules-item__text']}>
                <h3 className={s['rules-item__title']}>Купить дни</h3>
                <p className={s['rules-item__subtitle']}>
                  Приобрести нужное количество дней в одном из двух коворкингов
                </p>
              </div>
            </li>
            <li className={s['rules-item']}>
              <span className={s['rules-item__count']}>04</span>
              <div className={s['rules-item__text']}>
                <h3 className={s['rules-item__title']}>Приехать в коворкинг</h3>
                <p className={s['rules-item__subtitle']}>
                  При входе отсканировать QR-код на проходной в данном
                  коворкинге
                </p>
              </div>
            </li>
            <li className={s['rules-item']}>
              <span className={s['rules-item__count']}>05</span>
              <div className={s['rules-item__text']}>
                <h3 className={s['rules-item__title']}>Работать</h3>
                <p className={s['rules-item__subtitle']}>
                  В течение дня вы можете выходить и заходить в коворкинг
                  неограниченное количество раз, повторного списания не будет
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Section>
  )
}
