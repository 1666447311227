export default function NegationIcon(props: { className: string }) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
        >
            <path
                d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433281 8.00043 -0.1937 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM1.25 10C1.2506 7.89743 2.01207 5.8661 3.39375 4.28125L15.7188 16.6062C14.4512 17.7008 12.8961 18.4086 11.2382 18.6456C9.58031 18.8826 7.88922 18.6389 6.36575 17.9433C4.84228 17.2477 3.55043 16.1295 2.64362 14.7215C1.73681 13.3134 1.25312 11.6748 1.25 10ZM16.6063 15.7187L4.28125 3.39375C5.95835 1.99246 8.09914 1.2695 10.2824 1.36711C12.4657 1.46472 14.5334 2.37583 16.0788 3.92119C17.6242 5.46655 18.5353 7.53428 18.6329 9.71757C18.7305 11.9009 18.0075 14.0416 16.6063 15.7187Z"
                fill="#BE763C"
            />
        </svg>
    );
}
