import {
  Column,
  TableOptions,
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import s from './AdminUsers.module.scss'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import {
  GetUsersType,
  IAdminUser,
  IPagination,
  SearchUserType,
} from '../../@types/assets'
import GlobalFilter from '../GlobalFilter/GlobalFilter'
import { STARTUP_PAGINATION_DATA } from '../../constants'
import { Spin } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'

const antTableIcon = (
  <LoadingOutlined
    style={{
      fontSize: 50,
      color: '#be763c',
    }}
    spin
  />
)

const AdminUsers = (props: {
  onGetUsers?: GetUsersType
  isLoading?: boolean
  onSearchUser?: SearchUserType
}) => {
  dayjs.locale('ru')
  document.title = 'Список всех пользователей | Bekker-place Admin'
  const navigate = useNavigate()
  const [adminUsers, setAdminUsers] = useState<IAdminUser[]>([])
  const [paginationParameters, setPaginationParameters] = useState<IPagination>(
    STARTUP_PAGINATION_DATA,
  )

  const [searchValue, setSearchValue] = useState<string>('')

  const handleUpdateTable = useAsyncDebounce(() => {
    if (searchValue === '') {
      props.onGetUsers &&
        props.onGetUsers(pageSizeLocal, pageNumber).then((res) => {
          setAdminUsers(res.data.data)
          setPaginationParameters(res.data.pagination)
        })
    } else {
      props.onSearchUser &&
        props
          .onSearchUser(pageSizeLocal, pageNumber, searchValue)
          .then((res) => {
            setAdminUsers(res.data.data)
            setPaginationParameters(res.data.pagination)
          })
    }
  }, 1500)

  const data = useMemo(() => {
    if (Array.isArray(adminUsers) && adminUsers.length !== 0) {
      return adminUsers
    } else {
      return []
    }
  }, [adminUsers])

  const onChangeGoTo = useAsyncDebounce((value: number) => {
    setPageNumber(Number(value))
  }, 2000)

  const columns: Column<IAdminUser>[] = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
      },
      {
        Header: 'Логин',
        accessor: 'login',
      },
      {
        Header: 'Телефон',
        accessor: 'phone',
      },
      {
        Header: 'Баланс',
        accessor: 'balance',
      },
      {
        Header: 'Дни',
        accessor: 'days',
      },
      {
        Header: 'Турникет',
        accessor: 'isInside',
        Cell: (cellProps) => {
          return (
            <div
              className={classNames(
                s['is-in'],
                cellProps.value === 1 ? s['in-true'] : s['in-false'],
              )}
            >
              {cellProps.value === 1 ? 'Вошёл' : 'Вышел'}
            </div>
          )
        },
      },
      {
        Header: 'Дата регистрации',
        accessor: 'createdAt',
        Cell: (cellProps) => {
          return (
            <span>{dayjs(cellProps.value).format('DD MMM YYYY, HH:mm')}</span>
          )
        },
      },
      {
        Header: 'Кол-во посещений',
        accessor: 'visitsCount',
      },
      {
        Header: 'Статус',
        accessor: 'banned',
        disableSortBy: true,
        Cell: (cellProps) => {
          return (
            <div
              className={classNames(
                s['is-in'],
                !cellProps.value ? s['in-true'] : s['in-false'],
              )}
            >
              {!cellProps.value ? 'Активен' : 'Заблокирован'}
            </div>
          )
        },
      },
      {
        Header: 'Действия',
        accessor: 'id',
        id: 'actions',
        disableSortBy: true,
        Cell: (props) => {
          return (
            <button
              className={s.edit}
              onClick={() => {
                navigate(`/admin/users/${props.value}/edit`)
              }}
            >
              <EditOutlined style={{ color: '#fff' }} />
            </button>
          )
        },
      },
    ],
    [],
  )

  const options: TableOptions<IAdminUser> = {
    data,
    columns,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setPageSize,
    setGlobalFilter,
  } = useTable(options, useGlobalFilter, useSortBy, usePagination)

  const [pageSizeLocal, setPageSizeLocal] = useState(10)
  const [pageNumber, setPageNumber] = useState<number>(1)

  useEffect(() => {
    handleUpdateTable()
  }, [searchValue, pageSizeLocal, pageNumber])

  return (
    <div className={s.users}>
      <h1 className={s.title}>Список всех пользователей</h1>
      <div className={s.table_container}>
        <div className={s['table_options-container']}>
          <select
            className={s['table_page-size']}
            value={pageSizeLocal}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
              setPageSizeLocal(Number(e.target.value))
              setPageNumber(1)
            }}
          >
            {[10, 25, 50].map((pageSize, index, array) => {
              return (
                <option key={pageSize} value={pageSize}>
                  Показать {pageSize}
                </option>
              )
            })}
          </select>
          <label>
            <span className={s.search_text}>Поиск по логину: </span>
            <input
              value={searchValue}
              onChange={(evt) => setSearchValue(evt.target.value)}
              className={s['table_search-input']}
            ></input>
          </label>
        </div>
        <div className={s.table_wrapper}>
          <table {...getTableProps()} className={s.table_main}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {/* {column.disableSortBy ? (
                        <></>
                      ) : column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className={s.table_arrow}>
                            &#8593;
                            <span className={s.table_arrow__unactive}>
                              &#8595;
                            </span>
                          </span>
                        ) : (
                          <span className={s.table_arrow}>
                            <span className={s.table_arrow__unactive}>
                              &#8593;
                            </span>
                            &#8595;
                          </span>
                        )
                      ) : (
                        <span
                          className={classNames(
                            s.table_arrow,
                            s.table_arrow__unactive,
                          )}
                        >
                          &#8593;&#8595;
                        </span>
                      )} */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        {!props.isLoading ? null : (
          <div className={s['table-spinner-container']}>
            <Spin indicator={antTableIcon} />
          </div>
        )}
        <div className={s.pagination}>
          <span className={s.pagination_text}>
            Страница {paginationParameters.currentPage} из{' '}
            {paginationParameters.totalPages}
          </span>
          <div className={s.pagination_goto}>
            <span className={s.pagination_text}>Перейти на страницу</span>
            <input
              type="number"
              defaultValue={1}
              max={paginationParameters.totalPages}
              min={1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const pageValue = e.target.value ? Number(e.target.value) : 0
                if (pageValue > paginationParameters.totalPages) {
                  onChangeGoTo(paginationParameters.totalPages)
                  return
                } else if (pageValue < 1) {
                  onChangeGoTo(1)
                  return
                }
                onChangeGoTo(pageValue)
              }}
              className={s.pagination_input}
            ></input>
          </div>
          <div className={s['pagination_button-container']}>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__first,
              )}
              onClick={() => {
                setPageNumber(1)
              }}
              disabled={pageNumber === 1}
            >
              1
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__middle,
              )}
              onClick={() => {
                setPageNumber(pageNumber - 1)
              }}
              disabled={pageNumber === 1}
            >
              Предыдущая
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__middle,
              )}
              onClick={() => {
                setPageNumber(pageNumber + 1)
              }}
              disabled={paginationParameters.totalPages === pageNumber}
            >
              Следующая
            </button>
            <button
              className={classNames(
                s.pagination_button,
                s.pagination_button__last,
              )}
              onClick={() => {
                setPageNumber(paginationParameters.totalPages)
              }}
              disabled={paginationParameters.totalPages === pageNumber}
            >
              {paginationParameters.totalPages}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminUsers
