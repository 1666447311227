import s from './AdminMenu.module.scss'
import {
  DashboardOutlined,
  UserOutlined,
  IdcardOutlined,
  QrcodeOutlined,
  FileSearchOutlined,
  PercentageOutlined,
  BackwardOutlined,
  LineChartOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Menu } from 'antd'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  ADMIN_ANALYTICS,
  ADMIN_LINK,
  ADMIN_LOGS_LINK,
  ADMIN_PROMOCODES_LINK,
  ADMIN_PROMOTIONS_LINK,
  ADMIN_SUBSCRIPTIONS_LINK,
  ADMIN_USERS_LINK,
  DASHBOARD_LINK,
} from '../../constants'
import classNames from 'classnames'
import { MenuRefType, SetCollapsedType } from '../../@types/assets'

type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem
}

const items: MenuItem[] = [
  getItem('Dashboard', '1', <DashboardOutlined />),
  getItem('Пользователи', '2', <UserOutlined />),
  getItem('Подписки', '3', <IdcardOutlined />),
  getItem('Промокоды', '4', <QrcodeOutlined />),
  getItem('Акции', '5', <PercentageOutlined />),
  // getItem('Логи', '6', <FileSearchOutlined />),
  getItem('Аналитика', '7', <LineChartOutlined />),
  getItem('Вернуться в кабинет', '8', <BackwardOutlined />),
]

const AdminMenu = (props: {
  collapsed: boolean
  setCollapsed: SetCollapsedType
  menuRef: MenuRefType
  onCloseMenu: () => void
}) => {
  const [activeKey, setActiveKey] = useState('0')
  const location = useLocation()
  const navigate = useNavigate()
  const handleNavigate = (key: string) => {
    switch (key) {
      case '1': {
        navigate(ADMIN_LINK)
        break
      }
      case '2': {
        navigate(ADMIN_USERS_LINK)
        break
      }
      case '3': {
        navigate(ADMIN_SUBSCRIPTIONS_LINK)
        break
      }
      case '4': {
        navigate(ADMIN_PROMOCODES_LINK)
        break
      }
      case '5': {
        navigate(ADMIN_PROMOTIONS_LINK)
        break
      }
      // case '6': {
      //   navigate(ADMIN_LOGS_LINK)
      //   break
      // }
      case '7': {
        navigate(ADMIN_ANALYTICS)
        break
      }
      case '8': {
        navigate(DASHBOARD_LINK)
        break
      }
    }
  }

  const handlesetActiveKey = (pathname: string) => {
    switch (pathname) {
      case ADMIN_LINK: {
        setActiveKey('1')
        break
      }
      case ADMIN_USERS_LINK: {
        setActiveKey('2')
        break
      }
      case ADMIN_SUBSCRIPTIONS_LINK: {
        setActiveKey('3')
        break
      }
      case ADMIN_PROMOCODES_LINK: {
        setActiveKey('4')
        break
      }
      case ADMIN_PROMOTIONS_LINK: {
        setActiveKey('5')
        break
      }
      // case ADMIN_LOGS_LINK: {
      //   setActiveKey('6')
      //   break
      // }
      case DASHBOARD_LINK: {
        setActiveKey('7')
        break
      }
    }
  }

  useEffect(() => {
    handlesetActiveKey(location.pathname)
  }, [location.pathname])

  return (
    <div
      ref={props.menuRef}
      className={classNames(s.sidebar, props.collapsed && s.sidebar__collapsed)}
    >
      <Button
        type="primary"
        onClick={props.onCloseMenu}
        className={s.hamburger}
      >
        <MenuFoldOutlined />
      </Button>
      <Menu
        className={s.menu}
        onClick={({ key }) => {
          setActiveKey(key)
          handleNavigate(key)
        }}
        selectedKeys={[activeKey]}
        mode="inline"
        theme="dark"
        inlineCollapsed={true}
        items={items}
      />
    </div>
  )
}

export default AdminMenu
